import makeStyles from '@mui/styles/makeStyles'
import withStyles from '@mui/styles/withStyles'
import React, { forwardRef } from 'react'
import AddBox from '@mui/icons-material/AddBox'
import Check from '@mui/icons-material/Check'
import Clear from '@mui/icons-material/Clear'
import DeleteOutline from '@mui/icons-material/DeleteOutline'
import ChevronRight from '@mui/icons-material/ChevronRight'
import Edit from '@mui/icons-material/Edit'
import SaveAlt from '@mui/icons-material/SaveAlt'
import FilterList from '@mui/icons-material/FilterList'
import FirstPage from '@mui/icons-material/FirstPage'
import LastPage from '@mui/icons-material/LastPage'
import ChevronLeft from '@mui/icons-material/ChevronLeft'
import Search from '@mui/icons-material/Search'
import ArrowDownward from '@mui/icons-material/ArrowDownward'
import Remove from '@mui/icons-material/Remove'
import ViewColumn from '@mui/icons-material/ViewColumn'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'

export const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
}
export const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#000000', //theme.palette.common.black,
    color: '#FF9F43',
  },
  body: {
    fontSize: 20,
  },
}))(TableCell)

export const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#FF9F43', //warning//theme.palette.action.hover,
    },
  },
}))(TableRow)

export const useStyles = makeStyles((theme) => ({
  table: {
    backgroundColor: '#82868B', //fff
  },
  fab: {
    position: 'relative',
    bottom: 2, //theme.spacing(2),
    right: 2, //theme.spacing(2),
  },
}))

export const tableHeaderStyle = (theme) => {
  return {
    paddingLeft: 5,
    paddingRight: 5,
    color: '#7367F0', //primary color
    fontWeight: 'bold',
    fontSize: 14,
    whiteSpace: 'nowrap',
    textAlign: 'left',
    flexDirection: 'row',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }
}
