import {
  CREATE_NOTIFICATION,
  DELETE_ALL_NOTIFICATION,
  DELETE_NOTIFICATION,
  GET_NOTIFICATION,
} from '../actions/NotificationActions'

const initialState = {
  notifications: [],
  badgeContent: 0,
}

const NotificationReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_NOTIFICATION: {
      return {
        ...state,
        badgeContent: action.payload.badgeContent,
        notifications: action.payload.notifications,
      }
    }
    case CREATE_NOTIFICATION: {
      return {
        ...state,
        badgeContent: action.payload.badgeContent,
        notifications: action.payload.notifications,
      }
    }
    case DELETE_NOTIFICATION: {
      return {
        ...state,
        badgeContent: action.payload.badgeContent,
        notifications: action.payload.notifications,
      }
    }
    case DELETE_ALL_NOTIFICATION: {
      return {
        ...state,
        badgeContent: action.payload.badgeContent,
        notifications: action.payload.notifications,
      }
    }
    default: {
      return state
    }
  }
}

export default NotificationReducer
