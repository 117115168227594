import history from '@src/history'
import jwtAuthService from '../../services/jwtAuthService'
export const SET_USER_DATA = 'USER_SET_DATA'
export const REMOVE_USER_DATA = 'USER_REMOVE_DATA'
export const USER_LOGGED_OUT = 'USER_LOGGED_OUT'

export function setUserData(user) {
  return (dispatch) => {
    dispatch({
      type: SET_USER_DATA,
      data: user,
    })
  }
}

export function logoutUser() {
  return (dispatch) => {
    console.log('Logout .............')
    jwtAuthService.logout()
    console.log('Logout JWT Done')

    // log out from google
    // window?.gapi?.auth2?.getAuthInstance()?.signOut();
    console.log('Logout google Done')

    localStorage.removeItem('user_id')

    for (let key in localStorage) {
      if (key.includes('linkedin')) {
        localStorage.removeItem(key)
      }
      if (key.includes('fcm')) {
        localStorage.removeItem(key)
      }
      console.log(key)
    }

    history.push({
      pathname: '/login',
    })
    console.log('Logout history')

    localStorage.removeItem('persist:root')
    console.log('Logout local storage done')
    setTimeout(() => {
      console.log('Action done')
    }, 2000)

    dispatch({
      type: 'CANDIDATE_DETAIL_CLEARALL',
    })

    dispatch({
      type: 'JD_EVALUATE_CLEAR_ALL',
    })

    dispatch({
      type: 'RESET_NAVIGATION_SIDEBAR',
    })

    dispatch({
      type: 'MULTIPLE_CREATE_JD_RESET',
    })

    dispatch({
      type: 'CREATE_MULTIPLE_JD_SETTINGS_RESET',
    })

    dispatch({
      type: 'JD_SETTINGS_RESET',
    })

    dispatch({
      type: 'OPEN_TASK_INIT',
    })

    dispatch({
      type: 'CREATE_TASK_INIT',
    })

    dispatch({
      type: 'RESET_LOGIN',
    })

    dispatch({
      type: 'RESET',
    })

    console.log('Logout history Done')
  }
}
