const initialState = {
  data: {
    designations: [''],
    num_of_positions: [0],
    exp_years: [1],
    exp_months: [1],
    ocr: [],
    anas: [],
    paras: [],
    rawJD: [],
    processing_ids: [],
    raw_jd_state: [],
  },
  file: {
    name: '',
    data: '',
    file: '',
  },
  jd_names: [''],
  bg_color_card: ['white'],
  values: [''],
  fetchTime: Date.now(),
  error: '',
  loading: false,
  redirect: false,
  path: '',
  last_fetch_time: '',
  jdID: [0],
  pseudo_jd_ids: [''],
  is_jd_saved: false,
}

const MultipleCreateJdReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'CREATE_JD_MULTIPLE_DATA':
      console.log(state)
      return {
        ...state,
        data: action.data,
        jd_names: action.jd_names,
        bg_color_card: action.bg_color_card,
        jdID: action.jd_ids,
        fetchTime: Date.now(),
        isLoading: false,
        error: '',
      }
    case 'CREATE_JD_ALL_DATA':
      return {
        ...state,
        data: action.data,
        jd_names: action.jd_names,
        bg_color_card: action.bg_color_card,
        values: action.values,
        jdID: action.jd_ids,
        pseudo_jd_ids: action.pseudo_jd_ids,
        fetchTime: Date.now(),
        isLoading: false,
        error: '',
      }
    case 'CREATE_JD_MULTIPLE_PREVIOUS':
      return {
        ...state,
        data: action.initValue.data,
        fetchTime: Date.now(),
        isLoading: false,
        last_fetch_time: action.initValue.last_fetch_time,
        error: '',
      }
    case 'CREATE_JD_MULTIPLE_UPDATE_UPLOADNEWJDS':
      return {
        ...state,
        data: {
          designation: [...state.data.designation],
          max_exp: [...state.data.max_exp],
          min_exp: [...state.data.min_exp],
          education: [...state.data.education],
          skills: [...state.data.skills],
          domain: [...state.data.domain],
          college_type: [...state.data.college_type],
          company_type: [...state.data.company_type],
          jd_score: [...state.data.jd_score],
          jd_tag: [...state.data.jd_tag],
          improvement_text: [...state.data.improvement_text],
          rawJD: [...state.data.rawJD],
          uploadNewJds: action.uploadNewJds,
        },
      }
    case 'CREATE_JD_LAST_FETCH_TIME':
      return {
        ...state,
        last_fetch_time: action.last_fetch_time,
      }
    case 'UPDATE_OCR':
      return {
        ...state,
        data: {
          designations: [...state.data.designations],
          num_of_positions: [...state.data.num_of_positions],
          exp_years: [...state.data.exp_years],
          exp_months: [...state.data.exp_months],
          ocr: action.ocr,
          anas: [...state.data.anas],
          paras: [...state.data.paras],
          rawJD: [...state.data.rawJD],
          processing_ids: [...state.data.processing_ids],
          raw_jd_state: [...state.data.raw_jd_state],
        },
      }
    case 'CREATE_JD_ERROR':
      return {
        ...state,
        error: action.error,
        isLoading: false,
      }

    case 'CREATE_JD_UPDATE_SAVE_STATUS':
      return {
        ...state,
        is_jd_saved: action.status,
      }

    case 'CREATE_JD_MULTIPLE_UPDATE_JD':
      return {
        ...state,
        jdID: action.jd_id,
      }

    case 'CREATE_JD_MULTIPLE_CLEAR':
      console.log('initialState', initialState)
      return {
        ...state,
        data: initialState.data,
        fetchTime: initialState.fetch_time,
        error: initialState.error,
        loading: initialState.loading,
        redirect: initialState.redirect,
        path: initialState.path,
        file: initialState.file,
        jd_names: initialState.jd_names,
        bg_color_card: initialState.bg_color_card,
        values: initialState.values,
        last_fetch_time: '',
        jdID: [0],
        is_jd_saved: false,
      }

    case 'CREATE_JD_FILE':
      return {
        ...state,
        file: {
          ...state.file,
          name: action.name,
          data: action.data,
          file: action.file,
        },
      }

    case 'CREATE_JD_LOADING':
      return {
        ...state,
        isLoading: action.isLoading,
      }

    case 'CREATE_JD_INIT':
      return {
        ...state,
        data: initialState.data,
        file: initialState.file,
        jdID: initialState.jdID,
        error: initialState.error,
        loading: initialState.loading,
        redirect: initialState.redirect,
        path: initialState.path,
        jd_names: initialState.jd_names,
        bg_color_card: initialState.bg_color_card,
        values: initialState.values,
      }
    case 'MULTIPLE_CREATE_JD_RESET':
      let x = {
        data: {
          designations: [''],
          num_of_positions: [0],
          exp_years: [1],
          exp_months: [1],
          ocr: [],
          anas: [],
          paras: [],
          rawJD: [],
          processing_ids: [],
          raw_jd_state: [],
        },
        file: {
          name: '',
          data: '',
          file: '',
        },
        jd_names: [''],
        bg_color_card: ['white'],
        values: [''],
        fetchTime: Date.now(),
        error: '',
        loading: false,
        redirect: false,
        path: '',
        last_fetch_time: '',
        jdID: [0],
        pseudo_jd_ids: [''],
        is_jd_saved: false,
      }
      return x
    case 'CREATE_JD_REDIRECT':
      return {
        ...state,
        error: action.error,
        isLoading: true,
        redirect: true,
        path: action.path,
      }

    case 'CREATE_JD_DESIGNATION':
      return {
        ...state,
        data: {
          ...state.data,
          designations: action.designation,
        },
      }

    default:
      return state
  }
}

export default MultipleCreateJdReducer
