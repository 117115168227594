import { combineReducers } from 'redux'
import SessionReducer from './reducers/LoginSessionReducer'
import NotificationReducer from './reducers/NotificationReducer'

import NavigationReducer from './reducers/NavigationReducer'
import LoginReducers from './reducers/LoginReducers'
import JDEvaluateReducer from '@src/screens/EvaluateJD/evaluate/JdEvaluateReducer'
import MultipleJdsEvaluateReducer from '@src/views/CreateMultipleJD/CreateJd/MultipleCreateJdReducer'
import JDSettingsReducer from '@src/screens/EvaluateJD/settings/JdSettingsReducer'
import CandidateDetailReducer from '@src/screens/EvaluateJD/search_profiles/CandidateDetailReducer'
import UserProfileReducer from './reducers/UserProfileReducer'
import ObjectivesReducer from '@src/screens/EvaluateJD/targets/ObjectivesReducer'
import OpenTaskReducer from '@src/views/apps/cvbuckets/extractcontactdetails/OpenTask/OpenTaskReducer'
import CreateTaskReducer from '@src/views/apps/cvbuckets/extractcontactdetails/CreateTask/CreateTaskReducer'
import calendar from '@src/views/apps/calendar/store'
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import chat from '@src/views/apps/chat2/store'
import appChat2 from '@src/views/apps/chat3/store'
import sms from '@src/views/apps/sms/store'
import inmail from '@src/views/apps/inmail2/store'
import users from '@src/views/apps/user/store'
import email from '@src/views/apps/email2/store2'
import personalemail from '@src/views/apps/PersonalEmail/store2'
import fcm from '@src/redux/reducers/fcmStore'
import pNotification from '@src/views/apps/cvbuckets/dataprocessing/store'
import workflow from '@src/views/apps/workflow1/store'
import processFirstContactNotification from '@src/views/apps/cvbuckets/extractcontactdetails/store'
import linkedinStatsReducer from './reducers/LinkedinStatsReducers'
import notes from '@src/views/jd/homepage/store'
import processResume from '../views/apps/cvbuckets/resumepage/store'

const RootReducer = combineReducers({
  login: SessionReducer,
  // user: UserReducer,
  // layout: LayoutReducer,
  // scrumboard: ScrumBoardReducer,
  // notification: NotificationReducer,
  // navigations: NavigationReducer,
  LoginReducers,
  NavigationReducer,
  JDEvaluateReducer,
  MultipleJdsEvaluateReducer,
  ObjectivesReducer,
  JDSettingsReducer,
  // MultipleJdSettingsReducer,
  // JDSourceReducer,
  // SettingPageReducer,
  CandidateDetailReducer,
  UserProfileReducer,
  OpenTaskReducer,
  CreateTaskReducer,
  NotificationReducer,

  // MultipleObjectivesReducer
  auth,
  calendar,
  chat,
  appChat2,
  sms,
  inmail,
  email,
  personalemail,
  users,
  navbar,
  layout,
  fcm,
  pNotification,
  processFirstContactNotification,
  linkedinStatsReducer,
  workflow,
  notes,
  processResume,
})

export default RootReducer
