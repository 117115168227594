import React, { Fragment, useEffect, useState } from 'react'
import { TextField } from '@mui/material'
import { sendSMS } from './ApiHelper'

import {
  Button,
  Card,
  CardText,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap'

import Select from 'react-select'
import { toast } from 'react-toastify'

function SendSMSDialog({ isOpen, handleClose, data, options }) {
  console.log('SendSMSDialog', isOpen, handleClose, data, options)
  const [params, setParams] = useState({
    name: data.name,
    role: data.designation,
    status_id: data.status_id,
    status: data.status,
    cvID: data.cid,
    jdID: data.jd_id,
  })

  useEffect(() => {
    setParams({
      name: data.name,
      role: data.designation,
      status_id: data.status_id,
      status: data.status,
      cvID: data.cid,
      jdID: data.jd_id,
    })
  }, [data])

  console.log('DEBUG SendSMSDialog', data, params)

  const handleSMSSend = (e) => {
    sendSMS(params.jdID, params.cvID, params.role, params.status_id).then(
      (res) => {
        console.log(res)
        if (res.status == 1) {
          toast.success('SMS Sent Successfully')
        } else {
          toast.error('SMS Sent Failed')
        }
        handleClose()
      },
    )
  }

  const handleStatusChange = (e) => {
    setParams((prev) => {
      return {
        ...prev,
        status_id: e.value,
        status: e.label,
      }
    })
  }

  const handleChange = (e) => {
    setParams({
      ...params,
      [e.name]: e.value,
    })
  }

  const toggle = () => handleClose(!isOpen)

  return (
    <div>
      <Card>
        <Fragment>
          <Modal
            isOpen={isOpen}
            toggle={toggle}
            className="modal-dialog-centered " //modal-lg
          >
            <ModalHeader className="bg-transparent" toggle={toggle}>
              {params?.name ? (
                <h4 className="text-left mb-1">
                  Send SMS to{' '}
                  <span className="text-primary">{params.name}</span>{' '}
                </h4>
              ) : (
                <h4 className="address-title text-left mb-1">
                  {' '}
                  Send message via SMS{' '}
                </h4>
              )}
            </ModalHeader>
            <ModalBody className="">
              <CardText>
                We find your profile a match for{' '}
                <TextField
                  id="filled-multiline-flexible"
                  value={params.role}
                  onChange={handleChange}
                  name={'role'}
                  variant="standard"
                  size="small"
                  color="primary"
                  className="text-primary"
                />{' '}
                role, please provide your details
                <b className="text-secondary">
                  {' '}
                  https://hire10xapi.10xscale.in/api/user/ask/?id={params.cvID}
                  &jd={params.jdId}
                </b>{' '}
                to move forward.
                <br />
                <p className="my-1 text-end text-primary">- Hire10X</p>
              </CardText>

              <label htmlFor="floatingInput">Change Candidate Status</label>
              <Select
                value={{
                  value: params.status_id,
                  label: params.status,
                }}
                onChange={handleStatusChange}
                inputProps={{
                  name: 'age',
                  id: 'age-native-simple',
                }}
                options={options.map((e) => {
                  return { value: e.id, label: e.value }
                })}
              ></Select>
            </ModalBody>
            <ModalFooter>
              <Button
                type="reset"
                className="me-1"
                color="secondary"
                outline
                onClick={handleClose}
              >
                Discard
              </Button>
              <Button className="" color="primary" onClick={handleSMSSend}>
                Send
              </Button>
            </ModalFooter>
          </Modal>
        </Fragment>
      </Card>
    </div>
  )
}

export default SendSMSDialog
