import * as api from '@src/api/JdHistoryApi'
//import axios from 'axios';
export const GET_NOTIFICATION = 'GET_NOTIFICATION'
export const CREATE_NOTIFICATION = 'CREATE_NOTIFICATION'
export const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION'
export const DELETE_ALL_NOTIFICATION = 'DELETE_ALL_NOTIFICATION'

export const getNotification = () => (dispatch) => {
  // axios.get("/api/notification").then(res => {
  //     dispatch({
  //         type: GET_NOTIFICATION,
  //         payload: res.data
  //     });
  // });
}

export const deleteNotification = (id) => (dispatch) => {
  // axios.post("/api/notification/delete", {id}).then(res => {
  //     dispatch({
  //         type: DELETE_NOTIFICATION,
  //         payload: res.data
  //     });
  // });
}

export function deleteAllNotification(
  token,
  userID,
  notifications,
  is_clear_all,
  nocID,
  fullname,
) {
  return function (dispatch) {
    return api
      .deleteNotification(token, userID, is_clear_all, nocID)
      .then((res) => {
        console.log('response from delete: ', res)
        if (res.data.Response === 'Ok') {
          if (is_clear_all === 1) {
            dispatch({
              type: GET_NOTIFICATION,
              payload: {
                badgeContent: 0,
                notifications: [],
              },
            })
          } else {
            let notifs = notifications.filter((e) => {
              return e.id !== nocID
            })

            dispatch({
              type: GET_NOTIFICATION,
              payload: {
                badgeContent: notifs.length,
                notifications: notifs,
              },
            })
          }
          console.log('delete all notification')
          getAllNotification(token, userID, fullname)
        }
      })
  }
}

export const createNotification = (notification) => (dispatch) => {
  // axios.post("/api/notification/add", {notification}).then(res => {
  //     dispatch({
  //         type: CREATE_NOTIFICATION,
  //         payload: res.data
  //     });
  // });
}

export function getAllNotification(token, userID, fullname) {
  return function (dispatch) {
    console.log('Calling api get notification', userID, token)
    return api
      .getNotification(token, userID)
      .then((response) => {
        console.log('This is response RESPONSE', response)
        if (response.data.Response === 'Ok') {
          let data = response.data.Data
          console.log('This is response data', data)

          let count = 0

          let noc = data.map((e) => {
            if (e.is_read === 0) {
              count += 1

              let title = ''
              if (e.from_user_name !== fullname) {
                title = e.from_user_name
              }

              return {
                id: e.id,
                title: title,
                heading: e.question,
                timestamp: e.updated_at,
                subtitle: e.message,
                icon: {
                  color: 'secondary',
                  icon: 'info',
                },
              }
            } else {
              return false
            }
          })

          let noc_data = noc.filter((e) => {
            return e !== false
          })

          console.log('This is response NOC', noc)
          console.log('This is response count', count)

          //saved notification
          // dispatch(SaveNotification(noc_data, count));

          dispatch({
            type: GET_NOTIFICATION,
            payload: {
              badgeContent: count,
              notifications: noc_data,
            },
          })

          return data
        }
      })
      .catch((error) => {
        //nothing to do
        console.log('This is response ERROR', error)
      })
  }
}

export function markNotificationComplete(token, userID, nocID, fullname) {
  return function (dispatch) {
    return api
      .markNotificationComplete(token, userID, nocID)
      .then((response) => {
        console.log('This is response RESPONSE', response)
        if (response.data.Response === 'Ok') {
          let data = response.data.Data
          console.log('This is response data', data)

          let count = 0

          let noc = data.map((e) => {
            if (e.is_read === 0) {
              count += 1
              return {
                id: e.id,
                heading: e.question,
                timestamp: Date.parse(e.updated_at),
                subtitle: e.message + ' from ' + e.from_user_name,
                icon: {
                  color: '#e3c032',
                  icon: 'info',
                },
              }
            } else {
              return false
            }
          })

          console.log('This is response NOC', noc)
          console.log('This is response count', count)

          let noc_data = noc.filter((e) => {
            return e !== false
          })

          //saved notification
          // dispatch(SaveNotification(noc, count));

          dispatch({
            type: GET_NOTIFICATION,
            payload: {
              badgeContent: count,
              notifications: noc_data,
            },
          })

          getAllNotification(token, userID, fullname)

          return data
        }
      })
      .catch((error) => {
        //nothing to do
        console.log('This is response ERROR', error)
        return error
      })
  }
}
