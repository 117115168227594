const initialState = {
  objective_id: '',

  questions_ids: [28, 29, 30, 31],
  questions_details: [
    'candidates_to_be_contacted',
    'candidates_to_be_selected',
    'candidates_to_be_contacted_via_email',
    'candidates_to_be_contacted_via_sms',
  ],

  owner: '',
  createdAt: '',
  timeSpent: '',
  timeRemaining: '',
  profilesExtracted: '',
  // is_bulk_folder_processed: [false],
  // bulk_folder_last_processed_value: ['10'],
  // yes_show_checkbox: [false],
  // fetch_button: [false],

  fetchTime: Date.now(),
  error: '',
  isLoading: false,
  redirect: false,
  path: '',
  status: '',
}

const ObjectivesReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'OBJECTIVES_AND_DETAILS_INFO':
      return {
        ...state,

        owner: action.owner,
        createdAt: action.createdAt,
        timeSpent: action.timeSpent,
        timeRemaining: action.timeRemaining,
        profilesExtracted: action.profilesExtracted,
      }

    case 'OBJECTIVES_QUESTIONS_INFO':
      return {
        ...state,
        questions_ids: action.questions_ids,
        questions_details: action.questions_details,
      }

    case 'UPDATE_OWNER_ID':
      return {
        ...state,
        owner: action.owner_id,
      }

    case 'UPDATE_OBJECTIVE_ID':
      return {
        ...state,
        objective_id: action.objective_id,
      }

    case 'JD_OBJECTIVES_ERROR':
      return {
        ...state,
        questions_ids: initialState.questions_ids,
        questions_details: initialState.questions_details,

        owner: initialState.owner,
        createdAt: initialState.createdAt,
        timeSpent: initialState.timeSpent,
        timeRemaining: initialState.timeRemaining,
        profilesExtracted: initialState.profilesExtracted,
        fetchTime: Date.now(),
        isLoading: false,
        error: action.error,
      }

    case 'JD_OBJECTIVES_CLEAR':
      return {
        ...state,
        questions_ids: initialState.questions_ids,
        questions_details: initialState.questions_details,

        owner: initialState.owner,
        createdAt: initialState.createdAt,
        timeSpent: initialState.timeSpent,
        timeRemaining: initialState.timeRemaining,
        profilesExtracted: initialState.profilesExtracted,
        fetchTime: initialState.fetchTime,
        error: initialState.error,
        isLoading: initialState.isLoading,
        redirect: initialState.redirect,
        path: initialState.path,
      }

    case 'JD_OBJECTIVES_LOADING':
      return {
        ...state,
        isLoading: action.isLoading,
      }

    case 'JD_OBJECTIVES_INIT':
      return {
        ...state,
        questions_ids: initialState.questions_ids,
        questions_details: initialState.questions_details,

        owner: initialState.owner,
        createdAt: initialState.createdAt,
        timeSpent: initialState.timeSpent,
        timeRemaining: initialState.timeRemaining,
        profilesExtracted: initialState.profilesExtracted,
        error: '',
        isLoading: true,
      }

    case 'JD_OBJECTIVES_REDIRECT':
      return {
        ...state,
        questions_ids: initialState.questions_ids,
        questions_details: initialState.questions_details,

        owner: initialState.owner,
        createdAt: initialState.createdAt,
        timeSpent: initialState.timeSpent,
        timeRemaining: initialState.timeRemaining,
        profilesExtracted: initialState.profilesExtracted,
        error: action.error,
        isLoading: true,
        redirect: true,
        path: action.path,
      }
    default:
      return state
  }
}

export default ObjectivesReducer
