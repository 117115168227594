import React, { useEffect, useState } from 'react'
import { Chip, Divider, Grid, Badge } from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import { styles } from '../../jd/incomplete/Helper'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import * as profileApi from '@src/api/CVDetailsApi'
import { checkDetails, checkProjects } from './profileHelper'
import { getCloudStatus } from '../infoCard/InfoHelper'
import parse from 'html-react-parser'
import { Card, CardBody, CardText, CardHeader } from 'reactstrap'
import { createTheme, ThemeProvider } from '@mui/material/styles'

export const BadgeTheme = createTheme({
  palette: {
    secondary: {
      main: '#7a56ff',
    },
  },
})

function ProfileTab(props) {
  const [data, setData] = React.useState({})

  let token = props.token
  let cvID = props.id
  let jdID = props.jdID
  console.log('ProfileTab', token)
  console.log('ProfileTab', props)

  useEffect(() => {
    profileApi.getProfileCardDetails(token, cvID, jdID).then((e) => {
      if (e.status === 200) {
        console.log('ProfileTab', e.data.Data) //list
        console.log('ProfileTab response: ', e) //list
        setData(e.data.Data)
      }
    })
  }, [cvID, jdID, token])

  data?.skill?.map((e) => {
    console.log('data_endorsements__1', e.endorsements)
  })

  var isOCR = getCloudStatus(props.data)

  return (
    <div>
      <Card>
        <CardBody className="p-3">
          {data?.length === 0 ? (
            <div></div>
          ) : (
            <div>
              <div className="py-1">
                <h5 className="fw-bolder mb-1">Work Experience</h5>
                <div className="ms-1">
                  {data?.works?.map((e) => {
                    console.log('Works', e)
                    if (e.text?.length > 0) {
                      {
                        console.log('text_e', parse(e.text))
                      }
                      return (
                        <p className="fw-bold opacity-75 mb-75">
                          {parse('<div>' + e.text + '</div>')}
                        </p>
                      )
                    }
                    // if (e.text?.length > 0) {
                    //     return parse('<div>' + e.text + "</div>")
                    // }
                    // if (e.text?.length > 0) {
                    //     return parse(e.text)
                    // }

                    // if (e.text?.length > 0 && isOCR) {
                    //     return <Grid item>
                    //         {
                    //             e.text.split("\n").map((txt) => {
                    //                 return <p> {txt} <br/></p>
                    //             })
                    //         }
                    //         <Divider/>
                    //     </Grid>
                    // }

                    return (
                      <div className="">
                        <p className="mb-50">
                          <ThemeProvider theme={BadgeTheme}>
                            <Chip
                              color="secondary"
                              label={`${e.designation} at ${e.company}`}
                              size="small"
                              variant="outlined"
                            />
                          </ThemeProvider>
                        </p>
                        {console.log('designation_e', e)}
                        {e.start_date !== '' && e.end_date !== '' ? (
                          <p className=" mb-50 fw-bold opacity-75 font-small-4">
                            {e.start_date} - {e.end_date} ({e.duration})
                          </p>
                        ) : (
                          <p className=" mb-50">({e.duration})</p>
                        )}
                        <p className=" mb-50">{e.text}</p>
                        <p className=" mb-50">{e.responsibility}</p>
                      </div>
                    )
                  })}
                </div>
              </div>

              {/* <Divider /> */}
              <div className="pt-1">
                <h5 className="fw-bolder mb-1">Education</h5>
                <div className="ms-1">
                  {data?.educations?.map((e) => {
                    console.log('Educations', e)

                    if (e.text?.length > 0) {
                      return <div className="">{parse(e.text)}</div>
                    }

                    return (
                      <div className="mb-1">
                        <p className="fw-bold opacity-75 mb-25">
                          {e.degree} from {e.college_name}
                        </p>

                        <p className="text-hint mb-0">
                          {e.start_year} - {e.end_year}
                        </p>
                        <div className="text-small">{e.text}</div>
                      </div>
                    )
                  })}
                </div>
              </div>
              {/* <Divider /> */}

              {checkProjects(data) && (
                <div>
                  <div className="my-5" />
                  <h5>Projects:</h5>
                  <div className="my-5" />
                  <Grid container spacing={3} direction="column">
                    {data.projects.map((e) => {
                      return (
                        <Grid item>
                          <div>
                            <b>{e?.title}</b>
                          </div>
                          <div className="my-2" />
                          <div className="text-hint">{e?.duration}</div>
                          {e.skills !== '' && (
                            <div>skills: {e.skills.split(',').join(' ,')}</div>
                          )}

                          {e.description !== '' && (
                            <div>
                              <span>Description:</span> {e.description}
                            </div>
                          )}
                        </Grid>
                      )
                    })}
                  </Grid>
                  <div className="my-5" />
                </div>
              )}
              {/* <Divider /> */}
              {data?.skill?.length > 0 && (
                <div className="py-1">
                  <h5 className="fw-bolder mb-1">Skills</h5>
                  {data.skill.map((skill) => {
                    console.log('skills_data_II', skill.endorsements)
                    if (skill.skills.length > 0) {
                      return skill.skills.split(',').map((skillName) => {
                        return (
                          <ThemeProvider theme={BadgeTheme}>
                            <Badge
                              badgeContent={
                                skill.endorsements.includes(0)
                                  ? null
                                  : skill.endorsements
                              }
                              color="secondary"
                              style={{
                                margin: '10px',
                              }}
                            >
                              <Chip
                                color="secondary"
                                label={skillName}
                                size="small"
                                variant="outlined"
                              />
                            </Badge>
                          </ThemeProvider>
                        )
                      })
                    } else {
                      return <div key={skill.id}></div>
                    }
                  })}
                </div>
              )}
              {/* <Divider /> */}
              {checkDetails(data) && (
                <div className="">
                  <h5 className="fw-bolder mb-1">Personal Details</h5>

                  <div className="ms-1">
                    {console.log('Profile', data)}
                    <p className=" mb-25 ">
                      {' '}
                      <span className="fw-bolder opacity-75">Name -</span>{' '}
                      {data?.cv?.name}
                    </p>
                    <p className=" mb-25 ">
                      <span className="fw-bolder opacity-75">Phone - </span>{' '}
                      {data?.cv?.phone_number}
                    </p>
                    {data?.details?.[0]?.location !== '' && (
                      <p className=" mb-25 ">
                        <span className="fw-bolder opacity-75">
                          Location -{' '}
                        </span>
                        {data?.details?.[0]?.location}
                      </p>
                    )}
                    {data.details?.[0]?.preferred_location}
                    {data?.details?.[0]?.preferred_location !== '' && (
                      <p className=" mb-25 ">
                        <span className="fw-bolder opacity-75">
                          Preferred Location -{' '}
                        </span>
                        {data.details?.[0]?.preferred_location}
                      </p>
                    )}
                    {data?.details?.[0]?.current_ctc !== '' &&
                      data?.details?.[0]?.current_ctc?.toString() !== '-1' && (
                        <p className=" mb-25 ">
                          <span className="fw-bolder opacity-75">
                            {' '}
                            Current CTC -{' '}
                          </span>
                          {data.details?.[0]?.current_ctc}
                        </p>
                      )}

                    {data?.details?.[0]?.expected_ctc !== '' &&
                      data?.details?.[0]?.expected_ctc?.toString() !== '-1' && (
                        <p className=" mb-25 ">
                          <span className="fw-bolder opacity-75">
                            {' '}
                            Expected CTC -{' '}
                          </span>
                          {data.details?.[0]?.expected_ctc}
                        </p>
                      )}

                    {data?.details?.[0]?.notice_periods !== '' &&
                      data?.details?.[0]?.notice_periods?.toString() !==
                        '-1' && (
                        <p className=" mb-25 ">
                          <span className="fw-bolder opacity-75">
                            {' '}
                            Notice Periods -{' '}
                          </span>
                          {data.details?.[0]?.notice_periods}
                        </p>
                      )}
                  </div>
                </div>
              )}
            </div>
          )}
        </CardBody>
      </Card>
    </div>
  )
}

const mapStateToProps = (state) => ({
  token: state.LoginReducers.token,
  uuid: state.LoginReducers.uuid,
})

const mapDispatchToProps = (dispatch) => ({})

export default withStyles(styles, { withTheme: true })(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(ProfileTab)),
)
