import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

export const fcmSlice = createSlice({
  name: 'fcm',
  initialState: {
    data: {},
  },
  reducers: {
    saveFCMData: (state, action) => {
      console.log('FCM Store', action.payload)
      state.data = action.payload
    },
  },
})

export const { saveFCMData } = fcmSlice.actions

export default fcmSlice.reducer
