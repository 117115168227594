import React, { Fragment, useEffect, useState } from 'react'
import { withStyles } from '@mui/styles'
import { styles } from '../../../jd/incomplete/Helper'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import { DatePicker } from 'antd'
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  Input,
  Modal,
  ModalFooter,
  ModalBody,
  ModalHeader,
} from 'reactstrap'
import { Checkbox } from '@mui/material'
import PerfectScrollbar from 'react-perfect-scrollbar'
import moment from 'moment-timezone'
import {
  getJourneyContactType,
  postJourneyType,
} from '../../../../api/SettingsApi'
import {
  createScheduleMeeting,
  deleteScheduleMeeting,
  patchScheduledMeeting,
  scheduleMeetingHistory,
  updateScheduleMeeting,
} from '../../../../api/FeedbackApi'
import CreatableSelect from 'react-select/creatable'
import dayjs from 'dayjs'
import { MeetingCard } from './components/meeting_card'
import { X } from 'react-feather'

function ScheduleMeeting({
  isOpen,
  isClose,
  data,
  isRequestNewMeetings = true,
  isCallback,
}) {
  const [chooseDate, setChooseDate] = useState(new Date())
  const [title, setTitle] = useState('')
  const [feedback, setFeedback] = useState('')
  const [selectedMeetingStatus, setSelectedMeetingStatus] = useState({
    value: -1,
    label: 'Select status',
  })
  const [isNewMeetings, setIsNewMeetings] = useState(isRequestNewMeetings)
  const [createdHistory, setCreatedHistory] = useState([])
  const [isCalendarchecked, setIsCalendarchecked] = useState(false)
  const [candiateStatusList, setCandiateStatusList] = useState([])
  const [isEditModel, setIsEditModel] = useState(false)
  const [editID, setEditID] = useState(-1)

  const [currentMeetDetails, setCurrentMeetDetails] = useState({
    event_link: '',
    title: '',
    jd_name: '',
    client_name: '',
    status_value: '',
    feedback: '',
    scheduled_date: '',
    cv_status: '',
    id: -1,
    is_completed: 0,
    name: '',
    meeting_link: '',
  })

  useEffect(() => {
    setChooseDate(new Date())
    setIsNewMeetings(isRequestNewMeetings)

    if (!isRequestNewMeetings) {
      setCurrentMeetDetails({
        event_link: data?.details?.event_link ?? '',
        title: data?.details?.title ?? '',
        jd_name: data?.details?.jd_name ?? '',
        client_name: data?.details?.client_name ?? '',
        status_value: data?.details?.status_value ?? '',
        feedback: data?.details?.feedback ?? '',
        scheduled_date: data?.details?.scheduled_date ?? '',
        cv_status: data?.details?.cv_status ?? '',
        id: data?.details?.id ?? -1,
        is_completed: data?.details?.is_completed ?? 0,
        name: data?.details?.name ?? '',
        meeting_link: data?.details?.meeting_link ?? '',
      })
      setIsDetailsActive(true)
      setIsHistoryActive(false)
    }
  }, [isOpen])

  function getJourney() {
    getJourneyContactType().then((res) => {
      console.log('setCreatedHistory', res?.data?.Data?.Journey)
      setCandiateStatusList(res?.data?.Data?.Journey ?? [])
    })
  }

  function listOfMeetingHistory() {
    populateDefaultValues()
    console.log('listOfMeetingHistory', data)
    if (!data?.c_id && !data?.jd_id) {
      return
    }
    const utcDates = new Date().toISOString()

    data.current_datetime = utcDates
    console.log('@george_listhistory', utcDates)
    scheduleMeetingHistory(data).then((res) => {
      console.log('listOfMeetingHistory', res)
      setCreatedHistory(res?.data?.Data ?? [])
      const data = res?.data?.Data?.upcoming
      setTitle(data[data?.length - 1]?.title)
    })
  }

  useEffect(() => {
    getJourney()
  }, [])

  useEffect(() => {
    listOfMeetingHistory()
  }, [data])

  const handleStatusChange = (e) => {
    console.log('handleStatusChange', e)
    if (e) {
      setSelectedMeetingStatus({
        value: e.value,
        label: e.label,
      })
    } else {
      populateDefaultValues()
    }
  }

  function resetStates() {
    setTitle('')
    setSelectedMeetingStatus({})
    setFeedback('')
    setChooseDate(new Date())
    setListOfInputs([{ attendeesName: '', attendeesEmail: '' }])

    setIsNewMeetings(true)
    setCreatedHistory([])
    setIsCalendarchecked(false)
    setIsEditModel(false)
    setIsDetailsActive(false)
    setIsHistoryActive(false)
    setCurrentMeetDetails({
      event_link: '',
      title: '',
      jd_name: '',
      client_name: '',
      status_value: '',
      feedback: '',
      scheduled_date: '',
      cv_status: '',
      id: -1,
      is_completed: 0,
      name: '',
      meeting_link: '',
    })
  }

  function populateDefaultValues() {
    setSelectedMeetingStatus({
      value: -1,
      label: 'Select status',
    })
  }

  const makeEventCreated = (data) => {
    setTitle('')
    populateDefaultValues()
    setFeedback('')
  }

  const createMeeting = (payload) => {
    createScheduleMeeting(payload)
      .then((res) => {
        console.log('createdHistory', res)
        setCurrentMeetDetails(() => ({
          event_link: res?.data?.Data?.event_link,
          title: res?.data?.Data?.title,
          jd_name: res?.data?.Data?.jd_name,
          client_name: res?.data?.Data?.client_name,
          status_value: res?.data?.Data?.status_value,
          feedback: res?.data?.Data?.feedback,
          scheduled_date: res?.data?.Data?.scheduled_date,
          cv_status: res.data?.Data?.cv_status ?? '',
          id: res.data?.Data?.id ?? -1,
          is_completed: res.data?.Data?.is_completed ?? 0,
          name: res.data?.Data?.name ?? '',
          meeting_link: res.data?.Data?.meeting_link ?? '',
        }))

        toast.success(
          `New meeting has created successfully to ${res.data?.Data?.name}`,
        )
        // resetStates()
        makeEventCreated(res)
        setIsNewMeetings(!isNewMeetings)
        setIsEditModel(false)
        setIsDetailsActive(true)
        setIsHistoryActive(false)
        listOfMeetingHistory()
        setListOfInputs([{ attendeesName: '', attendeesEmail: '' }])
        setIsCalendarchecked(false)
        isClose({ isRefetched: true })
      })
      .catch((err) => {
        console.log('createdHistory', err)
        toast.error(err)
      })
  }

  const updateMeeting = (payload) => {
    // const datesOnly = createdHistory?.upcoming.map((meeting) => {
    //   const datePart = meeting.scheduled_date.split('T')[0]
    //   return datePart
    // })

    // const currentDate = new Date(payload?.date).toISOString().split('T')[0];

    // const isReschedule = datesOnly?.some(
    //   (date) => date ==currentDate
    // )

    // console.log('chooseDate', chooseDate, datesOnly, isReschedule,payload,currentDate,moment(payload?.date).format('YYYY-MM-DD'))

    // if (isReschedule) {
    //   toast?.error(
    //     `${data?.details?.name}  already has a meeting scheduled for today.`,
    //   )
    //   return
    // }

    updateScheduleMeeting(payload)
      .then((res) => {
        toast.success('Meeting has updated successfully')
        setCurrentMeetDetails(() => ({
          event_link: res?.data?.Data?.event_link,
          title: res?.data?.Data?.title,
          jd_name: res?.data?.Data?.jd_name,
          client_name: res?.data?.Data?.client_name,
          status_value: res?.data?.Data?.status_value,
          feedback: res?.data?.Data?.feedback,
          scheduled_date: res?.data?.Data?.scheduled_date,
          cv_status: res.data?.Data?.cv_status ?? '',
          id: res.data?.Data?.id ?? -1,
          is_completed: res.data?.Data?.is_completed ?? 0,
          name: res.data?.Data?.name ?? '',
          meeting_link: res.data?.Data?.meeting_link ?? '',
        }))
        makeEventCreated(res)
        setIsNewMeetings(!isNewMeetings)
        setIsEditModel(false)
        listOfMeetingHistory()

        setListOfInputs([{ attendeesName: '', attendeesEmail: '' }])
        setIsCalendarchecked(false)
        isClose({ isRefetched: true })
      })
      .catch((err) => {
        console.log('createdHistory', err)
        toast.error(err)
      })
  }

  const scheduleMeeting = () => {
    if (title?.trim() === '') {
      toast.error('Title is required')
      return
    }
    if (chooseDate === undefined) {
      toast.error('Date is required')
      return
    }
    if (Object.keys(selectedMeetingStatus)?.length === 0) {
      toast.error('Status is required')
      return
    }

    let payload = {
      cid: data?.c_id,
      jd_id: data?.jd_id,
      title: title.trim(),
      date: chooseDate.toISOString(),
      zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      status_id: selectedMeetingStatus?.value,
      feedback: feedback.trim(),
    }
    if (isCalendarchecked) {
      let email = []
      listOfInputs.map((e) => {
        // validate email also
        if (e.attendeesEmail.trim() !== '' && e.attendeesEmail.includes('@')) {
          email.push(e.attendeesEmail)
        }
      })

      payload = {
        ...payload,
        attendees_email: email.toString(),
        is_external_event: true,
      }
    }

    if (isEditModel) {
      let temp_id = editID
      if (temp_id === -1) {
        temp_id = currentMeetDetails?.id ?? -1
      }
      payload = {
        ...payload,
        scheduled_task_id: temp_id,
      }
      updateMeeting(payload)
    } else {
      createMeeting(payload)
    }
  }

  const editHistory = (val) => {
    console.log('#editHistory', val)
    setFeedback(val?.feedback ?? '')
    setTitle(val?.title ?? '')
    // setDescription(data?.details?.description)
    // convert date with moment
    setChooseDate(moment(val?.scheduled_date).toDate())

    setSelectedMeetingStatus(() => ({
      value: val?.cv_status,
      label: val?.status_value,
    }))

    setEditID(val?.id ?? -1)

    // check attendees email available or not
    if (val?.attendee_emails?.trim()?.length > 0) {
      console.log('attendee_emails', val?.attendee_emails)
      let emails = val?.attendee_emails?.split(',')
      let newInputs = emails.map((e) => {
        return { attendeesName: '', attendeesEmail: e }
      })
      setListOfInputs(newInputs)
      setIsCalendarchecked(true)
    } else if (val?.attendee_emails?.trim()?.length === 0) {
      setListOfInputs([{ attendeesName: '', attendeesEmail: '' }])
      setIsCalendarchecked(false)
    }

    // if its coming from calendar or home page
    // then attendees will be in data
    if (data?.details?.attendees?.length > 0) {
      let newInputs = val?.attendees.map((e) => {
        return { attendeesName: '', attendeesEmail: e?.email }
      })
      setListOfInputs(newInputs)
      setIsCalendarchecked(true)
    }

    setIsNewMeetings(true)
    setIsEditModel(true)
  }

  const toggle = () => {
    resetStates()
    isClose((prev) => ({ ...prev, isOpen: !isOpen }))
    setIsCalendarchecked(false)
    console.log('inputs', inputs)
  }

  //  create dynamically inputs fields

  const [listOfInputs, setListOfInputs] = useState([
    { attendeesName: '', attendeesEmail: '' },
  ])
  const [inputs, setInputs] = useState([])

  const handleAddInput = () => {
    setListOfInputs([
      ...listOfInputs,
      { attendeesName: '', attendeesEmail: '' },
    ])
  }

  const handleInputChange = (index, name, value) => {
    const newInputs = [...listOfInputs]
    if (value === '') {
      newInputs.splice(index, 1)
    } else {
      newInputs[index][name] = value
    }
    setListOfInputs(newInputs)
  }

  const [isLoading, setIsLoading] = useState(false)

  const createOption = (label) => ({
    value: label.trim(),
    rank: candiateStatusList.length + 1,
  })

  const handleCreate = (inputValue) => {
    setIsLoading(true)
    setTimeout(() => {
      const newOption = createOption(inputValue)

      setIsLoading(false)
      setCandiateStatusList((prev) => [...prev, newOption])

      postJourneyType(newOption?.rank, newOption?.value).then((res) => {
        getJourney()
        toast.success('New Status created successfully')
        setSelectedMeetingStatus(() => ({
          value: res?.data?.Data?.id,
          label: res?.data?.Data?.value,
        }))
      })
    }, 1000)
  }

  const [isDetailsActive, setIsDetailsActive] = useState(true)
  const [isHistoryActive, setIsHistoryActive] = useState(false)

  const handleEventClick = (e, link) => {
    e.preventDefault()
    window.open(link, '_blank')
  }

  const handleDelete = (data) => {
    let id = data?.id || data.details?.id
    deleteScheduleMeeting(id).then((res) => {
      toast.success('Meeting has deleted successfully')
      // remove from list
      let newHistory = createdHistory.filter((e) => e.id !== id)
      setCreatedHistory(newHistory)

      // if user delete current meeting
      if (id === currentMeetDetails?.id) {
        setCurrentMeetDetails({
          event_link: '',
          title: '',
          jd_name: '',
          client_name: '',
          status_value: '',
          feedback: '',
          scheduled_date: '',
          cv_status: '',
          id: -1,
          is_completed: 0,
          name: '',
          meeting_link: '',
        })
        // close t
      }
    })
  }

  const markAsDone = (data) => {
    let id = data?.id || data.details?.id

    console.log('@george_mark', createdHistory, data)
    patchScheduledMeeting(id, 1).then((res) => {
      toast.success('Meeting has marked as completed')

      let newHistory = createdHistory?.upcoming?.map((e) => {
        if (e.id === id) {
          e.is_completed = 1
        }
        return e
      })
      setCreatedHistory(newHistory)
      //   check if current meeting is marked as done
      if (id === currentMeetDetails?.id) {
        setCurrentMeetDetails({
          ...currentMeetDetails,
          is_completed: 1,
        })
      }
    })
  }

  console.log('currentMeetDetails', currentMeetDetails)

  return (
    <div>
      <Card>
        <Fragment>
          <Modal
            isOpen={isOpen}
            toggle={toggle}
            className="modal-dialog-centered modal-refer-earn modal-lg"
            backdrop="static"
          >
            <ModalHeader className="bg-transparent w-100" toggle={toggle}>
              <h4 className="fw-bolder">
                {/*Schedule Meeting {isNewMeetings ? '' : 'History'}*/}
                {isNewMeetings && 'Schedule Meeting'}
                {!isNewMeetings && isHistoryActive && 'Interaction History'}

                {!isNewMeetings && isDetailsActive && 'Upcoming Meeting'}
              </h4>
            </ModalHeader>
            <ModalBody>
              <div className="row">
                <div className="col-12">
                  <div className="row mb-1">
                    <div className="col-5">
                      <DatePicker
                        label={'Scheduled Date'}
                        defaultValue={dayjs(chooseDate)}
                        value={dayjs(chooseDate)}
                        selected={dayjs(chooseDate)}
                        onChange={(date) => {
                          console.log('date', date)
                          if (date === null) {
                            return
                          }
                          setChooseDate(date?.toDate())
                        }}
                        showTime={{ format: 'HH:mm' }}
                        format="YYYY-MM-DD HH:mm a"
                        placeholder="Choose a Date"
                        className={isNewMeetings ? 'd-block w-100' : 'd-none'}
                        size="large"
                        disabledDate={(current) =>
                          current && current < moment().startOf('day')
                        }
                      />
                    </div>
                    <div className="col-7 text-end">
                      {isNewMeetings ? (
                        <Button
                          className=""
                          outline
                          color="primary"
                          onClick={() => {
                            setIsNewMeetings(() => !isNewMeetings)
                            setIsEditModel(false)
                            setIsDetailsActive(false)
                            setIsHistoryActive(true)
                          }}
                        >
                          Go to history
                        </Button>
                      ) : (
                        <div>
                          <ButtonGroup>
                            <Button
                              tag="label"
                              style={{
                                fontWeight: 'bold',
                                backgroundColor: isDetailsActive
                                  ? 'rgba(115, 103, 240, 0.2)'
                                  : 'white',
                              }}
                              color="primary"
                              outline
                              onClick={() => {
                                setIsDetailsActive(true)
                                setIsHistoryActive(false)
                              }}
                            >
                              Current / Upcoming
                            </Button>

                            <Button
                              tag="label"
                              style={{
                                fontWeight: 'bold',
                                backgroundColor: isHistoryActive
                                  ? 'rgba(115, 103, 240, 0.2)'
                                  : 'white',
                              }}
                              color="primary"
                              outline
                              onClick={() => {
                                setIsDetailsActive(false)
                                setIsHistoryActive(true)
                              }}
                            >
                              History
                            </Button>
                            <Button
                              color="primary"
                              outline
                              style={{
                                fontWeight: 'bold',
                              }}
                              onClick={() => {
                                setIsNewMeetings(() => !isNewMeetings)
                              }}
                            >
                              New Meeting
                            </Button>
                          </ButtonGroup>
                        </div>
                      )}
                    </div>
                  </div>
                  <div>
                    <div className={isNewMeetings ? 'd-block' : 'd-none'}>
                      <div className="row mb-1">
                        <div className="col-6">
                          <label htmlFor="" className="form-label">
                            Title
                            <sup className="text-danger font-smll-4">*</sup>
                          </label>
                          <Input
                            id=""
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            placeholder="title"
                            type="text"
                          />
                        </div>
                        <div className="col-6">
                          <label htmlFor="" className="form-label">
                            Choose Status
                            <sup className="text-danger font-smll-4">*</sup>
                          </label>

                          <CreatableSelect
                            isClearable
                            isDisabled={isLoading}
                            isLoading={isLoading}
                            value={selectedMeetingStatus}
                            onChange={handleStatusChange}
                            onCreateOption={handleCreate}
                            options={candiateStatusList.map((data) => {
                              return { value: data?.id, label: data?.value }
                            })}
                          />
                        </div>
                      </div>
                      <div className="row mb-1">
                        <div className="col-12 ">
                          <label htmlFor="" className="form-label">
                            Feedback
                          </label>

                          <Input
                            id=""
                            name=""
                            value={feedback}
                            onChange={(e) => setFeedback(e.target.value)}
                            placeholder="feedback"
                            type="textarea"
                          />
                        </div>
                      </div>
                      <div className="row mb-1">
                        <div className="col-6 "></div>
                        <div className="col-6 text-end">
                          <div className="d-flex align-items-center justify-content-end mt-1">
                            <Checkbox
                              id="googleCalendar_checkbox"
                              checked={isCalendarchecked}
                              onChange={(e) => {
                                console.log(
                                  'isCalendarchecked',
                                  e.target.checked,
                                )
                                setIsCalendarchecked(e.target.checked)
                              }}
                              inputProps={{ 'aria-label': 'controlled' }}
                            />
                            <label
                              className="form-label cursor-pointer"
                              for="googleCalendar_checkbox"
                            >
                              Add Google Calendar
                            </label>
                          </div>
                        </div>
                      </div>
                      <div>
                        {isCalendarchecked && (
                          <div>
                            <h5 className="fw-bolder">Meeting Participants </h5>
                            <div>
                              {listOfInputs?.map((input, index) => {
                                return (
                                  <div className="row mb-1">
                                    <div className="col-12">
                                      <label
                                        htmlFor="floatingInput"
                                        className="form-label"
                                      >
                                        Email
                                        <sup className="text-danger font-smll-4">
                                          *
                                        </sup>
                                      </label>
                                      <div className="d-flex align-items-center">
                                        <Input
                                          id={`attendeesEmail_${index}`}
                                          variant="standard"
                                          name={`attendeesEmail_${index}`}
                                          type={'email'}
                                          placeholder="email`"
                                          value={input?.attendeesEmail}
                                          onChange={(e) =>
                                            handleInputChange(
                                              index,
                                              'attendeesEmail',
                                              e?.target?.value,
                                            )
                                          }
                                        />
                                        <Button
                                          color="danger"
                                          className=" ms-1  p-0   "
                                          onClick={() =>
                                            handleInputChange(
                                              index,
                                              'attendeesEmail',
                                              '',
                                            )
                                          }
                                        >
                                          <X size={15} />
                                        </Button>
                                      </div>
                                    </div>
                                  </div>
                                )
                              })}
                            </div>
                            <div className="text-end mt-2">
                              <Button
                                variant="text"
                                color="primary"
                                disableElevation
                                onClick={handleAddInput}
                              >
                                Add Participants
                              </Button>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className={isNewMeetings ? 'd-none' : 'd-block'}>
                    <PerfectScrollbar
                      component="li"
                      className="media-list scrollable-container list-unstyled"
                      style={{
                        maxHeight: '25em',
                      }}
                      onScrollY={(container) =>
                        console.log(`scrolled to: ${container.scrollTop}.`)
                      }
                    >
                      {isDetailsActive && (
                        <>
                          {createdHistory?.upcoming?.length ? (
                            <>
                              {createdHistory?.upcoming?.map((data) => {
                                return (
                                  <>
                                    <MeetingCard
                                      data={data}
                                      handleEventClick={handleEventClick}
                                      markAsDone={markAsDone}
                                      editHistory={editHistory}
                                      handleDelete={handleDelete}
                                    />
                                  </>
                                )
                              })}
                            </>
                          ) : (
                            <Card className="bg-light-primary rounded mb-1">
                              <CardBody className="p-1 text-center">
                                No Upcoming Meeting available
                              </CardBody>
                            </Card>
                          )}
                        </>
                      )}

                      {isHistoryActive && (
                        <>
                          {createdHistory?.history?.length ? (
                            <>
                              {createdHistory?.history?.map((data) => {
                                return (
                                  <>
                                    <MeetingCard
                                      data={data}
                                      handleEventClick={handleEventClick}
                                      markAsDone={markAsDone}
                                      editHistory={editHistory}
                                      handleDelete={handleDelete}
                                    />
                                  </>
                                )
                              })}
                            </>
                          ) : (
                            <Card className="bg-light-primary rounded mb-1">
                              <CardBody className="p-1 text-center">
                                No history available
                              </CardBody>
                            </Card>
                          )}
                        </>
                      )}
                    </PerfectScrollbar>
                  </div>
                </div>
              </div>
            </ModalBody>

            <ModalFooter>
              {isNewMeetings ? (
                <>
                  <Button
                    onClick={toggle}
                    color="primary"
                    outline
                    className="me-1"
                  >
                    Discard
                  </Button>
                  <Button color="primary" onClick={scheduleMeeting}>
                    {isEditModel ? 'Update' : 'Schedule'}
                  </Button>
                </>
              ) : (
                ''
              )}
            </ModalFooter>
          </Modal>
        </Fragment>
      </Card>
    </div>
  )
}

const mapStateToProps = (state) => ({
  token: state.LoginReducers.token,
  uuid: state.LoginReducers.uuid,
})

export default withStyles(styles, { withTheme: true })(
  withRouter(connect(mapStateToProps, {})(ScheduleMeeting)),
)
