import { _apps, initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'
import { getAnalytics } from 'firebase/analytics'
import { getPerformance } from 'firebase/performance'
import { getMessaging } from 'firebase/messaging'

let app = null

if (!_apps.length) {
  console.log('DEBUG', 'adding app')
  app = initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
  })

  console.log('DEBUG', app)
} else {
  app = _apps[0]
}

export const firebaseAuth = getAuth(app)
export const firebaseDB = getFirestore(app)
export const firebaseAnalytics = getAnalytics(app)
export const firebasePerformance = getPerformance(app)
export const firebaseMessaging = getMessaging(app)
