import React from 'react'
import withStyles from '@mui/styles/withStyles'
import { styles } from '../../jd/incomplete/Helper'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

function LinkedinCVProfileTab(props) {
  return (
    <div>
      <img src={props.linkedinCV} alt={'LinkedinCV'} />
    </div>
  )
}

const mapStateToProps = (state) => ({
  token: state.LoginReducers.token,
  uuid: state.LoginReducers.uuid,
})

const mapDispatchToProps = (dispatch) => ({})

export default withStyles(styles, { withTheme: true })(
  withRouter(
    connect(mapStateToProps, mapDispatchToProps)(LinkedinCVProfileTab),
  ),
)
