import instance from './API'

export function getProfileCardData(token, cvID) {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
    params: {
      cvID,
    },
  }

  return instance.get('cv/profile/card/', config)
}

export function getProfileCardDetails(token, cvID, jdID) {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
    params: {
      cvID,
      jdID,
    },
  }

  return instance.get('cv/profile/details/', config)
}

export function requestLinkedin(token, jdID, cv_ids) {
  const formData = new FormData()
  formData.append('jdID', jdID)
  formData.append('cvIDs', cv_ids)

  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
  }

  return instance.post('cv/linkedin/', formData, config)
}

export function updateCandidateStatus(token, jdID, cvID, journey, comments) {
  const formData = new FormData()
  formData.append('journey_id', journey)
  formData.append('cvID', cvID)
  formData.append('jdID', jdID)
  formData.append('comments', comments)

  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
  }

  return instance.post('cv/status/', formData, config)
}

export function updateBulkCandidateStatus(
  token,
  jdID,
  cvID,
  journey,
  comments,
) {
  const formData = new FormData()
  formData.append('jdID', jdID)
  formData.append('journey_id', journey)
  formData.append('cids', cvID)
  formData.append('comments', comments)

  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
  }

  return instance.post('cv/status/all/', formData, config)
}
