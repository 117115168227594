const initialState = {
  data: {
    image_url: '',
    fullname: '',
    company_name: '',
    designation: '',
    short_description: '',
    phone_number: '',
    username: '',
    email: '',
    role: '',
  },
  fetchTime: Date.now(),
  error: '',
  isLoading: true,
  redirect: false,
  path: '',
  isUserAdded: false,
  managerData: {},
}

const UserProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'PROFILE_SAVE':
      console.log('action.data', action.data, action.data?.company)
      return {
        ...state,
        data: {
          ...state.data,
          image_url: action.data?.image_url ?? '',
          fullname: action.data?.fullname ?? '',
          phone_number: action.data?.phone_number ?? '',
          username: action.data?.username ?? '',
          email: action.data?.email ?? '',
          company_name: action.data?.company ?? '',
          designation: action.data?.designation ?? '',
          short_description: action.data?.short_description ?? '',
          role: action.data.role ?? '',
        },
        fetchTime: action.fetchTime,
        isLoading: false,
        error: '',
      }

    case 'PROFILE_ERROR':
      return {
        ...state,
        error: action.error,
        isLoading: false,
      }
    case 'PASSWORD_ERROR':
      return {
        ...state,
        error: action.error,
        isLoading: false,
      }
    case 'PROFILE_REDIRECT':
      return {
        ...state,
        path: action.path,
        error: action.error,
        isLoading: false,
        redirect: true,
      }
    case 'PROFILE_CLEAR':
      return {
        ...state,
        data: initialState.data,
        fetchTime: initialState.fetchTime,
        error: initialState.fetchTime,
        isLoading: initialState.fetchTime,
        redirect: initialState.fetchTime,
        path: initialState.fetchTime,
      }
    case 'ADD_USER':
      return {
        ...state,
        isUserAdded: true,
        error: '',
        isLoading: false,
      }
    case 'ADD_USER_ERROR':
      return {
        ...state,
        isUserAdded: false,
        error: action.error,
        isLoading: false,
      }
    case 'GET_MANAGER':
      return {
        ...state,
        managerData: action.data.Data,
        error: '',
        isLoading: false,
      }
    default:
      return state
  }
}

export default UserProfileReducer
