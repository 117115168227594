//This function will calculate the time difference
export function checkUpdateRequired(fetchTime, cacheTime = 5) {
  const now = Date.now()
  const pre = new Date(fetchTime)
  const diff = parseInt((now - pre) / (1000 * 60 * 60), 10)
  return diff > cacheTime
}

export function analysisResponseStatus(status) {
  if (status === null) {
    return {
      redirect: false,
      path: '',
    }
  }

  switch (true) {
    //less than 210 means response ok
    case status < 210:
      console.log('Response math 210')
      return {
        redirect: false,
        path: '',
      }
    // 401 means token change
    case 401 === status:
      console.log('Response math 401')
      return {
        redirect: false,
        path: '',
      }

    //bad request
    //todo need to discuss how to handle bad request
    //this can be happen very rarer
    case 400 === status:
      return {
        redirect: false,
        path: '',
      }
    //requested url not found
    //todo this also need to done from frontend
    //it's also rare case for backend
    case 404 === status:
      return {
        redirect: true,
        path: '/session/404',
      }

    case 500 >= status:
      return {
        redirect: true,
        path: '/error/500/',
      }

    default:
      console.log('Response math none')
      return {
        redirect: true,
        path: '',
      }
  }
}
