import instance from './API'

//get files
export function sendContactUsEmail(token, userId, data) {
  const formData = new FormData()
  formData.append('userId', userId)
  formData.append('group', data.group)
  formData.append('username', data.username)
  formData.append('email', data.email)
  formData.append('subject', data.subject)
  formData.append('des', data.des)
  formData.append('files', data.files)

  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
  }

  return instance.post('settings/contactus/', formData, config)
}

export function sendGlobalContactUsEmail(data) {
  const formData = new FormData()
  formData.append('group', data.group)
  formData.append('username', data.username)
  formData.append('email', data.email)
  formData.append('subject', data.subject)
  formData.append('des', data.des)
  formData.append('files', data.files)

  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  }

  return instance.post('global/contactus/', formData, config)
}

export function createScheduleMeeting(data) {
  const formData = new FormData()
  formData.append('cid', data.cid)
  formData.append('jd_id', data.jd_id)
  formData.append('title', data.title)
  formData.append('scheduled_date', data.date)
  formData.append('zone', data.zone)
  formData.append('status_id', data.status_id)
  formData.append('feedback', data.feedback)
  if (data?.attendees_email) {
    formData.append('attendees_email', data.attendees_email)
  }
  if (data?.is_external_event) {
    formData.append('is_external_event', data.is_external_event)
  }

  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  }

  return instance.post('cv/scheduledtask/', formData, config)
}

export function updateScheduleMeeting(data) {
  console.log('createdHistory', data?.google_calendar)
  // scheduled_task_id
  const formData = new FormData()
  formData.append('scheduled_task_id', data.scheduled_task_id)
  formData.append('cid', data.cid)
  formData.append('jd_id', data.jd_id)
  formData.append('title', data.title)
  formData.append('scheduled_date', data.date)
  formData.append('zone', data.zone)
  formData.append('status_id', data.status_id)
  formData.append('feedback', data.feedback)

  if (data?.attendees_email) {
    formData.append('attendees_email', data.attendees_email)
  }
  if (data?.is_external_event) {
    formData.append('is_external_event', data.is_external_event)
  }

  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  }

  return instance.put('cv/scheduledtask/', formData, config)
}

export function patchScheduledMeeting(id, isComplete) {
  const formData = new FormData()
  formData.append('id', id)
  formData.append('is_complete', isComplete)

  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  }

  return instance.patch('cv/scheduledtask/', formData, config)
}

export function deleteScheduleMeeting(id) {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      id,
    },
  }

  return instance.delete('cv/scheduledtask/', config)
}

export function scheduleMeetingHistory(data) {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  }

  return instance.get(
    `cv/scheduledtask/?cid=${data?.c_id}&jd_id=${data?.jd_id}&current_datetime=${data?.current_datetime}`,
    config,
  )
}
