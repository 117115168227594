import history from '@src/history'
import { LEFT_SIDE_CANCEL_ICON } from '@src/utility/utils/constants'

export const SET_USER_NAVIGATION = 'SET_USER_NAVIGATION'
export const ADD_EVALUATE_TO_NAVIGATION = 'ADD_EVALUATE_TO_NAVIGATION'
export const REMOVE_ELEMENT = 'REMOVE_ELEMENT'
export const RESET_NAVIGATION_SIDEBAR = 'RESET_NAVIGATION_SIDEBAR'
//import { AddJdToNavigation } from '@store/navbar'

const getfilteredNavigations = (navList = [], role) => {
  return navList.reduce((array, nav) => {
    if (nav.auth) {
      if (nav.auth.includes(role)) {
        array.push(nav)
      }
    } else {
      if (nav.children) {
        nav.children = getfilteredNavigations(nav.children, role)
        array.push(nav)
      } else {
        array.push(nav)
      }
    }
    return array
  }, [])
}

export function getNavigationByUser() {
  return (dispatch, getState) => {
    let { user, navigations = [] } = getState()
    let filteredNavigations = getfilteredNavigations(navigations, user.role)

    dispatch({
      type: SET_USER_NAVIGATION,
      payload: [...filteredNavigations],
    })
  }
}

export function addEvaluateToNavigation(jd_id, jd_name) {
  return function (dispatch) {
    console.log('ADDEVALUATE1', jd_id, jd_name)
    dispatch({
      type: ADD_EVALUATE_TO_NAVIGATION, //ADD_EVALUATE_TO_NAVIGATION,
      payload: {
        title: 'JD - ' + jd_name,
        id: 'JDID - ' + jd_name,
        children: [
          {
            title: 'JD Summary',
            id: jd_id + '-summary',
            navLink: '/evaluateJD/summary/' + jd_id,
          },
          {
            title: 'WorkFlow',
            id: jd_id + '-workflow',
            navLink: '/apps/getworkflow/' + jd_id,
          },
          {
            title: 'Track Candidates',
            id: jd_id + '-candidates',
            navLink: '/evaluateJD/candidates/' + jd_id,
          },
          {
            title: 'Screened Candidates',
            id: jd_id + '-shortlisted',
            navLink: '/shortlistedcvs/' + jd_id,
          },
        ],
      },
      jd_id: jd_id,
    })
  }
}

export function removeElementFromNavigation(val, jdID) {
  return function (dispatch) {
    dispatch({
      type: REMOVE_ELEMENT,
      value: val,
    })

    dispatch({
      type: 'JD_REMOVE_ELEMENT',
      jd_id: jdID,
    })

    dispatch({
      type: 'JD_SETTINGS_REMOVE_ELEMENT',
      jd_id: jdID,
    })

    dispatch({
      type: 'JD_CANDIDATE_REMOVE_ELEMENT',
      jd_id: jdID,
    })

    history.push({
      pathname: '/jd/incomplete/',
    })
  }
}
