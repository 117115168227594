import moment from 'moment'

export function getProcessing(date) {
  if (date === null || date === undefined) return ''

  let mom = moment(date)
  let zone = Intl.DateTimeFormat().resolvedOptions().timeZone
  let finalDate = mom.tz(zone)

  return finalDate.fromNow(true) + ' ago'
}

export function getLinkedinStatus(data) {
  if (data.length === 0) return ''
  console.log('LinkedinButton', data[0].social_media_name)
  return data[0].social_media_name
}

export function getCloudStatus(data) {
  if (data.length === 0) return false
  console.log('DEBUG getCloudStatus', data)
  if (data[0].cloud_processing_id > 0 || data[0].ocr_labels_id > 0) {
    return false
  }
  console.log('LinkedinButton', data)
  return false
}

export function getLinkedinLink(data) {
  if (data.length === 0) return ''
  return data[0].social_url ?? ''
}

export function getStatus(data) {
  console.log('DEBUG Candidate Status', data)
  if (data.length === 0) return 0
  return data[0].status_id ?? 0
}

export function getPhone(data) {
  console.log('GOT called with empty data', data)
  if (data.length === 0) return ''
  console.log('GOT PHONE')
  return data[0].phone_number
}

export function getEmail(data) {
  if (data.length === 0) return ''
  console.log('GOT Email')
  return data[0].email
}

export function getAppliedJobs(data) {
  if (data.length === 0) return []

  let jobs = []
  let seen = []

  data.map((e) => {
    if (!seen.includes(e.jd_id)) {
      seen.push(e.jd_id)
      jobs.push({
        id: e.jd_id,
        name: e.jd_name,
        role: e.designation,
      })
    }
  })
  return jobs
}
