import instance from './API'

//get files
export function cvinteract(token, cvID) {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
    params: {
      cvID,
    },
  }

  return instance.get('cv/contact/', config)
}

export function postCvInteract(
  token,
  cvID,
  jdID,
  action,
  oldStatus,
  newStatus,
  comments,
  date,
) {
  const formData = new FormData()
  formData.append('jdID', jdID)
  formData.append('cvID', cvID)
  formData.append('action', action)
  formData.append('oldStatus', oldStatus)
  formData.append('newStatus', newStatus)
  formData.append('comments', comments)
  formData.append('date', date)

  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
  }

  return instance.post('cv/contact/', formData, config)
}

//get files
export function deleteCvInteract(token, id) {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
    data: {
      id,
    },
  }

  return instance.delete('cv/contact/', config)
}

//get files
export function cvanalysis(token, cvID) {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
    params: {
      cvID,
    },
  }

  return instance.get('cv/analysis/', config)
}

export function updateEmailPhone(token, cvID, type, value) {
  const formData = new FormData()
  formData.append('cvID', cvID)
  if (type === 'Email') {
    formData.append('email', value)
  } else {
    formData.append('phone', value)
  }

  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
  }

  return instance.post('cv/details/', formData, config)
}

export function updateEmailPhoneCandidateCard(cvID, email, phone) {
  const formData = new FormData()
  formData.append('cvID', cvID)
  if (phone !== '') {
    formData.append('phone', phone)
  }
  if (email !== '') {
    formData.append('email', email)
  }

  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  }

  return instance.post('cv/details/', formData, config)
}
