// import qs from 'query-string';
import qs from 'query-string'
import instance from './API'

// send login request to the server
export function requestLogin(username, password) {
  const requestBody = {
    username,
    password,
  }

  const config = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  }

  return instance.post('login/', qs.stringify(requestBody), config)
}

//get profile
export function getEmployeeIds(token, userID, role) {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
    params: {
      uuid: userID,
      role,
    },
  }

  return instance.get('/user/team/subordinate/', config)
}

//get siblings
export function getSiblingIds(token, userID) {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
    params: {
      uuid: userID,
    },
  }

  return instance.get('/user/team/sibling/', config)
}

export function getUserInfo() {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  }

  return instance.get('/user/me/', config)
}

export function updateUserInfo() {
  return instance.put('/user/me', {})
}

export function saveFCMToken(fcm) {
  const formData = new FormData()
  formData.append('fcm', fcm)
  formData.append('device', 'web')

  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  }

  return instance.post('fcm/', formData, config)
}
