import instance from './API'

export function getSettingsData(token, userID) {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
    params: {
      userID,
    },
  }

  return instance.get('settings/login', config)
}

export function postSettingsData(token, userID, data) {
  const formData = new FormData()
  formData.append('userID', userID)
  formData.append('naukri_email', data.naukri_email)
  formData.append('naukri_pass', data.naukri_pass)
  formData.append('monster_email', data.monster_email)
  formData.append('monster_pass', data.monster_pass)
  formData.append('indeed_email', data.indeed_email)
  formData.append('indeed_pass', data.indeed_pass)
  formData.append('linkedin_email', data.linkedin_email)
  formData.append('linkedin_pass', data.linkedin_pass)

  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
  }

  return instance.post('settings/login/', formData, config)
}

/***************
 * College Tier
 ***************/
export function getCollegeTier(token) {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
  }

  return instance.get('settings/college/', config)
}

export function postCollegeTier(token, collegeName, tierLevel) {
  const formData = new FormData()
  formData.append('college_name', collegeName)
  formData.append('tier_level', tierLevel)

  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
  }

  return instance.post('settings/college/', formData, config)
}

//update Colleges
export function updateCollegeTier(token, id, collegeName, tierLevel) {
  const formData = new FormData()
  formData.append('id', id)
  formData.append('college_name', collegeName)
  formData.append('tier_level', tierLevel)

  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
  }
  return instance.put('settings/college/', formData, config)
}

//delete Colleges
export function deleteCollegeTier(token, id) {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
    data: {
      id,
    },
  }

  return instance.delete('settings/college/', config)
}

/***************
 * Company Tier
 ***************/
export function getCompanyTier(token) {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
  }

  return instance.get('settings/company/', config)
}

export function postCompanyTier(token, companyName, tierLevel) {
  const formData = new FormData()
  formData.append('company_name', companyName)
  formData.append('tier_level', tierLevel)

  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
  }

  return instance.post('settings/company/', formData, config)
}

//update Company
export function updateCompanyTier(token, id, companyName, tierLevel) {
  const formData = new FormData()
  formData.append('id', id)
  formData.append('company_name', companyName)
  formData.append('tier_level', tierLevel)

  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
  }
  return instance.put('settings/company/', formData, config)
}

//delete Company
export function deleteCompanyTier(token, id) {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
    data: {
      id,
    },
  }

  return instance.delete('settings/company/', config)
}

/***************
 * Contact Type
 ***************/
export function getJourneyContactType() {
  const config = {
    headers: {
      // Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
  }

  return instance.get('settings/candidates/constant/', config)
}

export function postContactType(rank, value) {
  const formData = new FormData()
  formData.append('rank', rank)
  formData.append('value', value)

  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  }

  return instance.post('settings/candidates/contact/', formData, config)
}

//update Colleges
export function updateContactType(token, id, rank, value) {
  const formData = new FormData()
  formData.append('id', id)
  formData.append('rank', rank)
  formData.append('value', value)

  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
  }
  return instance.put('settings/candidates/contact/', formData, config)
}

//delete Colleges
export function deleteContactType(token, id) {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
    data: {
      id,
    },
  }

  return instance.delete('settings/candidates/contact/', config)
}

/*************************
 * Candidate Journey Type
 *************************/
export function postJourneyType(rank, value) {
  const formData = new FormData()
  formData.append('rank', rank)
  formData.append('value', value)
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  }

  return instance.post('settings/candidates/journey/', formData, config)
}

//update Colleges
export function updateJourneyType(token, id, rank, value) {
  const formData = new FormData()
  formData.append('id', id)
  formData.append('rank', rank)
  formData.append('value', value)

  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
  }

  return instance.put('settings/candidates/journey/', formData, config)
}

//delete Colleges
export function deleteJourneyType(token, id) {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
    data: {
      id,
    },
  }

  return instance.delete('settings/candidates/journey/', config)
}

//***********************
export function startBulkProcessing(token, bulkIDs) {
  const formData = new FormData()
  formData.append('bulkIDs', bulkIDs)

  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
  }

  return instance.post('settings/bulkprocess/start/', formData, config)
}

/** Bulk Data Processing Status API */

export function updateBulkProcessing(id, name) {
  const formData = new FormData()

  formData.append('id', id)
  formData.append('name', name)

  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  }

  return instance.put('resume/bulkprocess/', formData, config)
}

export function deleteBulkProcessing(token, id) {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
    data: {
      id,
    },
  }

  return instance.delete('resume/bulkprocess/', config)
}

//upload bulk processing
export function uploadBulkReferralsProcessing(token, file) {
  const formData = new FormData()
  formData.append('file', file)

  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
  }

  return instance.post('resume/referrals/', formData, config)
}

export function reUploadBulkReferrals(token, id, file) {
  const formData = new FormData()
  formData.append('referrals', file)
  formData.append('bulk_id', id)

  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
  }

  return instance.put('settings/referrals/export/', formData, config)
}

export function downloadBulkReferrals(token, id) {
  const formData = new FormData()
  formData.append('bulk_id', id)

  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
  }

  return instance.post('settings/referrals/export/', formData, config)
}

//data store

export function getDataStores(token) {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
  }

  return instance.get('settings/store/', config)
}

export function getGoogleDriveConfig() {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  }

  return instance.get('config/drive/', config)
}

export function getGoogleDriveTokenConfig(token) {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
  }

  return instance.get('config/drive/token/', config)
}

// export function addDataStore(
//   token,
//   name,
//   bucket_name,
//   store_config,
//   is_default,
//   type,
// ) {
//   const formData = new FormData()
//   const store = JSON.stringify({
//     name,
//     bucket_name,
//     config: store_config,
//     is_default,
//     type,
//   })

//   formData.append('store', store)

//   const config = {
//     headers: {
//       Authorization: `Token ${token}`,
//       'Content-Type': 'application/json',
//     },
//   }

//   return instance.post('settings/store/', formData, config)
// }
export function addDataStore(name, bucket_name, store_config, type) {
  const formData = new FormData()
  const store = JSON.stringify({
    name,
    bucket_name,
    config: store_config,
    type,
  })
  formData.append('store', store)

  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  }
  return instance.post('settings/store/', formData, config)
}
export function updateDataStore(id) {
  const formData = new FormData()
  formData.append('id', id)

  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  }

  return instance.put('settings/store/', formData, config)
}
export function deleteDataStore(token, id) {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
    data: {
      id,
    },
  }
  return instance.delete('settings/store/', config)
}

export function getEmailTemplate() {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  }

  return instance.get('settings/email/template/', config)
}

export function createEmailTemplate(
  token,
  id,
  name,
  content,
  params,
  templateType,
) {
  const formData = new FormData()
  formData.append('id', id)
  formData.append('name', name)
  formData.append('content', content)
  formData.append('values', params)
  formData.append('template_type', templateType)

  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
  }

  return instance.post('settings/email/template/', formData, config)
}

export function deleteEmailTemplate(token, id) {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
    data: {
      id,
    },
  }

  return instance.delete('settings/email/template/', config)
}

export function postLinkedinStatsSettings(data) {
  console.log('DATA ', data)
  const formData = new FormData()
  formData.append('public_profile', data.public_profile)
  formData.append('recruiter_profile', data.recruiter_profile)
  formData.append('inmail', data.inmail)
  formData.append('message', data.message)
  formData.append('connection_request', data.connection_request)
  formData.append('leads', data.leads)

  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  }

  return instance.post('linkedin/stats/settings/', formData, config)
}

export function getLinkedinStatsSettings() {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  }

  return instance.get('linkedin/stats/settings/', config)
}
