import React, { Fragment } from 'react'
import withStyles from '@mui/styles/withStyles'
//import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog'
import MuiDialogTitle from '@mui/material/DialogTitle'
import MuiDialogContent from '@mui/material/DialogContent'
import MuiDialogActions from '@mui/material/DialogActions'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Typography from '@mui/material/Typography'
import {
  Card,
  Badge,
  Input,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from 'reactstrap'

const styles = (theme) => ({
  root: {
    margin: 0,
    // padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    // right: theme.spacing(1),
    // top: theme.spacing(1),
    // color: theme.palette.grey[500],
  },
})

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const DialogContent = withStyles((theme) => ({
  root: {
    // padding: theme.spacing(2),
  },
}))(MuiDialogContent)

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    // padding: theme.spacing(1),
  },
}))(MuiDialogActions)

export default function ChangeCandidateStatus({
  showCandidateStatusDialog,
  handleClose,
  journeys,
  data,
  statusID,
  statusComments,
  onCommentsChange,
  handleUpdateStatus,
}) {
  const [isOpen, setIsOpen] = React.useState(false)

  return (
    <Card>
      <Fragment>
        <Modal
          isOpen={showCandidateStatusDialog}
          onClosed={handleClose} //@Todo : Close button not working
          toggle={handleClose}
          className="modal-dialog-centered " //modal-lg
        >
          <ModalHeader className="bg-transparent" toggle={handleClose}>
            <h4 className="">Update Candidate Status</h4>
          </ModalHeader>
          <ModalBody className="">
            <div className="d-flex justify-content-between">
              <p className="">
                <b className="fw-bolder opacity-75">Old Status</b> -{' '}
                <Badge color="light-primary" className="me-1 mb-1 border " pill>
                  {data.status}
                </Badge>{' '}
              </p>

              <p className="">
                <b className="fw-bolder opacity-75">New Status</b> -{' '}
                <Badge color="light-info" className="me-1 mb-1 border " pill>
                  {statusID.label}
                </Badge>{' '}
              </p>
            </div>
            <h6 htmlFor="floatingInput">Add Comment</h6>
            <Input
              typeof="textarea"
              autoFocus
              margin="dense"
              id="name"
              multiline
              minRows={1}
              maxRows={5}
              value={statusComments}
              onChange={onCommentsChange}
              placeholder="Write your comments ...."
              type="email"
              fullWidth
            />
          </ModalBody>
          <ModalFooter>
            <Button autoFocus onClick={handleClose} color="primary" outline>
              CLOSE
            </Button>
            <Button autoFocus onClick={handleUpdateStatus} color="primary">
              UPDATE
            </Button>
          </ModalFooter>
        </Modal>
      </Fragment>
    </Card>
  )
}
