import React from 'react'
const initialState = {
  data: [],
  jd_id: '',
  error: false,
  isLoading: false,
  redirect: false,
  path: false,
}

const CreateTaskReducer = (state = initialState, action) => {
  let index = ''
  switch (action.type) {
    case 'CREATE_TASK_ADDED':
      return {
        ...state,
        data: action.data,
        jd_id: action.jd_id,
        error: false,
        isLoading: false,
        redirect: false,
        path: false,
      }
    case 'CREATE_TASK_INIT':
      return {
        ...state,
        data: [],
        jd_id: '',
        error: false,
        isLoading: false,
        redirect: false,
        path: false,
      }
    case 'CREATE_TASK_ERROR':
      return {
        ...state,
        error: action.error,
      }

    case 'CREATE_TASK_REDIRECT':
      return {
        ...state,
        error: action.error,
        isLoading: action.isLoading,
      }
    default:
      return state
  }
}

export default CreateTaskReducer
