import React from 'react'
const initialState = {
  task_data: [],
  details_data: [],
  credits: 0,
  jd_id: '',
  task_id: '',
  error: false,
  isLoading: false,
  redirect: false,
  path: false,
}

const OpenTaskReducer = (state = initialState, action) => {
  let index = ''
  switch (action.type) {
    case 'OPEN_TASK_ADDED':
      return {
        ...state,
        task_data: action.task_data,
        credits: action.credits,
        error: false,
        isLoading: false,
        redirect: false,
        path: false,
      }
    case 'OPEN_TASK_DETAILS_ADDED':
      return {
        ...state,
        details_data: action.details_data,
        jd_id: action.jd_id,
        task_id: action.task_id,
        error: false,
        isLoading: false,
        redirect: false,
        path: false,
      }
    case 'OPEN_TASK_INIT':
      return {
        ...state,
        task_data: [],
        details_data: [],
        credits: 0,
        jd_id: '',
        task_id: '',
        error: false,
        isLoading: false,
        redirect: false,
        path: false,
      }
    case 'OPEN_TASK_ERROR':
      return {
        ...state,
        error: action.error,
      }

    case 'OPEN_TASK_REDIRECT':
      return {
        ...state,
        error: action.error,
        isLoading: action.isLoading,
      }
    default:
      return state
  }
}

export default OpenTaskReducer
