import { createTheme } from '@mui/material/styles'
import { amber, grey } from '@mui/material/colors'

const getColors = (mode) => ({
  palette: {
    mode,
    secondary: {
      ...amber,
      ...(mode === 'dark' && {
        main: amber[300],
      }),
    },
    ...(mode === 'dark' && {
      background: {
        default: '#161d31', //283046
        paper: '#161d31',
      },
    }),
    text: {
      ...(mode === 'light'
        ? {
            primary: grey[900],
            secondary: grey[800],
          }
        : {
            primary: '#fff',
            secondary: grey[500],
          }),
    },
  },
})

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    main: amber[300],
    secondary: amber,
    background: {
      default: '#161d31', //283046
      paper: '#161d31',
    },
    text: {
      primary: '#fff',
      secondary: grey[500],
    },
  },
})

const darkThemeMT = createTheme({
  palette: {
    type: 'dark',
    main: amber[300],
    secondary: amber,
    background: {
      default: '#161d31', //283046
      paper: '#161d31',
    },
    text: {
      primary: '#fff',
      secondary: grey[500],
    },
  },
})

const lightTheme = createTheme({
  palette: {
    mode: 'light',
  },
})

const lightThemeMT = createTheme({
  palette: {
    type: 'light',
  },
})

export function getMuiTheme(store, type) {
  if (type === 'mt') {
    if (store.skin === 'dark') {
      return darkThemeMT
    } else {
      return lightThemeMT
    }
  }

  if (type === '') {
    if (store.skin === 'dark') {
      return darkTheme
    } else {
      return lightTheme
    }
  }
}
