import React from 'react'
import { Paper } from '@mui/material'
import {
  ArgumentAxis,
  BarSeries,
  Chart,
  Title,
  ValueAxis,
} from '@devexpress/dx-react-chart-material-ui'
import { Animation } from '@devexpress/dx-react-chart'
import withStyles from '@mui/styles/withStyles'
import { styles } from '../../jd/incomplete/Helper'
import { withRouter } from 'react-router-dom'
import { connect, useSelector } from 'react-redux'
import * as jdcvmatchapi from '@src/api/JdCVMatch'

import { getMuiTheme } from '@src/utility/utils/Themes'
import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'

function ProfileAnalysis(props) {
  const [state, setState] = React.useState({
    columns: [
      { title: 'Start Date', field: 'start_date' },
      { title: 'End Date', field: 'end_date' },
      { title: 'Duration', field: 'duration' },
      { title: 'Company', field: 'company' },
      { title: 'Designation', field: 'designation' },
    ],
    data: [],
    matching: [],
  })

  React.useEffect(() => {
    let token = props.token
    let cvID = props.id
    console.log('token', token, cvID)
    jdcvmatchapi
      .cvanalysis(token, cvID)
      //.then(response => response.text())
      .then((result) => {
        result = result.data.Data
        let data = []
        for (let i = 0; i < result.length; i++) {
          let res = result[i]
          data.push({
            //start_date: res.start_date,
            //end_date: res.end_date,
            duration: Number(res.duration),
            company:
              res.start_date + ' - ' + res.end_date + ' {' + res.company + '} ',
            //designation: res.designation
          })
        }
        setState((prevState) => ({
          ...prevState,
          data: data.reverse(),
        }))
      })
      .catch((error) => console.log('error', error))
  }, [props.jdID])

  console.log('CV Data', state.data)

  const store = useSelector((state) => state.layout)
  const darkModeTheme = getMuiTheme(store, '')

  return (
    <ThemeProvider theme={darkModeTheme}>
      <CssBaseline />
      <div>
        <div>
          <Paper>
            {state.data.length !== 0 ? (
              <Chart data={state.data}>
                <ArgumentAxis
                  tickComponent={({ x, y, payload }) => (
                    <g transform={`translate(${x},${y}) rotate(45)`}>
                      <text x={0} y={0} dy={16} textAnchor="end" fill="#666">
                        {payload}
                      </text>
                    </g>
                  )}
                />
                <ValueAxis max={7} />
                <BarSeries valueField="duration" argumentField="company" />
                <Title text="Job Changes" />
                <Animation />
              </Chart>
            ) : (
              <div className="px-10 py-10">No work analytics found</div>
            )}
          </Paper>
        </div>
      </div>
    </ThemeProvider>
  )
}

const mapStateToProps = (state) => ({
  token: state.LoginReducers.token,
  uuid: state.LoginReducers.uuid,
})

const mapDispatchToProps = (dispatch) => ({})

export default withStyles(styles, { withTheme: true })(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(ProfileAnalysis)),
)
