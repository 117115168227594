import instance from './API'

export function getJdHistory(
  token,
  userID,
  start,
  end,
  start_col,
  start_val,
  user_filter,
  isCompleteJD = 0,
) {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
    params: {
      uuid: userID,
      offset: start,
      limit: end,
      start_col,
      start_val,
      isCompleteJD,
      user_filter,
    },
  }
  return instance.get('jds/history/', config)
}

// Get Incomplete Jds
export function getJdHistoryInComplete(
  token,
  start,
  end,
  contractType,
  fulltimeType,
  zone,
  startDate,
  endDate,
) {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
    params: {
      offset: start,
      limit: end,
      jd_type_contract: contractType,
      jd_type_fulltime: fulltimeType,
      zone,
      startDate,
      endDate,
    },
  }
  return instance.get('jds/history/incomplete/', config)
}

// Get Completed Jds
export function getJdHistoryCompleted(
  token,
  userID,
  start,
  end,
  contractType,
  fulltimeType,
  zone,
  startDate,
  endDate,
) {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
    params: {
      uuid: userID,
      offset: start,
      limit: end,
      jd_type_contract: contractType,
      jd_type_fulltime: fulltimeType,
      zone,
      startDate,
      endDate,
    },
  }
  return instance.get('jds/history/complete/', config)
}

//get All Jds
export function getJdsForTask() {
  return instance.get('extension/jds/', {})
}

//get JD Task Summary
export function getJdTasks(token, userID) {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
    params: {
      id: userID,
      offset: 0,
      limit: 100,
    },
  }
  return instance.get('jds/tasks/', config)
}

//Update JD Task Summary
export function updateJDTask(token, taskId, accountType) {
  const formData = new FormData()
  formData.append('taskID', taskId)
  formData.append('account_type', accountType)

  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
    /*params: {
            taskID: taskId,
            account_type: accountType
        }*/
  }
  return instance.put('jds/tasks/', formData, config)
}

//get JD Task Details
export function getJdTaskDetails(token, taskId, start, end) {
  console.log('TASK ID ', taskId)
  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
    params: {
      jd_id: taskId,
      offset: start,
      limit: end,
    },
  }
  return instance.get('jds/tasks/details/', config)
}

export function getAccountType(accType, jdID, start, end) {
  console.log('TASK ID ', jdID, accType)
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
    params: {
      jd_id: jdID,
      account_type: accType,
      offset: start,
      limit: end,
    },
  }
  return instance.get('jds/tasks/details', config)
}

//delete jdTaskDetails
export function deleteJDTaskDetails(token, id) {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
    data: {
      id,
    },
  }

  return instance.delete('jds/tasks/details/', config)
}

//get JD for selected Jdid
export function getJdChoosenTasks(
  token,
  jdId,
  offset,
  limit,
  sort_clause = 'n',
  sort_value = '',
  where_clause = '',
  where_value = '',
  start_col = '',
  start_val = '',
) {
  if (jdId === undefined) {
    return
  }
  const formData = new FormData()

  formData.append('jdID', jdId)
  formData.append('offset', offset)
  formData.append('limit', limit)
  formData.append('start_col', start_col)
  formData.append('start_val', start_val)
  formData.append('sort_clause', sort_clause)
  formData.append('sort_value', sort_value)
  formData.append('where_clause', where_clause)
  formData.append('where_value', where_value)

  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
  }
  return instance.post('jds/choosenTasks/', formData, config)
}

export function getWAChooseTasks(
  token,
  jdId,
  offset,
  limit,
  sort_clause = 'n',
  sort_value = '',
  where_clause = '',
  where_value = '',
  start_col = '',
  start_val = '',
) {
  if (jdId === undefined) {
    return
  }
  const formData = new FormData()

  formData.append('jdID', jdId)
  formData.append('offset', offset)
  formData.append('limit', limit)
  formData.append('start_col', start_col)
  formData.append('start_val', start_val)
  formData.append('sort_clause', sort_clause)
  formData.append('sort_value', sort_value)
  formData.append('where_clause', where_clause)
  formData.append('where_value', where_value)

  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
  }
  return instance.post('jds/waChats/', formData, config)
}

// Save JdTasks and JdTaskDetails
export function saveJdTasks(
  token,
  jdId,
  taskName,
  accountType,
  totalProfiles,
  taskDetails,
  tags,
  template_id,
  subject,
) {
  const formData = new FormData()
  formData.append('jdID', jdId)
  formData.append('name', taskName)
  formData.append('accountType', accountType)
  formData.append('totalProfiles', totalProfiles)
  formData.append('taskDetails', JSON.stringify(taskDetails))
  formData.append('subject', subject)
  formData.append('template_id', template_id)
  formData.append('tags', tags)

  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
  }
  return instance.post('jds/tasks/', formData, config)
}

// Add New Jds
export function addNewJd(token, data, jdid) {
  const formData = new FormData()
  formData.append('data', JSON.stringify(data))
  formData.append('jdID', jdid)

  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
  }
  console.log('FORM DATA ', JSON.stringify(data))
  console.log('FORM DATA1 ', formData)
  return instance.post('jds/tasks/newJd/', formData, config)
}

// Duplicate JdTasks
export function duplicateJdTasks(
  token,
  taskId,
  taskName,
  accountType,
  subject,
  template_id,
  tags,
) {
  const formData = new FormData()
  formData.append('taskID', taskId)
  formData.append('name', taskName)
  formData.append('account_type', accountType)
  formData.append('subject', subject)
  formData.append('template_id', template_id)
  formData.append('tags', tags)

  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
  }
  return instance.put('jds/tasks/', formData, config)
}

export function getJdAnalytics(token, userID) {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
    params: {
      userID,
    },
  }

  return instance.get('jds/analytics/', config)
}

export function deleteJD(token, jdId) {
  const formData = new FormData()
  formData.append('jdID', jdId)

  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
  }

  return instance.post('jd/delete/', formData, config)
}

export function deleteJDTask(token, taskID) {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
    data: {
      taskID,
    },
  }

  return instance.delete('jds/tasks/', config)
}

// NOTIFCATION STARTS
export function getNotification(token, userID) {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
    params: {
      uuid: userID,
    },
  }

  return instance.get('notifications/', config)
}

export function markNotificationComplete(token, userID, nocID) {
  const formData = new FormData()
  formData.append('nocID', nocID)
  formData.append('uuid', userID)

  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
  }

  return instance.post('notifications/', formData, config)
}

export function postNotification(token, from_user, to_user, message) {
  const formData = new FormData()
  formData.append('from_user', from_user)
  formData.append('to_user', to_user)
  formData.append('message', message)

  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
  }

  return instance.put('notifications/', formData, config)
}

export function deleteNotification(token, userID, is_clear_all, nocID) {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
    data: {
      uuid: userID,
      is_clear_all,
      nocID,
    },
  }

  return instance.delete('notifications/', config)
}

// Notification Ends

export function getTargetData(token, userID, date) {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
    params: {
      to_userID: userID,
      date,
    },
  }

  return instance.get('user/target/', config)
}

export function postTargetData(
  token,
  from_user,
  to_user,
  target_value,
  question,
) {
  const formData = new FormData()
  formData.append('from_user', from_user)
  formData.append('to_user', to_user)
  formData.append('question', question)
  formData.append('target_value', target_value)

  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
  }

  return instance.put('user/target/', formData, config)
}

export function getEmployeeData(token, emp_ids) {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
    params: {
      emp_ids,
    },
  }

  return instance.get('user/team/allmembers/', config)
}

export function getLinkedinGroupPost(jdID) {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
    params: {
      jdID,
    },
  }

  return instance.get('jd/post/linkedin/', config)
}

export function getBasicValidations(jd_id, cid) {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      // Authorization: `Token ${token}`,
    },
    params: {
      jd_id: jd_id,
      cid: cid,
    },
  }

  return instance.get('extension/linkedin/v3/addtional_details/', config)
}
