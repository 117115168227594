import { Paper, Tab, Tabs } from '@mui/material'
import React from 'react'
import Box from '@mui/material/Box'
import PropTypes from 'prop-types'
import CandidateProfileTab from './profile/CandidateProfileTab'
import ProfileAnalysis from './analysis/ProfileAnalysis'
import CommentsTabs from './comments/CommentsTabs'
import InterviewTab from './InterviewTabs/InterviewTab'

import { useSelector } from 'react-redux'
import { getMuiTheme } from '@src/utility/utils/Themes'
import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'

function TabPanel(props) {
  const { children, value, index, ...other } = props
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  }
}

export default function CandidateDetailsTabsContainer(props) {
  const [value, setValue] = React.useState(0)

  const handleTabValueChange = (event, newValue) => {
    setValue(newValue)
  }

  const store = useSelector((state) => state.layout)
  const darkModeTheme = getMuiTheme(store, '') //mt is material table

  return (
    <ThemeProvider theme={darkModeTheme}>
      <CssBaseline />
      <div>
        <Paper square>
          <Tabs
            value={value}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons
            onChange={handleTabValueChange}
            aria-label="disabled tabs example"
            allowScrollButtonsMobile
          >
            <Tab label="Candidate Profile" {...a11yProps(0)} />
            <Tab label="Analysis" {...a11yProps(1)} />
            {/* <Tab label="Interviews" {...a11yProps(2)}/> */}
            <Tab label="Comments" {...a11yProps(2)} />
          </Tabs>
        </Paper>
        <TabPanel value={value} index={0}>
          <CandidateProfileTab {...props} data={props.data} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <ProfileAnalysis {...props} />
        </TabPanel>

        {/* <TabPanel value={value} index={2}>
                <InterviewTab {...props}/>
            </TabPanel> */}

        <TabPanel value={value} index={2}>
          <CommentsTabs {...props} />
        </TabPanel>
      </div>
    </ThemeProvider>
  )
}
