import instance from './API'

export function postJdStatusUpdate(token, status, jdID) {
  const formData = new FormData()
  formData.append('jdID', jdID)
  formData.append('status', status)

  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
  }

  return instance.post('jd/status/', formData, config)
}

export function postJdReassignToUpdate(
  token,
  reassign_to,
  jdID,
  old_user_id,
  is_unassigned,
) {
  const formData = new FormData()
  formData.append('jdID', jdID)
  formData.append('reassign_to_id', reassign_to)
  formData.append('old_user_id', old_user_id)
  formData.append('is_unassigned', is_unassigned)

  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
  }

  return instance.post('jd/reassign/', formData, config)
}

export function getLiveScarping(token) {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
    params: {
      offset: 0,
      limit: 100,
    },
  }

  return instance.get('jds/scraping/live/', config)
}

export function updateLiveScraping(token, jdID) {
  const formData = new FormData()
  formData.append('jdID', jdID)

  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
  }

  return instance.post('jds/scraping/live/', formData, config)
}

export function getJDExcelData(jdID) {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
    params: {
      jdID,
    },
  }

  return instance.get('jd/excel/', config)
}

export function getExcelCache(jdID) {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
    params: {
      jd_id: jdID,
    },
  }

  return instance.get('excel/data/', config)
}

export function postExcelCache(jdID, data) {
  const formData = new FormData()
  formData.append('jd_id', jdID)
  formData.append('data', data)

  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  }
  console.log('syncData api call happened')

  return instance.post('excel/data/', formData, config)
}

export function postExcelUpdate(data) {
  const formData = new FormData()
  formData.append('data', data)

  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  }
  console.log('syncData api call happened')

  return instance.post('excel/update/', formData, config)
}

export function getJDEmbedding(jdID) {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
    params: {
      jd_id: jdID,
    },
  }

  return instance.get('jd/embedding/', config)
}
