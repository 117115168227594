import React, { useState, Fragment, useEffect } from 'react'
import * as whatsAppAPI from '@src/api/ContactApi'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import {
  Button,
  Card,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Row,
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from 'reactstrap'
import Grid from '@mui/material/Grid'
import Select from 'react-select'
import { convert } from 'html-to-text'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import {
  getEmailTemplate,
  getJourneyContactType,
} from '../../../../api/SettingsApi'
function SendWhatsAppDialog({ isOpen, data, isClose, getTemplateContent }) {
  const tokens = useSelector((state) => state.LoginReducers)
  const [useAuto, setUseAuto] = useState(true)
  const [connectMsg, setConnectMsg] = useState('')
  const [customMsg, setCustomMsg] = useState('')
  const [accToggleOpen, setAccToggleOpen] = useState('1')
  const [emailTags, setEmailTags] = useState({})

  const [listOfTemplates, setListOfTemplates] = useState([])
  const [listOfJourney, setListOfJourney] = useState([])
  const [selectedCand_status, setSelectedCand_status] = useState({
    value: '-1',
    label: 'Select Status',
  })
  const [selectedTemplate, setSelectedTemplate] = useState({
    value: '-1',
    label: 'Select Template',
  })

  console.log('SendWhatsAppDialog', data)

  // fetch all templates
  useEffect(() => {
    getEmailTemplate().then((res) => {
      console.log('sendWhatsAppDialog_', res.data.Data)
      setListOfTemplates(res.data.Data)
    })

    getJourneyContactType().then((res) => {
      const defaultValue = res.data.Data?.Journey?.filter(
        (data) => data?.value === 'NOT CONTACTED',
      )
      console.log('sendWhatsAppDialog_', res.data.Data?.Journey, defaultValue)
      setListOfJourney(res?.data?.Data?.Journey)
    })

    console.log('sendWhatsAppDialog_', isOpen, data, isClose)
  }, [])

  const htmlOptions = {
    wordwrap: 130,
  }

  const accToggle = (id) => {
    if (accToggleOpen === id) {
      setAccToggleOpen()
    } else {
      setAccToggleOpen(id)
    }
  }

  const handleConnectMsg = (e) => {
    console.log('sendWhatsAppDialog_', e)
    setConnectMsg(convert(e.target.value, htmlOptions))
  }

  const handleCustomoMsg = (e) => {
    setCustomMsg(convert(e.target.value, htmlOptions))
  }

  function resetFields() {
    console.log('sendWhatsAppDialog_')
    setSelectedCand_status({
      value: '-1',
      label: 'Select Status',
    })
    setSelectedTemplate({
      value: '-1',
      label: 'Select Template',
    })
  }

  const toggle = () => {
    resetFields()
    isClose()
  }
  const handleWhatAppSend = (e) => {
    const message_id = 0

    console.log('sendWhatsAppDialog_', setSelectedCand_status, selectedTemplate)
    if (selectedCand_status?.value === '-1') {
      toast.warn('Select Status')
      return
    } else if (selectedTemplate?.value == '-1') {
      toast.warn('Select template')
      return
    }

    if (!data?.c_id && !data?.jd_id) {
      return
    }

    whatsAppAPI
      .sendWhatsapp(
        tokens,
        data?.c_id,
        data?.jd_id,
        connectMsg ?? customMsg,
        selectedTemplate?.value,
        selectedCand_status?.value,
        message_id,
      )
      .then((res) => {
        toast.success(res.data.Data)
        toggle()
        setConnectMsg('')
      })
      .catch((e) => {
        console.log('sendWhatsapp', e)
        toast.error(
          'Unable to send WhatsApp messages. Please try again.',
          e.message,
        )
        toggle()
      })
    //when done lets close dialog
  }

  const handleTagsChange = (e) => {
    setEmailTags({
      ...emailTags,
      [e.currentTarget.name]: e.currentTarget.value,
    })
  }

  const handleChangeJourney = (e) => {
    setSelectedCand_status(e)
  }

  const handleChangeTemplate = (e) => {
    setSelectedTemplate(e)
    const contentMsg = listOfTemplates?.filter((data) => data?.id === e?.value)
    console.log('handleChangeTemplate', contentMsg, e?.value)
    setConnectMsg(convert(contentMsg[0]?.content, htmlOptions))
    getTemplateContent(convert(contentMsg[0]?.content, htmlOptions))
  }

  return (
    <div>
      <Card>
        <Fragment>
          <Modal
            isOpen={isOpen}
            toggle={toggle}
            className="modal-dialog-centered modal-dialog modal-lg"
          >
            <ModalHeader className="bg-transparent" toggle={toggle}>
              {console.log('Send message via whatsapp', data)}
              {data?.name ? (
                <h4 className="address-title text-left mb-1">
                  Send message via whatsapp to{' '}
                  <span className="text-primary">{data?.name}</span>
                </h4>
              ) : (
                <h4 className="address-title text-left mb-1">
                  Send message via whatsapp
                </h4>
              )}
              {/* <h4 className="address-title text-left mb-1">
                Send whatsapp to {data?.name}
              </h4> */}
            </ModalHeader>
            <ModalBody className="5">
              <div className="row mb-2">
                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                  <label htmlFor="whatsappChooseStatus">
                    Select Status <span className="text-danger">*</span>
                  </label>
                  <Select
                    id="whatsappChooseStatus"
                    value={selectedCand_status}
                    onChange={handleChangeJourney}
                    options={listOfJourney.map((e) => {
                      return { value: e.id, label: e.value }
                    })}
                  />
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                  <label htmlFor="whatsappChooseStatus">
                    {' '}
                    Select Template{' '}
                  </label>
                  <span className="text-danger">*</span>
                  <Select
                    labelId="demo-select-small"
                    id="demo-select-small"
                    defaultValue={'-'}
                    value={selectedTemplate}
                    options={[
                      {
                        value: '-1',
                        label: 'None',
                      },
                      ...listOfTemplates
                        ?.filter(
                          (e) => e?.template_type?.toLowerCase() === 'whatsapp',
                        )
                        .map((e) => ({ value: e?.id, label: e?.name })),
                    ]}
                    onChange={handleChangeTemplate}
                  ></Select>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                  {selectedTemplate.value === '-1' ? (
                    <div className="my-1">
                      <label htmlFor="whatsappChooseStatus">
                        Message <span className="text-danger">*</span>
                      </label>
                      <Input
                        type="textarea"
                        id={'emailMessage'}
                        value={customMsg}
                        placeholder="Hi {NAME}"
                        rows="4"
                        onChange={handleCustomoMsg}
                        variant="outlined"
                        label={'Connect Message'}
                        name={'subject'}
                        size="small"
                      />
                    </div>
                  ) : (
                    <div className="row">
                      <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                        <div className="my-1">
                          <Input
                            type="textarea"
                            id={'emailMessage'}
                            value={connectMsg}
                            rows="8"
                            onChange={handleConnectMsg}
                            variant="outlined"
                            label={'Connect Message'}
                            name={'subject'}
                            disabled={true}
                            size="small"
                          />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                        <Grid item>
                          <FormControlLabel
                            control={
                              <Checkbox
                                defaultChecked={useAuto}
                                value={useAuto}
                                onChange={(e) => setUseAuto(e.target.checked)}
                              />
                            }
                            label="Auto generate"
                          />
                        </Grid>
                        <Grid item>
                          <Accordion open={accToggleOpen} toggle={accToggle}>
                            <AccordionItem>
                              <AccordionHeader targetId="1">
                                Edit Tags
                              </AccordionHeader>
                              <AccordionBody>
                                {listOfTemplates
                                  ?.filter(
                                    (f) => f.id === selectedTemplate?.value,
                                  )
                                  ?.map((tp) => {
                                    console.log('@george_whatsapp', tp)

                                    return tp?.values.values?.map((e) => {
                                      if (
                                        e.name.toLowerCase().includes('form') ||
                                        e.name.toLowerCase().includes('jd_link')
                                      ) {
                                        return <div />
                                      }
                                      return (
                                        <div className="my-1">
                                          <label htmlFor="floatingInput">
                                            {e.description}
                                          </label>
                                          <Input
                                            type="text"
                                            id={'emailMessage' + e.name}
                                            value={emailTags[e.name]}
                                            onChange={handleTagsChange}
                                            variant="outlined"
                                            label={e.description}
                                            name={e.name}
                                            disabled={useAuto}
                                            size="small"
                                          />
                                        </div>
                                      )
                                    })
                                  })}
                                {listOfTemplates
                                  ?.filter(
                                    (f) => f.id === selectedTemplate.value,
                                  )
                                  ?.map((tp) => {
                                    console.log('Values', tp)
                                    return tp?.values.values?.map((e) => {
                                      if (
                                        e.name.toLowerCase().includes('form')
                                      ) {
                                        return (
                                          <div>
                                            **
                                            {e.name +
                                              ' : ' +
                                              'will be auto generated from backend'}
                                          </div>
                                        )
                                      } else if (
                                        e.name.toLowerCase().includes('jd_link')
                                      ) {
                                        return (
                                          <div color="secondary">
                                            **
                                            {e.name +
                                              ' : ' +
                                              'will be auto generated from backend'}
                                          </div>
                                        )
                                      }
                                      return <div></div>
                                    })
                                  })}
                              </AccordionBody>
                            </AccordionItem>
                          </Accordion>
                        </Grid>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                type="reset"
                className="me-1"
                color="secondary"
                outline
                onClick={toggle}
              >
                Discard
              </Button>
              <Button className="" color="primary" onClick={handleWhatAppSend}>
                Send
              </Button>
            </ModalFooter>
          </Modal>
        </Fragment>
      </Card>
    </div>
  )
}

export default SendWhatsAppDialog
