import React from 'react'
import Box from '@mui/material/Box'
import PropTypes from 'prop-types'
import { Tab } from '@mui/material'
import Tabs from '@mui/material/Tabs'
import ProfileTab from './profileTab'
import ResumeTab from './ResumeProfileTab'
import { getLinkedinStatus } from '../infoCard/InfoHelper'
import LinkedinCVProfileTab from './LinkedinCVProfileTab'

function getCvLink(data) {
  var cv_link = ''

  // data.map((e) => {
  //     var mis = e?.miscellaneous
  //     mis.map((ee) => {
  //             var obj = JSON.parse(ee);
  //             if (obj['LINKEDIN_IMG_PROFILE'] !== "") {
  //                 cv_link = obj['LINKEDIN_IMG_PROFILE']
  //             }
  //         }
  //     )
  // })

  return cv_link
}

function TabPanel(props) {
  const { children, value, index, ...other } = props
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

export default function CandidateProfileTab(props) {
  const [value, setValue] = React.useState(0)

  var linkedinCV =
    'https://media-exp1.licdn.com/dms/image/C562DAQHeaY5gQSbiiQ/profile-treasury-document-images_480/1/1605784610868?e=1649869200&v=beta&t=K_ATUGKTnJ7UWRixID2myWE1xSM9gUWGfIgqPv0EE8Y'

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  console.log(
    'CARDSOURCE',
    getLinkedinStatus(props.data)?.toUpperCase().includes('LINKEDIN'),
  )

  return (
    <div>
      <div>
        {getLinkedinStatus(props.data)?.toUpperCase().includes('LINKEDIN') &&
        getCvLink(props.data) !== '' ? (
          <Tabs
            value={value}
            indicatorColor="secondary"
            textColor="secondary"
            onChange={handleChange}
            aria-label="disabled tabs example"
          >
            <Tab label="Profile" />
            <Tab label="Resume" />
            <Tab label="LinkedinProfile" />
          </Tabs>
        ) : (
          <Tabs
            value={value}
            indicatorColor="secondary"
            textColor="secondary"
            onChange={handleChange}
            aria-label="disabled tabs example"
          >
            <Tab label="Profile" />
            <Tab label="Resume" />
          </Tabs>
        )}
      </div>

      {getLinkedinStatus(props.data)?.toUpperCase().includes('LINKEDIN') &&
      getCvLink(props.data) !== '' ? (
        <div>
          <TabPanel value={value} index={0}>
            <ProfileTab {...props} ocr={props.data} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <ResumeTab {...props} />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <LinkedinCVProfileTab {...props} linkedinCV={linkedinCV} />
          </TabPanel>
        </div>
      ) : (
        <div>
          <TabPanel value={value} index={0}>
            <ProfileTab {...props} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <ResumeTab {...props} />
          </TabPanel>
        </div>
      )}
    </div>
  )
}
