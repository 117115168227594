import MaterialTable from 'material-table'
import React from 'react'
import * as jdcvmatchapi from '@src/api/JdCVMatch'
import { tableHeaderStyle, tableIcons } from '@src/views/Settings/helper'
import makeStyles from '@mui/styles/makeStyles'
import withStyles from '@mui/styles/withStyles'
import { styles } from '@src/views/jd/incomplete/Helper'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { Alert } from '@mui/material'
import InteractionTypeCard from './helper/IntractionTypeCard'
import { getReadableDateTime } from '@src/common/DateHelper'
import { useSelector } from 'react-redux'
import { getMuiTheme } from '@src/utility/utils/Themes'
import { ThemeProvider } from '@material-ui/core/styles'
import { toast } from 'react-toastify'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    //marginLeft: theme.spacing(1),
    //marginRight: theme.spacing(1),
    width: 200,
  },
}))

function InterviewTab(props) {
  const classes = useStyles()

  const [state, setState] = React.useState({
    data: [],
    actions: {},
    journey: {},
  })

  let theme = props.theme

  console.log('Interactionhistory props', props)

  // const communication_mode_lookup = {1: 'Email', 2: 'Phone Call', 3: 'SMS', 4: 'Email/SMS'}
  // const inverted_communication_mode_lookup = {'Email': 1, 'Phone Call': 2, 'SMS': 3, 'Email/SMS': 4}
  // const status_lookup = {1: 'Not Contacted', 2: 'Contacted', 3: '1st Round', 4: 'Selected', 5: 'Rejected'}
  // const inverted_status_lookup = {'Not Contacted': 1, 'Contacted': 2, '1st Round': 3, 'Selected': 4, 'Rejected': 5}

  const columns = [
    {
      title: 'Date',
      field: 'updated_at',
      type: 'datetime',
      width: '5%',
      render: (row) => (
        <div className="mx-2 my-2">
          <h6>{getReadableDateTime(row.updated_at)}</h6>
        </div>
      ),
    },
    {
      title: 'Jd',
      field: 'jd_name',
      type: 'string',
      default: 'hello',
      editable: 'never',
      render: (row) => (
        <div className="mx-2 my-2">
          <h6>{row.jd_name}</h6>
        </div>
      ),
    },
    {
      title: 'Email',
      field: 'email',
      type: 'string',
      editable: 'never',
      render: (row) => <div className="mx-2 my-2">{row.email}</div>,
    },
    {
      title: 'Phone Num',
      field: 'phone',
      type: 'string',
      editable: 'never',
      render: (row) => <div className="mx-2 my-2">{row.phone}</div>,
    },
    {
      title: 'Previous Status',
      field: 'old_candidate_status',
      lookup: state.journey,
      render: (row) => (
        <div className="mx-2 my-2">
          {state.journey?.[row.old_cand_status] ?? ''}
        </div>
      ),
    },
    {
      title: 'New Status',
      field: 'new_candidate_status',
      lookup: state.journey,
      render: (row) => (
        <div className="mx-2 my-2">
          {state.journey?.[row.new_cand_status] ?? ''}
        </div>
      ),
    },
    {
      title: 'Comments',
      field: 'comments',
      render: (row) => <div className="mx-2 my-2">{row.comments}</div>,
    },
    {
      title: 'Communication Mode',
      field: 'action_name',
      lookup: state.actions,
      render: (row) => (
        <div className="mx-2 my-2">{state.actions?.[row.action] ?? ''}</div>
      ),
    },
  ]

  const [open, setOpen] = React.useState(false)
  const [message, setMessage] = React.useState('')

  React.useEffect(() => {
    let token = props.token
    let cvID = props.id
    console.log('token', token, cvID)
    jdcvmatchapi
      .cvinteract(token, cvID)
      //.then(response => response.text())
      .then((result) => {
        var data = result.data.Data.summary

        data.sort(function (a, b) {
          return b.updated_at.localeCompare(a.updated_at)
        })

        var journey = {}
        result.data.Data.journey.map((e) => {
          journey[e.id] = e.value
        })

        var actions = {}
        result.data.Data.actions.map((e) => {
          actions[e.id] = e.action_name
        })

        setState((prevState) => ({
          ...prevState,
          data: data,
          actions: actions,
          journey: journey,
        }))
      })
      .catch((error) => console.log('error', error))
  }, [props.jdID])

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpen(false)
  }

  let link =
    'https://hire10xapi.10xscale.in/api/user/ask/?id=' +
    props.cvID +
    '&jd=' +
    props.jdID

  console.log('InterviewData', state)

  const store = useSelector((state) => state.layout)
  const darkModeTheme = getMuiTheme(store, 'mt') //mt is material table

  return (
    <ThemeProvider theme={darkModeTheme}>
      <div>
        {/* <InteractionTypeCard theme={theme} props={props}/> */}

        <div className="my-10" />

        <MaterialTable
          icons={tableIcons}
          title="Interactions with Candidate"
          columns={columns}
          data={state.data}
          options={{
            headerStyle: tableHeaderStyle(theme),
          }}
          editable={{
            onRowAdd: (newData) =>
              new Promise((resolve) => {
                setTimeout(() => {
                  console.log('ONADD', newData)

                  let token = props.token
                  let cvID = props.id
                  let jdID = props.jdID
                  let action = newData.action_name
                  let oldStatus = newData.old_candidate_status
                  let newStatus = newData.new_candidate_status

                  let comments = newData?.comments ?? ''
                  let date = newData.updated_at.getTime()
                  console.log(
                    'props ',
                    props,
                    newData,
                    oldStatus,
                    newStatus,
                    comments,
                    date,
                  )
                  // cvID, jdID, action, oldStatus, newStatus, comments
                  jdcvmatchapi
                    .postCvInteract(
                      token,
                      cvID,
                      jdID,
                      action,
                      oldStatus,
                      newStatus,
                      comments,
                      date,
                    )
                    //.then(response => response.text())
                    .then((result) => {
                      console.log('result ', result)
                      resolve()
                      setState((prevState) => {
                        const data = [...prevState.data]
                        data.push(newData)
                        return { ...prevState, data }
                      })
                    })
                    .catch((error) => console.log('error', error))
                }, 600)
              }),
            onRowDelete: (oldData) =>
              new Promise((resolve) => {
                setTimeout(() => {
                  resolve()
                  setState((prevState) => {
                    const data = [...prevState.data]
                    data.splice(data.indexOf(oldData), 1)
                    return { ...prevState, data }
                  })
                  console.log(oldData)
                  let token = props.token
                  let id = oldData.id
                  // cvID, jdID, action, oldStatus, newStatus, comments
                  jdcvmatchapi
                    .deleteCvInteract(token, id)
                    //.then(response => response.text())
                    .then((result) => {
                      console.log('result ', result)
                    })
                    .catch((error) => console.log('error', error))
                }, 600)
              }),
          }}
        />
      </div>
    </ThemeProvider>
  )
}

const mapStateToProps = (state) => ({
  token: state.LoginReducers.token,
  uuid: state.LoginReducers.uuid,
  jdRole: 'dataScience',
  candidateEmail: 'hello@example.com',
  organizerEmail: state.LoginReducers.email,
  organizerName: state.LoginReducers.fullname,
})

const mapDispatchToProps = (dispatch) => ({})

export default withStyles(styles, { withTheme: true })(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(InterviewTab)),
)
