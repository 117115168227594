import { setUserData } from './UserActions'
//import history from "../../../history";
import * as api from '../../api/UserProfileApi'
import * as loginApi from '../../api/LoginApi'

import localStorageService from '../../services/localStorageService'
import { getProfileData } from './UserProfileActions'
//import {getObjectivesQuestionsInfo} from "../../../screens/EvaluateJD/targets/ObjectivesActions";
//import { initialNavigation } from '@store/navbar'
import * as email_api from '@src/api/ChatEmailApi'
import * as settingsApi from '@src/api/SettingsApi'

export const LOGIN_ERROR = 'LOGIN_ERROR'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_LOADING = 'LOGIN_LOADING'
export const RESET_PASSWORD = 'RESET_PASSWORD'

export function resetPassword({ email }) {
  return (dispatch) => {
    return api
      .sendResetEmail(email)
      .then((response) => {
        if (response.data.Response === 'Ok') {
          return { status: 1, message: response.data.Message }
        } else {
          return { status: 0, message: response.data.Message }
        }
      })
      .catch((error) => {
        let msg = error.response?.data.Message ?? 'Something went wrong'
        return { status: 0, message: msg }
      })
  }
}

export function verifyPassword(pin, password) {
  return (dispatch) => {
    return api
      .sendResetPassword(pin, password)
      .then((response) => {
        if (response.data.Response === 'Ok') {
          return { status: 1, message: response.data.Message }
        } else {
          return { status: 0, message: response.data.Message }
        }
      })
      .catch((error) => {
        let msg = error.response?.data.Message ?? 'Something went wrong'
        return { status: 0, message: msg }
      })
  }
}

export const setLinkedinId = (id) => ({
  type: 'SET_LINKEDIN_ID',
  linkedin_id: id,
})

export const SaveLoginDetails = (
  token,
  uuid,
  user_id,
  error,
  isAuth,
  fullname,
  role,
  emp_ids,
  emp_uuids,
  emp_names,
  emp_urls,
  company,
  is_logged,
  existing_password,
) => ({
  type: 'LOGIN_SAVE',
  token,
  uuid,
  user_id,
  error,
  isAuth,
  fullname,
  role,
  emp_ids,
  emp_uuids,
  emp_names,
  emp_urls,
  company,
  is_logged,
  existing_password,
})

export const UpdateError = (
  token,
  uuid,
  user_id,
  error,
  isAuth,
  fullname,
  role,
  emp_uuids,
  emp_names,
  emp_urls,
  company,
) => ({
  type: 'LOGIN_UPDATE_ERROR',
  token,
  uuid,
  user_id,
  error,
  isAuth,
  fullname,
  role,
  emp_uuids,
  emp_names,
  emp_urls,
  company,
})

export const UpdateEmpMappings = (emp_mappings, emp_names_mapping) => ({
  type: 'UPDATE_EMP_MAPPINGS',
  emp_mappings,
  emp_names_mapping,
})

export const UpdateSiblingsInfo = (
  sibling_ids,
  sibling_names,
  sibling_urls,
) => {
  return {
    type: 'UPDATE_SIBLINGS_INFO',
    sibling_ids,
    sibling_names,
    sibling_urls,
  }
}

export const updateJourneyData = (journeys, ids) => {
  return {
    type: 'UPDATE_JOURNEY_INFO',
    journeys,
    ids,
  }
}

export function LoginCall() {
  console.log('Inside Login Call')
  return function (dispatch) {
    return loginApi
      .getUserInfo()
      .then((response) => {
        console.log('Debug login resp', response)
        if (response.data.Response === 'Ok') {
          let token = response.data.Data.token
          let uuid = response.data.Data.uuid
          let role = response.data.Data.role
          let fullname = response.data.Data.fullname
          let user_id = response.data.Data.user_id
          let company = response.data.Data.company
          let is_logged = response.data.Data.is_logged
          let existing_password = response.data.Data.existing_password
          localStorage.setItem('user_id', user_id)

          dispatch(
            SaveLoginDetails(
              token,
              uuid,
              user_id,
              '',
              true,
              fullname,
              role,
              [],
              [],
              [],
              {},
              company,
              is_logged,
              existing_password,
            ),
          )

          let user = {
            id: user_id,
            role: role, //'admin',
            fullName: fullname,
            username: response.data.Data.username,
            email: response.data.Data.email,
            avatar: response.data.Data.image_url ?? '/assets/images/face-4.jpg',
            accessToken: token,
            is_logged: is_logged,
            existing_password: existing_password,
            ability: [
              {
                action: 'manage',
                subject: 'all',
              },
            ],
            extras: {
              eCommerceCartItemsCount: 0,
            },
            refreshToken: token,
          }

          dispatch(setUserData(user))
          localStorageService.setItem('userData', user)
          //token
          localStorage.setItem('jwt_token', token)
          //localStorage.setItem("accessToken", token)

          //fetch the email folders amd send them to LoginReducers
          console.log('getEmailFolder: ')
          // email_api.getEmailFolder().then( (res)  => {
          email_api.getEmailFolderPersonal().then((res) => {
            //console.log('getEmailFolder: ', res1)

            if (res.Response !== 'Ok') {
              return
            }
            let folders = res.data.Data

            //cehck for Inbox and Sent ... they should be at the top
            let inbox_folders = folders
              .filter((k) => {
                return k.name.toLowerCase().includes('inbox')
              })
              .map((k) => k.name)

            let sent_folders = folders
              .filter((k) => {
                return k.name.toLowerCase().includes('sent')
              })
              .map((k) => k.name)

            let draft_folders = folders
              .filter((k) => {
                return k.name.toLowerCase().includes('draft')
              })
              .map((k) => k.name)

            let trash_folders = folders
              .filter((k) => {
                return k.name.toLowerCase().includes('trash')
              })
              .map((k) => k.name)

            let remaining_folders = folders
              .filter((k) => {
                return !(
                  k.name.toLowerCase().includes('inbox') ||
                  k.name.toLowerCase().includes('sent') ||
                  k.name.toLowerCase().includes('draft') ||
                  k.name.toLowerCase().includes('trash')
                )
              })
              .map((k) => k.name)

            let final = inbox_folders.concat(
              sent_folders,
              draft_folders,
              trash_folders,
              remaining_folders,
            )

            console.log('getEmailFolder: ', final)
            dispatch({
              type: 'UPDATE_EMAIL_FOLDERS',
              folders: final,
            })
          })

          //
          loginApi
            .getEmployeeIds(
              response.data.Data.token,
              response.data.Data.uuid,
              response.data.Data.role,
            )
            .then((output) => {
              console.log('getEmailFolder:1 ', output)
              let emp_uuids = output.data.Data.map((k) => {
                return k.emp_uuid
              })

              let emp_ids = output.data.Data.map((k) => {
                return k.emp_id
              })

              let emp_names_dict = {}
              output.data.Data.map((k) => {
                emp_names_dict[k.emp_id] = k.emp_name
                return ''
              })

              let emp_urls_dict = {}
              output.data.Data.map((k) => {
                emp_urls_dict[k.emp_id] = k.emp_image_url
                return ''
              })

              dispatch(
                SaveLoginDetails(
                  token,
                  uuid,
                  user_id,
                  '',
                  true,
                  fullname,
                  role,
                  emp_ids,
                  emp_uuids,
                  emp_names_dict,
                  emp_urls_dict,
                  company,
                  is_logged,
                  existing_password,
                ),
              )
            })

          loginApi
            .getSiblingIds(response.data.Data.token, response.data.Data.uuid)
            .then((res) => {
              console.log('sibling output: ', res)
              let sibling_ids = res.data.Data.map((k) => {
                return k.user_id
              })

              let sibling_names = {}
              res.data.Data.map((k) => {
                sibling_names[k.user_id] = k.fullname
                return ''
              })

              let sibling_urls = {}
              res.data.Data.map((k) => {
                sibling_urls[k.user_id] = k.image_url
                return ''
              })
              console.log('output2: ', sibling_ids, sibling_names, sibling_urls)
              dispatch(
                UpdateSiblingsInfo(sibling_ids, sibling_names, sibling_urls),
              )
            })

          settingsApi
            .getJourneyContactType(response.data.Data.token)
            .then((res) => {
              let journeys = res.data.Data['Journey'].map((k) => k.value)
              console.log('res.data.Data: ', res.data.Data)
              let ids = res.data.Data['Journey'].map((k) => [k.id, k.value])
              journeys = ['All', 'Replied Back'].concat(journeys)

              let keys = res.data.Data['Journey'].map((k) => k.value)

              dispatch(updateJourneyData(journeys, ids))
              //console.log('new_pagination: ', new_pagination)
            })

          dispatch(getProfileData(token, uuid))
          //
          // //As of now pass the values directly
          //dispatch(getObjectivesQuestionsInfo(token, uuid))

          //set login success
          dispatch({
            type: LOGIN_SUCCESS,
          })

          return {
            status: true,
            data: user,
            message: 'Login Success',
          }
        } else {
          dispatch(
            UpdateError(
              '',
              '',
              '',
              response.data.Message ?? 'Something went wrong',
              false,
              '',
              '',
              [],
              [],
              [],
              '',
            ),
          )

          dispatch({
            type: LOGIN_ERROR,
            payload: response.data.Message ?? 'Something went wrong',
          })

          return {
            status: false,
            data: null,
            message: response.data.Message ?? 'Something went wrong',
          }
        }
      })
      .catch((error) => {
        console.log(error.response)
        console.log(error.request)
        //if server is offline or server overload
        //error response will be null
        //check error.request.status will be 0
        dispatch(
          UpdateError(
            '',
            '',
            '',
            error.response?.data.Message ?? 'Something went wrong',
            false,
            '',
            '',
            [],
            [],
            [],
            '',
          ),
        )

        dispatch({
          type: LOGIN_ERROR,
          payload: error.data?.Message ?? 'Something went wrong',
        })

        return {
          status: false,
          data: null,
          message: error.response?.data?.Message ?? 'Something went wrong',
        }
      })
  }
}
