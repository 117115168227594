// ** Icons Import
import {
  Twitch,
  Home,
  Users,
  Mail,
  MessageSquare,
  CheckSquare,
  Calendar,
  FileText,
  Circle,
  File,
  ShoppingCart,
  PlusCircle,
  User,
  UserPlus,
  Shield,
  GitPullRequest,
  UserCheck,
  Target,
} from 'react-feather'
import AccountTreeIcon from '@mui/icons-material/AccountTree'
export default [
  {
    //header: 'Hire10x',
  },
  {
    id: 'homepage-jds',
    title: 'My Dashboard',
    icon: <Home size={12} />,
    navLink: '/apps/homepage',
  },
  {
    id: 'workflows',
    title: 'Hire10x WorkFlow',
    icon: <AccountTreeIcon size={20} />,
    navLink: '/apps/workflow1',
    // children: [
    //   {
    //     id: 'workflow1',
    //     title: 'Hire10x WorkFlow',
    //     icon: <GitPullRequest size={20} />,
    //     navLink: '/apps/workflow1',
    //   },
    // ],
  },

  {
    id: 'createjds',
    title: 'New JD',
    icon: <FileText size={20} />,
    children: [
      {
        id: 'createjds',
        title: 'Create JD',
        icon: <Circle size={20} />,
        navLink: '/apps/createjd',
      },
      {
        id: 'postjds',
        title: 'Post JD',
        icon: <Circle size={20} />,
        navLink: '/apps/postjd',
      },
      {
        id: 'retarget-candidtates',
        title: 'Retarget candidates',
        icon: <Circle size={20} />,
        navLink: '/retargetprospects',
      },
    ],
  },
  {
    id: 'jd-history',
    title: 'Manage JD',
    icon: <UserCheck size={20} />,
    children: [
      // {
      //   id: 'add-candidates',
      //   title: 'Assign Candidates',
      //   icon: <Circle size={12} />,
      //   navLink: '/search',
      // },
      {
        id: 'incomplete-jds',
        title: 'In Progress JD',
        icon: <Circle size={12} />,
        navLink: '/apps/incompletejd',
      },
      {
        id: 'complete-jds',
        title: 'Completed JD',
        icon: <Circle size={12} />,
        navLink: '/apps/completejd',
      },
      // {
      //   id: 'homepage-jds',
      //   title: 'HomePage JD',
      //   icon: <Circle size={12} />,
      //   navLink: '/apps/homepage',
      // },

      {
        /*{
        id: 'analytics',
        title: 'Analytics',
        icon: <Circle size={12} />,
        navLink: '/apps/analytics'
      },*/
      },
    ],
  },
  {
    id: 'extract-contact-Details',
    title: 'Initial Outreach ',
    icon: <User size={20} />,
    navLink: '/apps/extractcontactdetails',
    style: {
      overflowWrap: 'break-word',
    },
  },
  {
    id: 'ai-Assistant',
    title: 'AI Assistant',
    icon: <User size={20} />,
    navLink: '/apps/recruiterassistant',
  },
  // {
  //   id: 'cv-buckets',
  //   title: 'Data Migration',
  //   icon: <Shield size={20} />,
  //   children: [
  //     {
  //       id: 'third-party-integration',
  //       title: 'Data Buckets',
  //       icon: <Circle size={12} />,
  //       navLink: '/apps/thirdparty_integration',
  //     },
  //     {
  //       id: 'process-cvs',
  //       title: 'Process Resume',
  //       icon: <Circle size={12} />,
  //       navLink: '/apps/process_cvs',
  //     },
  //   ],
  // },
  // {
  //   id: 'teams',
  //   title: 'Team Analytics',
  //   icon: <Users size={20} />,
  //   navLink: '/apps/teams',
  // },
]
