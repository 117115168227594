// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import * as api from '@src/api/SettingsApi'
import * as calenderAPI from '@src/api/CalenderApi'
import moment from 'moment-timezone'
// import { getReadableDateTime } from '@src/common/DateHelper'

export const fetchEvents = createAsyncThunk(
  'appCalendar/fetchEvents',
  async ({ zone, start, end, status }) => {
    const res = await calenderAPI.getCalenderEvents(
      zone,
      start,
      end,
      status.toString(),
    )
    console.log('#CALENDER res', res)
    return res.data
  },
)

export const getAllTheStatus = createAsyncThunk(
  'appCalendar/getAllTheStatus',
  async () => {
    let res = await api.getJourneyContactType()
    console.log('#CALENDER', res)
    return res.data
  },
)

export const appCalendarSlice = createSlice({
  name: 'appCalendar',
  initialState: {
    events: [],
    selectedEvent: {},
    selectedCalendars: ['Personal', 'Business', 'Family', 'Holiday', 'ETC'],
    status: [],
  },
  reducers: {
    selectEvent: (state, action) => {
      state.selectedEvent = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchEvents.fulfilled, (state, action) => {
        console.log('#CALENDER action.payload', action.payload)
        let data = action.payload?.Data ?? []
        let value = data.map((k) => {
          // 2024-03-29T19:28:55Z
          let start = moment(k.scheduled_date).format()
          // let start = getReadableDateTime(k.scheduled_date)
          console.log('#CALENDER start', start)
          if (k.is_completed === 1) {
            return {
              ...k,
              start: start,
              color: 'green',
              backgroundColor: 'white',
            }
          } else {
            return {
              ...k,
              start: start,
              backgroundColor: 'white',
              color: 'blue',
              display: 'list-item',
            }
          }
        })
        console.log('#CALENDER value', value)
        state.events = value
      })
      // .addCase(updateFilter.fulfilled, (state, action) => {
      //   if (state.selectedCalendars.includes(action.payload)) {
      //     state.selectedCalendars.splice(
      //       state.selectedCalendars.indexOf(action.payload),
      //       1,
      //     )
      //   } else {
      //     state.selectedCalendars.push(action.payload)
      //   }
      // })
      // .addCase(updateAllFilters.fulfilled, (state, action) => {
      //   const value = action.payload
      //   let selected = []
      //   if (value === true) {
      //     selected = ['Personal', 'Business', 'Family', 'Holiday', 'ETC']
      //   } else {
      //     selected = []
      //   }
      //   state.selectedCalendars = selected
      // })
      .addCase(getAllTheStatus.fulfilled, (state, action) => {
        const value = action.payload
        const journey = value?.Data?.Journey ?? []
        state.status = journey.map((k) => {
          return { rank: k.rank, value: k.value, id: k.id }
        })
      })
  },
})

export const { selectEvent } = appCalendarSlice.actions

export default appCalendarSlice.reducer
