export const DEFAULT_ERROR_MESSAGE = 'Something went wrong, please try again'

// API keys
// export const API_KEY = "AIzaSyAdN3N0IydcKZQzmp6I1aHZOO61IVhF1Ak";
// export const CLIENT_ID = "1075871690897-iok402ebubtc131f2d5shh8q2hhi4l0u.apps.googleusercontent.com";
// export const DISCOVERY_DOCS = ["https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest", 'https://www.googleapis.com/discovery/v1/apis/drive/v3/rest'];
// export const SCOPES = 'https://www.googleapis.com/auth/drive.file https://www.googleapis.com/auth/calendar';

export const API_KEY = 'AIzaSyAdN3N0IydcKZQzmp6I1aHZOO61IVhF1Ak'
export const CLIENT_ID =
  '1075871690897-iok402ebubtc131f2d5shh8q2hhi4l0u.apps.googleusercontent.com'
export const DISCOVERY_DOCS = [
  'https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest',
]
export const SCOPES = 'https://www.googleapis.com/auth/calendar'

export const DEFAULT_IMAGE_URL =
  'https://www.pngfind.com/pngs/m/676-6764065_default-profile-picture-transparent-hd-png-download.png'

export const LEFT_SIDE_CANCEL_ICON = 'delete_forever'
