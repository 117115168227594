import instance from './API'

export function getCVS(
  token,
  jdID,
  candidate_status,
  start,
  end,
  start_id_col,
  start_id_val,
  filters = {},
  filter_mode = 'original',
  search_param = '',
) {
  if (filter_mode === 'original') {
    filters = {}
  }
  console.log(
    'JSON.stringify(filters) ',
    JSON.stringify(filters),
    filters,
    filter_mode,
  )
  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
    params: {
      jdID,
      filters: JSON.stringify(filters),
      start,
      end,
      start_id_col,
      start_id_val,
      candidate_status,
      search_param,
    },
  }

  return instance.get('cvs/', config)
}

export function getNewProfilesCount(token, jdID) {
  const formData = new FormData()
  formData.append('jd_id', jdID)
  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
  }

  return instance.post('cv/newprofiles/', formData, config)
}

export function getNaukriCV(token, cid) {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
    params: {
      cid,
    },
  }

  return instance.get('cv/naukri/', config)
}

export function getCandidateCard(cid) {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
    params: {
      cid,
    },
  }

  return instance.get('resume/CandidateCard/', config)
}

export function getRawCVs(token, cid, jdID) {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
    params: {
      cid,
      jdID,
    },
  }

  return instance.get('cv/raw/', config)
}

export function getRawCV(token, bulk_id, offset, limit) {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
    params: {
      bulk_id,
      offset,
      limit,
    },
  }

  return instance.get('resume/Candidate/files/', config)
}

export function updatePhoneEmail(token, cid, type, value) {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
  }

  const formData = new FormData()
  formData.append('cid', cid)

  if (type === 'Email') {
    formData.append('email', value)
  } else {
    formData.append('phone', value)
  }

  return instance.post('cvs/', formData, config)
}

export function postCvStatusUpdate(token, cvID, status, jdID) {
  const formData = new FormData()
  formData.append('jdID', jdID)
  formData.append('status', status)
  formData.append('cvID', cvID)

  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
  }

  return instance.post('cv/status/', formData, config)
}

export function postCvContact(token, cvID, jdID) {
  const formData = new FormData()
  formData.append('jdID', jdID)
  formData.append('cvID', cvID)

  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
  }

  return instance.post('cv/contact/', formData, config)
}

// export function getCVMatching(token, cvID, jdID) {
//
//     const config = {
//         headers: {
//             'Authorization': "Token " + token,
//             'Content-Type': 'application/json',
//         },
//         params: {
//             jdID: jdID,
//             cvID: cvID
//         }
//     }
//
//     return instance.get("cv-match/", config)
// }

export function postNaukriOTP(token, jdID, otp) {
  const formData = new FormData()
  formData.append('otp', otp)
  formData.append('jdIDs', jdID)

  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
  }

  return instance.post('otp/naukri/', formData, config)
}

export function postLinkedinOTP(token, jdID, otp) {
  const formData = new FormData()
  formData.append('otp', otp)
  formData.append('jdIDs', jdID)

  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
  }

  return instance.post('otp/linkedin/', formData, config)
}

export function postCvScraping(token, userID, jdID) {
  const formData = new FormData()
  formData.append('uuid', userID)
  formData.append('jdIDs', jdID)

  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
  }

  return instance.post('jds/scraping/', formData, config)
}

export function sendEmail(
  jdID,
  cv_ids,
  subject,
  template_id,
  tags,
  statusID = -1,
  message = '',
  files = [],
  cc = '',
  bcc = '',
) {
  console.log(
    'API_sendEmail',
    jdID,
    cv_ids,
    subject,
    template_id,
    tags,
    statusID,
  )
  const formData = new FormData()

  formData.append('jdID', jdID)
  formData.append('cv_ids', cv_ids)
  formData.append('subject', subject)
  formData.append('template_id', template_id)
  formData.append('tags', tags)
  formData.append('status_id', statusID)
  formData.append('message', message)
  formData.append('cc', cc)
  formData.append('bcc', bcc)
  // formData.append('files', files)
  files.forEach((file, index) => {
    formData.append(`files`, file)
  })

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }

  return instance.post('send/email/', formData, config)
}

export function sendSMS(jdID, cv_ids, jdRole, statusID = -1) {
  const formData = new FormData()
  formData.append('jdID', jdID)
  formData.append('cv_ids', cv_ids)
  formData.append('jdRole', jdRole)
  formData.append('status_id', statusID)

  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  }

  return instance.post('send/sms/', formData, config)
}

export function postCvClickView(token, data) {
  const formData = new FormData()
  formData.append('data', data)

  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
  }

  return instance.post('cv/click/', formData, config)
}

export function uploadNaukriCV(token, cvID, jdID, file) {
  const formData = new FormData()
  formData.append('jdID', jdID)
  formData.append('cvID', cvID)
  formData.append('file', file)

  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
  }

  return instance.post('cv/naukri/', formData, config)
}

export function export_profileApi(token, jdID, uuid) {
  const formData = new FormData()
  formData.append('jdID', jdID)
  formData.append('uuid', uuid)

  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
  }

  return instance.post('cv/export/', formData, config)
}

export function updateRankingOld(token, jdID, des, skills, exp) {
  const formData = new FormData()
  formData.append('jd_id', jdID)
  formData.append('skill_scaling_factor', skills)
  formData.append('designation_scaling_factor', des)
  formData.append('exp_scaling_factor', exp)

  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
  }

  return instance.post('cv/ranking/update/', formData, config)
}

export function updateRanking(token, jdID, des, skills, exp) {
  const formData = new FormData()
  formData.append('jd_id', jdID)
  formData.append('skill_scaling_factor', skills)
  formData.append('designation_scaling_factor', des)
  formData.append('exp_scaling_factor', exp)

  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
  }

  return instance.post('cv/ranking/update/', formData, config)
}

export function runRanking(token, jdID) {
  const formData = new FormData()
  formData.append('jdID', jdID)

  const config = {
    headers: {
      //'Authorization': "Token " + token,
      'Content-Type': 'application/json',
    },
  }

  return instance.post('cv/ranking/', formData, config)
}

export function cardEdit(jdID) {
  const formData = new FormData()
  formData.append('jdID', jdID)

  const config = {
    headers: {
      //'Authorization': "Token " + token,
      'Content-Type': 'application/json',
    },
  }

  return instance.post('cv/ranking/', formData, config)
}

export function searchResume(bulk_id, query, offset, limit) {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
    params: {
      bulk_id,
      query,
      offset: offset,
      limit,
    },
  }

  return instance.get('resume/Candidate/search/', config)
}

export function setBulkProcessing(token, location, type, mappings = '') {
  const formData = new FormData()

  formData.append('location', location)
  formData.append('type', type)

  if (mappings !== '') {
    formData.append('db_config', mappings)
  }

  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
  }

  return instance.post('resume/bulkprocess/', formData, config)
}
