import React, { useEffect } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import CardHeader from '@mui/material/CardHeader'
import Tooltip from '@mui/material/Tooltip'
import Avatar from '@mui/material/Avatar'
import IconButton from '@mui/material/IconButton'
import * as commentsApi from '@src/api/CommentsAPI'
import { red } from '@mui/material/colors'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import { getReadableDateTime } from '@src/common/DateHelper'
import { CardBody } from 'reactstrap'
import { deleteComment, getComments } from '../../../api/CommentsAPI'
import { toast } from 'react-toastify'

const useStyles = makeStyles((theme) => ({
  root: {},
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    // transition: theme.transitions.create('transform', {
    //     duration: theme.transitions.duration.shortest,
    // }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
}))

export default function CommentCard({ data, token, cvID, handleDelete }) {
  const classes = useStyles()

  const getColorFromShortName = (shortName) => {
    const colors = [
      '#44576D',
      '#768A96',
      '#aac7d8',
      '#dfebf6',
      '#ff8787',
      '#374254',
      '#03A9F4',
      '#00BCD4',
      '#009688',
      '#4CAF50',
      '#737ba5',
      '#568f7c',
      '#173c4c',
      '#5ebf87',
      '#1b6f85',
    ]

    const index = shortName.charCodeAt(0) % colors.length
    return colors[index]
  }

  return (
    <>
      <div
        className={`card shadow border mb-1 h-75 ${classes.root}`}
        style={{
          boxShadow:
            '0px 1px 3px 0px rgba(0, 0, 0, 0.02), 0px 0px 0px 1px rgba(27, 31, 35, 0.15)',
        }}
      >
        <CardHeader
          className="pb-0"
          avatar={
            <Avatar
              aria-label="recipe"
              className={` ${classes.avatar}`}
              style={{ background: `${getColorFromShortName(data?.name)}` }}
            >
              {data.name.substring(0, 2)}
            </Avatar>
          }
          action={
            <IconButton color="error" aria-label="settings" size="large">
              <HighlightOffIcon onClick={() => handleDelete(data?.id)} />
            </IconButton>
          }
          title={<span className="fw-bolder text-secondary">{data.name}</span>}
          subheader={
            <span className="fw-bolder text-secondary">
              {getReadableDateTime(data.date)}
            </span>
          }
        />
        <CardBody className="">
          <p className="text-secondary ms-4 ">
            {data.comments.length > 100 ? (
              <Tooltip title={data?.comments} placement="top" arrow>
                {data.comments.slice(0, 100).concat('...')}
              </Tooltip>
            ) : (
              data.comments
            )}
          </p>
        </CardBody>
        {console.log('CardHeader', data)}
      </div>
    </>
  )
}
