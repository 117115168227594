import * as api from '../../api/UserProfileApi'
import { analysisResponseStatus } from '../../utility/helperUtils'

export const UpdateProfileError = (error) => ({
  type: 'PROFILE_ERROR',
  error,
})

export const UpdateProfileRedirect = (path, error) => ({
  type: 'PROFILE_REDIRECT',
  path,
  error,
})

export const UpdateProfileSave = (data) => ({
  type: 'PROFILE_SAVE',
  data,
})

export const UpdateProfileClear = () => ({
  type: 'PROFILE_CLEAR',
})

export const UpdatePasswordError = (error) => ({
  type: 'PASSWORD_ERROR',
  error,
})

export const AddUser = (data) => ({
  type: 'ADD_USER',
  data,
})

export const AddUserError = (error) => ({
  type: 'ADD_USER_ERROR',
  error,
})

export const GetManager = (data) => ({
  type: 'GET_MANAGER',
  data,
})

export function getProfileData(token, userId) {
  return function (dispatch) {
    return api
      .getUserProfile(token, userId)
      .then((res) => {
        if (res.data.Response === 'Ok') {
          if (res.data.Data != null) {
            //let data = UserProfileUtils(res.data.Data)
            console.log('Data is not null', res.data.Data)
            dispatch(UpdateProfileSave(res.data.Data))
            return res.data.Data
          }
        } else {
          if (res?.Message?.length > 0) {
            dispatch(UpdateProfileError(res.Message))
          }
        }
      })
      .catch((error) => {
        let status = analysisResponseStatus(error?.response?.status)
        if (error?.redirect) {
          dispatch(
            UpdateProfileRedirect(
              status.path,
              error?.response?.data.Message ?? '',
            ),
          )
        }
      })
  }
}

export function postProfilePageData(userId, data) {
  return function (dispatch) {
    return api
      .sendUserProfile(userId, data)
      .then((res) => {
        if (res.data.Response === 'Ok') {
          dispatch(UpdateProfileSave(res.data.Data))
          return res.data.Data
        } else {
          if (res?.Message?.length > 0) {
            dispatch(UpdateProfileError(res.Message))
          }
        }
      })
      .catch((error) => {
        let status = analysisResponseStatus(error?.response?.status)
        if (error?.redirect) {
          dispatch(
            UpdateProfileRedirect(
              status.path,
              error?.response?.data.Message ?? '',
            ),
          )
        }
      })
  }
}

export function postPasswordData(token, userId, data) {
  return function (dispatch) {
    return api
      .sendUserPassword(token, userId, data)
      .then((res) => {
        if (res.data.Response === 'Ok') {
          // let data = GlobalLoginSettings(res.data.Data)
          // dispatch(UpdateSettingPageSave(
          //     data
          // ))
          return res.data.Data
        } else {
          if (res?.Message?.length > 0) {
            dispatch(UpdatePasswordError(res.Message))
          }
        }
      })
      .catch((error) => {
        let status = analysisResponseStatus(error?.response?.status)
        if (error?.redirect) {
          dispatch(
            UpdateProfileRedirect(
              status.path,
              error?.response?.data.Message ?? '',
            ),
          )
        }
      })
  }
}

export function postAddUserData(token, data) {
  return function (dispatch) {
    try {
      return api.addUser(token, data).then((res) => {
        if (res.data.Response === 'Ok') {
          dispatch(AddUser(res.data))
          return res.data
        } else {
          if (res?.Message?.length > 0) {
            dispatch(AddUserError(res.Message))
          }
        }
      })
    } catch (error) {
      let status = analysisResponseStatus(error?.response?.status)
      if (error?.redirect) {
        dispatch(
          UpdateProfileRedirect(
            status.path,
            error?.response?.data.Message ?? 'role',
          ),
        )
      }
      return 'Error'
    }
  }
}

export function getManagerData(token, data) {
  return function (dispatch) {
    try {
      return api.getManager(token, data).then((res) => {
        if (res.data.Response === 'Ok') {
          dispatch(GetManager(res.data))
          return res.data
        } else {
          if (res?.Message?.length > 0) {
            dispatch(AddUserError(res.Message))
          }
        }
      })
    } catch (error) {
      let status = analysisResponseStatus(error?.response?.status)
      if (error?.redirect) {
        dispatch(
          UpdateProfileRedirect(
            status.path,
            error?.response?.data.Message ?? '',
          ),
        )
      }
      return 'Error'
    }
  }
}
