const initialState = {
  data: [
    {
      designation: '',
      max_exp: '',
      min_exp: '',
      education: '',
      skills: '',
      domain: '',
      college_type: '',
      company_type: '',
      jd_score: 0,
      jd_tag: '',
      improvement_text: '',
      rawJD: '',
      value: '',
      source: 0,
    },
  ],
  naukri: [
    {
      naukri_premium: '',
      naukri_min_exp: '',
      naukri_max_exp: '',
      naukri_min_ctc: '',
      naukri_max_ctc: '',
      naukri_keyword: '',
      profiles_scraped: 40,
      naukri_mode: 'economy',
      job_type: '',
      employment_type: '',
    },
  ],
  jd_fetch_meta_data: [
    {
      fetch_time: new Date(),
      naukri_count: 0,
      linkedin_count: 0,
      db_count: 0,
      referrals_count: 0,
      cloud_count: 0,
      gdrive_count: 0,
      total_count: 0,
    },
  ],
  jd_name: [''],
  search_profiles_view: ['list'],
  designation: [''],
  fetchTime: [Date.now()],
  error: [''],
  loading: [false],
  redirect: [false],
  path: [''],
  file: [
    {
      name: '',
      date: '',
      file: '',
    },
  ],
  last_fetch_time: [''],
  is_ranking_updated: [''],
  jdID: [],
  is_jd_saved: [false],
  naukri_otp_dialog_open: [false],
  linkedin_otp_dialog_open: [false],
  live_scraping_update: [false],
  tab_value: [0],
}

const JDEvaluateReducer = (state = initialState, action) => {
  var index =
    state?.jdID
      ?.map((k) => k?.toString())
      ?.indexOf(action?.jd_id?.toString()) ?? -1

  switch (action.type) {
    case 'JD_EVALUATE_PREVIOUS':
      console.log('REDUCER: ', state, action)
      let res = {
        ...state,
        data: state.data.map((k, i) => {
          if (i === index) {
            return action.initValue.data
          } else {
            return k
          }
        }),
        naukri: state.naukri.map((k, i) => {
          if (i === index) {
            return action.initValue.naukri
          } else {
            return k
          }
        }),
        jd_name: state.jd_name.map((k, i) => {
          if (i === index) {
            return action.initValue.jd_name
          } else {
            return k
          }
        }),
        designation: state.designation.map((k, i) => {
          if (i === index) {
            return action.initValue.designation
          } else {
            return k
          }
        }),
        fetchTime: state.fetchTime.map((k, i) => {
          if (i === index) {
            return Date.now()
          } else {
            return k
          }
        }),
        loading: state.loading.map((k, i) => {
          if (i === index) {
            return false
          } else {
            return k
          }
        }),
        last_fetch_time: state.last_fetch_time.map((k, i) => {
          if (i === index) {
            return action.initValue.last_fetch_time
          } else {
            return k
          }
        }),
        is_ranking_updated: state.is_ranking_updated.map((k, i) => {
          if (i === index) {
            return action.initValue.is_ranking_updated
          } else {
            return k
          }
        }),
        error: state.error.map((k, i) => {
          if (i === index) {
            return ''
          } else {
            return k
          }
        }),
        search_profiles_view: state.search_profiles_view.map((k, i) => {
          if (i === index) {
            return ''
          } else {
            return k
          }
        }),
        jd_fetch_meta_data: state.jd_fetch_meta_data.map((k, i) => {
          if (i === index) {
            return {
              fetch_time: new Date(),
              naukri_count: 0,
              linkedin_count: 0,
              db_count: 0,
              referrals_count: 0,
              cloud_count: 0,
              gdrive_count: 0,
              total_count: 0,
            }
          } else {
            return k
          }
        }),
        tab_value: state.tab_value.map((k, i) => {
          if (i === index) {
            return 0
          } else {
            return k
          }
        }),
      }
      console.log('RES', res)
      return res
    case 'JD_EVALUATE_LAST_FETCH_TIME':
      return {
        ...state,
        last_fetch_time: Object.assign({}, state).last_fetch_time.map(
          (k, i) => {
            if (i === index) {
              return action.last_fetch_time
            } else {
              return k
            }
          },
        ),
      }
    case 'JD_EVALUATE_IS_RANKING_UPDATED':
      return {
        ...state,
        is_ranking_updated: Object.assign({}, state).is_ranking_updated.map(
          (k, i) => {
            if (i === index) {
              return action.is_ranking_updated
            } else {
              return k
            }
          },
        ),
      }
    case 'JD_EVALUATE_FETCH_META_DATA':
      return {
        ...state,
        jd_fetch_meta_data: Object.assign({}, state).jd_fetch_meta_data.map(
          (k, i) => {
            if (i === index) {
              return action.jd_fetch_meta_data
            } else {
              return k
            }
          },
        ),
      }
    case 'UPDATE_EVALUATE_TAB_VALUE':
      console.log(
        'tab_value: ',
        action,
        index,
        Object.assign({}, state).tab_value.map((k, i) => {
          if (i === index) {
            return action.tab_value
          } else {
            return k
          }
        }),
      )
      return {
        ...state,
        tab_value: Object.assign({}, state).tab_value.map((k, i) => {
          if (i === index) {
            return action.tab_value
          } else {
            return k
          }
        }),
      }
    case 'JD_EVALUATE_ERROR':
      return {
        ...state,
        error: Object.assign({}, state).error.map((k, i) => {
          if (i === index) {
            return action.error
          } else {
            return k
          }
        }),
        loading: Object.assign({}, state).loading.map((k, i) => {
          if (i === index) {
            return false
          } else {
            return k
          }
        }),
      }

    case 'UPDATE_LIVE_SCRAPING':
      return {
        ...state,
        live_scraping_update: Object.assign({}, state).live_scraping_update.map(
          (k, i) => {
            if (i === index) {
              return action.live_scraping_update
            } else {
              return k
            }
          },
        ),
      }
    case 'JD_EVALUATE_UPDATE_GRID_STATUS':
      return {
        ...state,
        search_profiles_view: Object.assign({}, state).search_profiles_view.map(
          (k, i) => {
            if (i === index) {
              return action.search_profiles_view_type
            } else {
              return k
            }
          },
        ),
      }
    case 'JD_EVALUATE_UPDATE_SAVE_STATUS':
      return {
        ...state,
        is_jd_saved: Object.assign({}, state).is_jd_saved.map((k, i) => {
          if (i === index) {
            return action.is_jd_saved
          } else {
            return k
          }
        }),
      }
    case 'JD_REMOVE_ELEMENT':
      return {
        ...state,
        jdID: state.jdID.filter((k, i) => {
          return i !== index
        }),
        data: state.data.filter((k, i) => {
          return i !== index
        }),
        naukri: state.naukri.filter((k, i) => {
          return i !== index
        }),
        jd_name: state.jd_name.filter((k, i) => {
          return i !== index
        }),
        designation: state.designation.filter((k, i) => {
          return i !== index
        }),
        search_profiles_view: state.search_profiles_view.filter((k, i) => {
          return i !== index
        }),
        file: state.file.filter((k, i) => {
          return i !== index
        }),
        error: state.error.filter((k, i) => {
          return i !== index
        }),
        loading: state.loading.filter((k, i) => {
          return i !== index
        }),
        redirect: state.redirect.filter((k, i) => {
          return i !== index
        }),
        path: state.path.filter((k, i) => {
          return i !== index
        }),
        last_fetch_time: state.last_fetch_time.filter((k, i) => {
          return i !== index
        }),
        is_ranking_updated: state.is_ranking_updated.filter((k, i) => {
          return i !== index
        }),
        is_jd_saved: state.is_jd_saved.filter((k, i) => {
          return i !== index
        }),
        live_scraping_update: state.live_scraping_update.filter((k, i) => {
          return i !== index
        }),
        fetchTime: state.fetchTime.filter((k, i) => {
          return i !== index
        }),
        jd_fetch_meta_data: state.jd_fetch_meta_data.filter((k, i) => {
          return i !== index
        }),
        tab_value: state.tab_value.filter((k, i) => {
          if (i === index) {
            return false
          } else {
            return true
          }
        }),
      }
    case 'JD_EVALUATE_UPDATE_JD':
      if (state.jdID?.includes(action.jd_id)) {
        //great this jd_id is included, hurray! just return the past state as is
        return {
          ...state,
        }
      } else {
        let jd_ids = Object.assign({}, state).jdID
        jd_ids?.push(action.jd_id)
        //insert data into

        if (
          state.jdID?.length > 1 ||
          (state.jdID?.length === 1 && state.data.length === 0)
        ) {
          let data = Object.assign({}, state).data
          data.push(initialState.data[0])
          let naukri = Object.assign({}, state).naukri
          naukri.push(initialState.naukri[0])
          let jd_name = Object.assign({}, state).jd_name
          jd_name.push(initialState.jd_name[0])
          let designation = Object.assign({}, state).designation
          designation.push(initialState.designation[0])
          let file = Object.assign({}, state).file
          file.push(initialState.file[0])
          let error = Object.assign({}, state).error
          error.push(initialState.error[0])
          let path = Object.assign({}, state).path
          path.push(initialState.path[0])
          let last_fetch_time = Object.assign({}, state).last_fetch_time
          last_fetch_time.push(initialState.last_fetch_time[0])
          let is_ranking_updated = Object.assign({}, state).is_ranking_updated
          is_ranking_updated.push(initialState.is_ranking_updated[0])
          let redirect = Object.assign({}, state).redirect
          redirect.push(initialState.redirect[0])
          let loading = Object.assign({}, state).loading
          loading.push(initialState.loading[0])
          let search_profiles_view = Object.assign(
            {},
            state,
          ).search_profiles_view
          search_profiles_view.push(initialState.search_profiles_view[0])
          let is_jd_saved = Object.assign({}, state).is_jd_saved
          is_jd_saved.push(initialState.is_jd_saved[0])
          let live_scraping_update = Object.assign(
            {},
            state,
          ).live_scraping_update
          live_scraping_update.push(initialState.live_scraping_update[0])
          let fetchTime = Object.assign({}, state).fetchTime
          fetchTime.push(initialState.fetchTime[0])
          let jd_fetch_meta_data = Object.assign({}, state).jd_fetch_meta_data
          jd_fetch_meta_data.push(initialState.jd_fetch_meta_data[0])
          let tab_value = Object.assign({}, state).tab_value
          tab_value.push(initialState.tab_value[0])
          return {
            ...state,
            jdID: jd_ids,
            data: data,
            naukri: naukri,
            jd_name: jd_name,
            designation: designation,
            file: file,
            error: error,
            path: path,
            last_fetch_time: last_fetch_time,
            is_ranking_updated: is_ranking_updated,
            redirect: redirect,
            loading: loading,
            search_profiles_view: search_profiles_view,
            is_jd_saved: is_jd_saved,
            jd_fetch_meta_data: jd_fetch_meta_data,
            live_scraping_update: live_scraping_update,
            fetchTime: fetchTime,
            tab_value: tab_value,
          }
        } else {
          return {
            ...state,
            jdID: jd_ids,
          }
        }
      }

    case 'JD_EVALUATE_INIT':
      return {
        ...state,
        data: state.data.map((k, i) => {
          if (i === index) {
            return initialState.data
          } else {
            return k
          }
        }),
        naukri: state.naukri.map((k, i) => {
          if (i === index) {
            return initialState.naukri
          } else {
            return k
          }
        }),
        jd_name: state.jd_name.map((k, i) => {
          if (i === index) {
            return initialState.jd_name
          } else {
            return k
          }
        }),
        designation: state.designation.map((k, i) => {
          if (i === index) {
            return initialState.designation
          } else {
            return k
          }
        }),
        search_profiles_view: state.search_profiles_view.map((k, i) => {
          if (i === index) {
            return initialState.search_profiles_view
          } else {
            return k
          }
        }),
        file: state.file.map((k, i) => {
          if (i === index) {
            return initialState.file
          } else {
            return k
          }
        }),
        error: state.error.map((k, i) => {
          if (i === index) {
            return initialState.error
          } else {
            return k
          }
        }),
        loading: state.loading.map((k, i) => {
          if (i === index) {
            return false
          } else {
            return k
          }
        }),
        redirect: state.redirect.map((k, i) => {
          if (i === index) {
            return initialState.redirect
          } else {
            return k
          }
        }),
        path: state.path.map((k, i) => {
          if (i === index) {
            return ''
          } else {
            return k
          }
        }),
        last_fetch_time: state.last_fetch_time.map((k, i) => {
          if (i === index) {
            return ''
          } else {
            return k
          }
        }),
        is_ranking_updated: state.is_ranking_updated.map((k, i) => {
          if (i === index) {
            return ''
          } else {
            return k
          }
        }),
        is_jd_saved: state.is_jd_saved.map((k, i) => {
          if (i === index) {
            return false
          } else {
            return k
          }
        }),
        live_scraping_update: state.live_scraping_update.map((k, i) => {
          if (i === index) {
            return false
          } else {
            return k
          }
        }),
        fetchTime: state.fetchTime.map((k, i) => {
          if (i === index) {
            return Date.now()
          } else {
            return k
          }
        }),
        jd_fetch_meta_data: state.jd_fetch_meta_data.map((k, i) => {
          if (i === index) {
            return initialState.jd_fetch_meta_data
          } else {
            return k
          }
        }),
        tab_value: state.tab_value.map((k, i) => {
          if (i === index) {
            return 0
          } else {
            return k
          }
        }),
      }
    case 'JD_EVALUATE_CLEAR_ALL':
      let x = {
        data: [
          {
            designation: '',
            max_exp: '',
            min_exp: '',
            education: '',
            skills: '',
            domain: '',
            college_type: '',
            company_type: '',
            jd_score: 0,
            jd_tag: '',
            improvement_text: '',
            rawJD: '',
            value: '',
            source: 0,
          },
        ],
        naukri: [
          {
            naukri_premium: '',
            naukri_min_exp: '',
            naukri_max_exp: '',
            naukri_min_ctc: '',
            naukri_max_ctc: '',
            naukri_keyword: '',
            profiles_scraped: 40,
            naukri_mode: 'economy',
            job_type: '',
            employment_type: '',
          },
        ],
        jd_fetch_meta_data: [
          {
            fetch_time: new Date(),
            naukri_count: 0,
            linkedin_count: 0,
            db_count: 0,
            referrals_count: 0,
            cloud_count: 0,
            gdrive_count: 0,
            total_count: 0,
          },
        ],
        jd_name: [''],
        designation: [''],
        fetchTime: [Date.now()],
        error: [''],
        loading: [false],
        redirect: [false],
        path: [''],
        search_profiles_view: ['list'],
        file: [
          {
            name: '',
            date: '',
            file: '',
          },
        ],
        last_fetch_time: [''],
        is_ranking_updated: [''],
        jdID: [],
        is_jd_saved: [false],
        naukri_otp_dialog_open: [false],
        linkedin_otp_dialog_open: [false],
        live_scraping_update: [false],
        tab_value: [0],
      }
      console.log('ex2: ', x)
      return x
    case 'JD_EVALUATE_REDIRECT':
      return {
        ...state,
        error: state.error.map((k, i) => {
          if (i === index) {
            return action.error
          } else {
            return k
          }
        }),
        loading: state.loading.map((k, i) => {
          if (i === index) {
            return true
          } else {
            return k
          }
        }),
        redirect: state.redirect.map((k, i) => {
          if (i === index) {
            return true
          } else {
            return k
          }
        }),
        path: state.path.map((k, i) => {
          if (i === index) {
            return action.path
          } else {
            return k
          }
        }),
      }

    default:
      return state
  }
}

export default JDEvaluateReducer
