import instance from './API'

export function getCalenderEvents(zone, start, end, status) {
  const config = {
    headers: {
      // Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
    params: {
      zone,
      start,
      end,
      status,
    },
  }

  // https://devserver.hire10x.ai/api/cv/sheduledtask/
  return instance.get('user/scheduledtasks/', config)
}

export function getDownloadCalenderEvents(zone, start, end, status) {
  const config = {
    params: {
      zone,
      start,
      end,
      status,
    },
    responseType: 'blob',
  }

  // https://devserver.hire10x.ai/api/cv/sheduledtask/
  return instance.get('user/scheduledtasks/download/', config)
}
