import instance from './API'

export function getComments(token, cvID) {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
    params: {
      cvID,
    },
  }

  return instance.get('cv/profile/comments/', config)
}

export function postComment(token, cvID, comments, jdID) {
  const formData = new FormData()
  formData.append('comments', comments)
  formData.append('cvID', cvID)
  formData.append('jdID', jdID)

  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
    params: {
      cvID,
      comments,
      jdID,
    },
  }

  return instance.post('cv/profile/comments/', formData, config)
}

// export function deleteComment(token, id) {
//   console.log('deleteComment', id)
//   const formData = new FormData()
//   formData.append('id', id)
//   const config = {
//     headers: {
//       Authorization: `Token ${token}`,
//       'Content-Type': 'application/json',
//     },
//   }

//   return instance.delete('cv/profile/comments/', config)
// }

export function deleteComment(token, id) {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
    data: {
      id,
    },
  }

  return instance.delete('cv/profile/comments/', config)
}
