import instance from './API'

//save profile
export function sendUserProfile(userId, data) {
  const formData = new FormData()
  formData.append('uuid', userId)
  formData.append('fullName', data.fullName)
  // formData.append("username", data.username ?? "");
  formData.append('email', data.email ?? '')
  formData.append('company_name', data.company_name ?? '')
  formData.append('designation', data.designation)
  formData.append('short_description', data.short_description ?? '')
  formData.append('phone_number', data.phone_number)
  formData.append('image', data.image ?? '')

  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  }

  return instance.post('user/update/', formData, config)
}

//get profile
export function getUserProfile(token, userID) {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
    params: {
      uuid: userID,
    },
  }

  return instance.get('user/update/', config)
}

//get Users
export function getUsers() {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  }

  return instance.get('user/teams/', config)
}

// API to get Teams Data
export function getTeamsData() {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  }

  return instance.get('user/team/teams/', config)
}

//update password
export function sendUserPassword(token, userId, data) {
  const formData = new FormData()
  formData.append('userID', userId)
  formData.append('current_password', data.current_password)
  formData.append('new_password', data.new_password)

  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
  }

  console.log('FORM DATA ', formData, config)

  return instance.post('user/update/password/', formData, config)
}

// add user
export function addUser(token, data) {
  const formData = new FormData()
  formData.append('fullname', data.fullname)
  formData.append('username', data.username)
  formData.append('role', data.role)
  formData.append('designation', data.designation)
  formData.append('password', data.password)
  formData.append('mgrID', data.mgrID)
  // formData.append("comID", data.comID);
  formData.append('short_description', data.short_description)
  formData.append('phone_number', data.phone_number)
  formData.append('email', data.email)
  // formData.append("image", data.image);

  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
  }

  return instance.post('user/create/', formData, config)
}

//get manager
export function getManager(token, userID) {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
    params: {
      uuid: userID,
    },
  }

  return instance.get('user/team/manager/', config)
}

//get team workload
export function getTeamWorkload(token, userID) {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
    params: {
      uuid: userID,
    },
  }

  return instance.get('user/team/workload/', config)
}

// send reset Email
export function sendResetEmail(email) {
  const formData = new FormData()
  formData.append('email', email)

  const config = {
    headers: {
      //'Authorization': "Token " + token,
      'Content-Type': 'application/json',
    },
  }

  return instance.post('user/reset/sendemail/', formData, config)
}

// send reset Password
export function sendResetPassword(token, password) {
  const formData = new FormData()
  formData.append('password', password)
  formData.append('token', token)

  const config = {
    headers: {
      //  'Authorization': "Token " + token,
      'Content-Type': 'multipart/form-data',
    },
  }

  return instance.post('user/reset/password/', formData, config)
}

export function getUserSettings() {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  }

  return instance.get('user/settings/', config)
}

export function postUserSettings(status) {
  const formData = new FormData()
  formData.append('prefers_personal_email', status)

  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  }

  return instance.post('user/settings/', formData, config)
}
export function preventMailNotification(status) {
  console.log('This is the status from the function ', status)
  const formData = new FormData()
  formData.append('schedule_notification', status)
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  }

  return instance.post('user/settings/', formData, config)
}
