import React, { useEffect } from 'react'
import withStyles from '@mui/styles/withStyles'
import { styles } from '../../jd/incomplete/Helper'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import CircularProgress from '@mui/material/CircularProgress'
import * as profileApi from '@src/api/CvApi'

function ResumeTab(props) {
  console.log('Resume Tab', props)

  const [data, setData] = React.useState({
    fileType: '',
    url: '',
  })

  const [loading, setLoading] = React.useState(true)

  let token = props.token
  let cvID = props.id
  let jdId = props.jdID
  console.log('Resume Tab', token)
  console.log('Resume Tab', props)

  useEffect(() => {
    setLoading(true)

    const fetchData = async () => {
      try {
        const e = await profileApi.getRawCVs(token, cvID, jdId)
        if (e.status === 200) {
          console.log('Resume Tab response: ', e)
          let format = e.data.Data.split('.').slice(-1)[0]
          let url =
            'https://docs.google.com/viewer?url=' +
            e.data.Data +
            '&embedded=true'
          setData((prevState) => ({
            ...prevState,
            fileType: format,
            url: e.data.Data,
          }))
        }
      } catch (error) {
        console.error('Error fetching data:', error)
      } finally {
        setTimeout(() => {
          setLoading(false)
        }, 2000)
      }
    }

    fetchData()
  }, [cvID, token, jdId])

  console.log('CV link', data)
  return (
    <div>
      {loading ? (
        <div
          style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 1000,
          }}
        >
          <CircularProgress />
        </div>
      ) : data.url !== '' ? (
        <div>
          <iframe
            src={data.url}
            style={{ width: '100%', height: '800px' }}
          ></iframe>
        </div>
      ) : (
        <div>No CV Link Found</div>
      )}
    </div>
  )
}
const mapStateToProps = (state) => ({
  token: state.LoginReducers.token,
  uuid: state.LoginReducers.uuid,
})

const mapDispatchToProps = (dispatch) => ({})

export default withStyles(styles, { withTheme: true })(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(ResumeTab)),
)
