import thunk from 'redux-thunk'
import RootReducer from './rootReducer'
import storage from 'redux-persist/lib/storage'
import { configureStore } from '@reduxjs/toolkit'
import { persistReducer, persistStore } from 'redux-persist'
import * as Sentry from '@sentry/react'

export const config = {
  key: 'root',
  storage,
  debug: process.env.NODE_ENV !== 'production',
}

const resetEnhancer = (rootReducer) => (state, action) => {
  if (action.type !== 'RESET') return rootReducer(state, action)
  storage.removeItem('persist:root')
  return rootReducer(undefined, {})
}

const persistedReducer = persistReducer(config, resetEnhancer(RootReducer))

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options listed below
})

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [thunk],
  enhancers: [sentryReduxEnhancer],
})

export const persistor = persistStore(store)
