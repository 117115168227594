import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  selectedProfiles: [],
  getCurrentFlow: {},
  flow: {},
  nodes: [],
  edges: [],
  selectedNodeId: '',
  selectedNodeName: '',
  attachFile: false,
  assignedToFocusFlag: false,
  skillsFocusFlag: false,
  profilesFocusFlag: false,
  accorID: [],
  saveJDFocusFlag: false,
  stages: {},
  isProceedClicked: false,
  errorFlag: false,
  currentStage: '',
  noErrorFlag: false,
  currentNode: '',
  isSaveClicked: false,
  tooltipSection: '',
  jdNameTooltip: false,
  assignedtoToolTip: false,
  experienceTooltip: false,
  primarySkillsTooltip: false,
  secondarySkillsTooltip: false,
  noticePeriodTooltip: false,
  prefferedLocationTooltip: false,
  companyNameTooltip: false,
  companyUrlTooltip: false,
  excelSheetTooltip: false,
  profileTooltip: false,
  collegesTooltip: false,
  industriesTooltip: false,
  companiesTooltip: false,
  keywordsTooltip: false,
  skillsTooltip: false,
  designationTooltip: false,
  proceedTooltip: ['', false],
  editorTooltip: false,
  parseTooltip: false,
  isAttachClicked: false,
  databaseTooltip: false,
  sourceAndTimeToolTip: false,
  locationToolTip: false,
  graduationToolTip: false,
  cvToolTip: false,
  fetchResultToolTip: false,
  selectProfilesToolTip: false,
  addToJdToolTip: false,
  isFilterClicked: false,
  isFetchClicked: false,
  saveProfilesClicked: false,
  isAddToJDClicked: false,
  selectedFlow: '',
  spLinkedinView: false,
  teamList: [],
  selectedAssignedTo: [],
  checkboxTooltip: false,
  mailTooltip: false,
  smsTooltip: false,
  whatsAppTooltip: false,
  linkedinTooltip: false,
  templateTooltip: false,
  statusbarTooltip: false,
  subjectTooltip: false,
  sendTooltip: false,
  sendsmsTooltip: false,
  contactTooltip: false,
  naukriTooltip: false,
  companyWebsiteTooltip: false,
  jobPostTooltip: false,
  isJobPostClicked: false,
  isParseJDClicked: false,
  currentJDID: 0,
  postToGropJD: '',
  candidateDet: [],
}

export const workflowSlice = createSlice({
  name: 'workflow',
  initialState,
  reducers: {
    addNewFlow: (state, action) => {
      state.flow = action.payload.flow
      state.nodes = action.payload.nodes
      state.edges = action.payload.edges
    },
    setUpdatedNodes: (state, action) => {
      state.nodes = action.payload
    },
    updateFlowCurrentNode: (state, action) => {
      var node = action.payload
      state.flow['current_node'] = node
    },
    updateNode: (state, action) => {
      var style = {}
      // let nodes = action.payload.nodes;
      style.backgroundColor = action.payload
      const nodeToUpdate = state.nodes.find(
        (node) => node.id === state.selectedNodeId,
      )
      console.log(' state.nodes - ', state.nodes)
      console.log('nodeToUpdate - ', nodeToUpdate)
      if (nodeToUpdate) {
        nodeToUpdate.style = style
      }
    },
    addProfile: (state, action) => {
      if (state.selectedProfiles.includes(action.payload)) {
        return
      }
      state.selectedProfiles.push(action.payload)
    },
    removeProfile: (state, action) => {
      state.selectedProfiles = state.selectedProfiles.filter(
        (item) => item !== action.payload,
      )
    },
    resetProfiles: (state, action) => {
      state.selectedProfiles = []
    },
    updateEdge: (state, action) => {
      var edge = action.payload
      // get edge index
      var index = state.edges.findIndex((k) => {
        return k.id === edge.id
      })
      state.edges[index] = edge
    },
    clearFlow: (state, action) => {
      state.flow = {}
      state.edges = {}
      state.nodes = {}
    },
    setSelectedNode: (state, action) => {
      console.log('action.payload setselected node - ', action.payload)
      state.selectedNodeId = action.payload
    },
    setSelectedNodeName: (state, action) => {
      state.selectedNodeName = action.payload
    },
    setAccorId: (state, action) => {
      state.accorID = action.payload
    },
    setEditorTooltip: (state, action) => {
      state.editorTooltip = action.payload
    },
    setAttachFileTooltip: (state, action) => {
      state.attachFile = action.payload
    },
    setDesignationTooltip: (state, action) => {
      state.designationTooltip = action.payload
    },
    setAssignedToFocusFlag: (state, action) => {
      state.assignedToFocusFlag = action.payload
    },
    setSkillsFocusFlag: (state, action) => {
      state.skillsFocusFlag = action.payload
    },
    setProfilesFocusFlag: (state, action) => {
      state.profilesFocusFlag = action.payload
    },
    setProceedTooltip: (state, action) => {
      state.proceedTooltip = action.payload
    },
    setSaveJDFocusFlag: (state, action) => {
      state.saveJDFocusFlag = action.payload
    },
    setIsProceedClicked: (state, action) => {
      state.isProceedClicked = action.payload
    },
    setValidationError: (state, action) => {
      state.errorFlag = action.payload
    },
    setCurrentStage: (state, action) => {
      state.currentStage = action.payload
    },
    setNoErrorFlag: (state, action) => {
      state.noErrorFlag = action.payload
    },
    setParseTooltip: (state, action) => {
      state.parseTooltip = action.payload
    },
    setSaveClicked: (state, action) => {
      state.isSaveClicked = action.payload
    },
    setCurrentNode: (state, action) => {
      state.currentNode = action.payload
    },
    setTooltipSection: (state, action) => {
      state.tooltipSection = action.payload
    },
    setIsParseJDClicked: (state, action) => {
      state.parseTooltip = action.payload
    },
    setJobPostTooltip: (state, action) => {
      state.jobPostTooltip = action.payload
    },

    setIsJobPostClicked: (state, action) => {
      state.isJobPostClicked = action.payload
    },

    setExcelTooltip: (state, action) => {
      state.excelSheetTooltip = action.payload
    },
    setIsFilterClicked: (state, action) => {
      state.isFilterClicked = action.payload
    },
    setIsFetchClicked: (state, action) => {
      state.isFetchClicked = action.payload
    },
    setSaveProfilesClicked: (state, action) => {
      state.saveProfilesClicked = action.payload
    },
    setAddToJDClicked: (state, action) => {
      state.isAddToJDClicked = action.payload
    },
    setSelectedFlow: (state, action) => {
      state.selectedFlow = action.payload
    },
    setSpLinkedinView: (state, action) => {
      state.spLinkedinView = action.payload
    },
    setTeamList: (state, action) => {
      state.teamList = action.payload
    },
    setAssignedTo: (state, action) => {
      state.selectedAssignedTo = action.payload
    },

    setGetCurrentFlow: (state, action) => {
      state.getCurrentFlow = action.payload
    },

    setCheckboxTooltip: (state, action) => {
      state.checkboxTooltip = action.payload
    },
    setMailTooltip: (state, action) => {
      state.mailTooltip = action.payload
    },
    setSmsTooltip: (state, action) => {
      state.smsTooltip = action.payload
    },
    setWhatsAppTooltip: (state, action) => {
      state.whatsAppTooltip = action.payload
    },
    setLinkedinTooltip: (state, action) => {
      state.linkedinTooltip = action.payload
    },
    setTemplateTooltip: (state, action) => {
      state.templateTooltip = action.payload
    },
    setStatusTooltip: (state, action) => {
      state.statusTooltip = action.payload
    },
    setSubjectTooltip: (state, action) => {
      state.subjectTooltip = action.payload
    },
    setSendTooltip: (state, action) => {
      state.sendTooltip = action.payload
    },
    setSendsmsTooltip: (state, action) => {
      state.sendsmsTooltip = action.payload
    },
    setContactTooltip: (state, action) => {
      state.contactTooltip = action.payload
    },
    setCurrentJDID: (state, action) => {
      state.currentJDID = action.payload
    },

    setPostToGropJD: (state, action) => {
      state.postToGropJD = action.payload
    },

    setCandidateDet: (state, action) => {
      state.candidateDet = action.payload
    },

    resetStates: (state) => {
      state.accorID = ['1']
      state.assignedToFocusFlag = false
      state.currentStage = ''
      state.designationTooltip = false
      state.editorTooltip = false
      state.parseTooltip = false
      state.noErrorFlag = false
      state.proceedTooltip = ['', false]
      state.attachFile = false
      state.isProceedClicked = false
      state.skillsFocusFlag = false
      state.profilesFocusFlag = false
      state.saveJDFocusFlag = false
      state.selectedNodeId = ''
      state.isSaveClicked = false
      state.isAttachClicked = false
      state.isJobPostClicked = false
      state.errorFlag = false
      state.isParseJDClicked = false
      state.isAttachClicked = false
      state.sourceAndTimeToolTip = false
      state.locationToolTip = false
      state.graduationToolTip = false
      state.cvToolTip = false
      state.fetchResultToolTip = false
      state.selectProfilesToolTip = false
      state.addToJdToolTip = false
      state.isFilterClicked = false
      state.isFetchClicked = false
      state.saveProfilesClicked = false
      state.isAddToJDClicked = false
      state.spLinkedinView = false
      state.teamList = []
      state.selectedAssignedTo = []
      state.checkboxTooltip = false
      state.mailTooltip = false
      state.smsTooltip = false
      state.whatsAppTooltip = false
      state.linkedinTooltip = false
      state.bwNodeId = ''
      state.bwNodeName = ''
      state.bwFlowName = ''
      state.selectedProfiles = []
      state.selectedFlow = ''
      state.selectedNodeId = ''
    },
    clearTooltips: (state) => {
      state.jdNameTooltip = false
      state.assignedtoToolTip = false
      state.experienceTooltip = false
      state.primarySkillsTooltip = false
      state.secondarySkillsTooltip = false
      state.noticePeriodTooltip = false
      state.prefferedLocationTooltip = false
      state.companyNameTooltip = false
      state.companyUrlTooltip = false
      state.excelSheetTooltip = false
      state.profileTooltip = false
      state.collegesTooltip = false
      state.industriesTooltip = false
      state.companiesTooltip = false
      state.keywordsTooltip = false
      state.skillsTooltip = false
      state.designationTooltip = false
      state.editorTooltip = false
      state.parseTooltip = false
      state.naukriTooltip = false
      state.jobPostTooltip = false
      state.isJobPostClicked = false
      state.excelSheetTooltip = false
      state.databaseTooltip = false
      state.sourceAndTimeToolTip = false
      state.locationToolTip = false
      state.graduationToolTip = false
      state.cvToolTip = false
      state.fetchResultToolTip = false
      state.selectProfilesToolTip = false
      state.addToJdToolTip = false
      state.checkboxTooltip = false
      state.mailTooltip = false
      state.smsTooltip = false
      state.whatsAppTooltip = false
      state.linkedinTooltip = false
    },
    setCreateJDTooltip: (state, action) => {
      if (action.payload === 'jdName') state.jdNameTooltip = true
      else if (action.payload === 'assignedTo') state.assignedtoToolTip = true
      else if (action.payload === 'experience') state.experienceTooltip = true
      else if (action.payload === 'primarySkill')
        state.primarySkillsTooltip = true
      else if (action.payload === 'secondarySkill')
        state.secondarySkillsTooltip = true
      else if (action.payload === 'noticePeriod')
        state.noticePeriodTooltip = true
      else if (action.payload === 'prefferedLocation')
        state.prefferedLocationTooltip = true
      else if (action.payload === 'companyName') state.companyNameTooltip = true
      else if (action.payload === 'companyUrl') state.companyUrlTooltip = true
      else if (action.payload === 'profile') state.profileTooltip = true
      else if (action.payload === 'colleges') state.collegesTooltip = true
      else if (action.payload === 'industries') state.industriesTooltip = true
      else if (action.payload === 'companies') state.companiesTooltip = true
      else if (action.payload === 'keywords') state.keywordsTooltip = true
      else if (action.payload === 'skills') state.skillsTooltip = true
      else if (action.payload === 'designation') state.designationTooltip = true
      else if (action.payload === 'proceed')
        state.proceedTooltip = [action.payload[0], true]
      else if (action.payload === 'editor') state.editorTooltip = true
      else if (action.payload === 'parse') state.parseTooltip = true
      else if (action.payload === 'naukriTooltip') state.jobPostTooltip = true
    },
    setSourceProfilesTooltip: (state, action) => {
      if (action.payload[0] === 'database')
        state.databaseTooltip = action.payload[1]
      else if (action.payload[0] === 'sourceAndTimeFilter')
        state.sourceAndTimeToolTip = action.payload[1]
      else if (action.payload[0] === 'locationFilter')
        state.locationToolTip = action.payload[1]
      else if (action.payload[0] === 'graduationYearFilter')
        state.graduationToolTip = action.payload[1]
      else if (action.payload[0] === 'cvFilter')
        state.cvToolTip = action.payload[1]
      else if (action.payload[0] === 'fetchResults')
        state.fetchResultToolTip = action.payload[1]
      else if (action.payload[0] === 'selectProfiles')
        state.selectProfilesToolTip = action.payload[1]
      else if (action.payload[0] === 'addToJD')
        state.addToJdToolTip = action.payload[1]
    },
    setIsAttachClicked: (state, action) => {
      state.isAttachClicked = action.payload
    },
  },
})

export const {
  setCurrentJDID,
  setSelectedNodeName,
  setUpdatedNodes,
  setIsJobPostClicked,
  setExcelTooltip,
  setIsParseJDClicked,
  setJobPostTooltip,
  setGetCurrentFlow,
  setAssignedTo,
  setTeamList,
  setSpLinkedinView,
  setSelectedFlow,
  setAddToJDClicked,
  setSaveProfilesClicked,
  setIsFetchClicked,
  setIsFilterClicked,
  setSourceProfilesTooltip,
  setContactTooltip,
  setSendsmsTooltip,
  setSendTooltip,
  setSubjectTooltip,
  setStatusTooltip,
  setTemplateTooltip,
  setLinkedinTooltip,
  setWhatsAppTooltip,
  setSmsTooltip,
  setMailTooltip,
  mailTooltip,
  setCheckboxTooltip,
  setIsAttachClicked,
  setCreateJDTooltip,
  clearTooltips,
  addNewFlow,
  updateFlowCurrentNode,
  clearFlow,
  updateNode,
  updateEdge,
  setSelectedNode,
  getNode,
  setNodes,
  setEditorTooltip,
  setAttachFileTooltip,
  setDesignationTooltip,
  setAssignedToFocusFlag,
  setAccorId,
  setSkillsFocusFlag,
  setProfilesFocusFlag,
  setProceedTooltip,
  setSaveJDFocusFlag,
  setIsProceedClicked,
  setValidationError,
  setCurrentStage,
  setNoErrorFlag,
  setParseTooltip,
  setSaveClicked,
  setCurrentNode,
  setTooltipSection,
  resetStates,
  setPostToGropJD,
  setCandidateDet,
  addProfile,
  removeProfile,
  resetProfiles,
} = workflowSlice.actions

export default workflowSlice.reducer
