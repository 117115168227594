export function checkDetails(data) {
  if (data?.details === null || data?.details === undefined) return false
  let details = data.details
  return JSON.stringify(details) !== JSON.stringify({})
}

export function checkSkills(data) {
  if (data?.skill === null || data?.skill === undefined) return false
  let details = data.skill
  return JSON.stringify(details) !== JSON.stringify({})
}

export function checkProjects(data) {
  if (data?.projects === null || data?.projects === undefined) return false
  if (data.projects.length === 0) return false
  let details = data.projects
  return JSON.stringify(details) !== JSON.stringify({})
}

export function checkWorks(data) {
  if (data?.projects === null || data?.projects === undefined) return false
  let details = data.projects
  return JSON.stringify(details) !== JSON.stringify({})
}
