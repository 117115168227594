import instance from './API'

export function sendWhatsapp(
  token,
  cv_ids,
  jdID,
  message,
  templateValue,
  statusID,
  message_id = 0,
) {
  console.log('sendWhatsapp', cv_ids, jdID, message, statusID, templateValue)
  const formData = new FormData()
  formData.append('jdID', jdID)
  formData.append('cv_ids', cv_ids)
  formData.append('message', message)
  formData.append('status_id', statusID)
  formData.append('template_id', templateValue)
  formData.append('chat_message_id', message_id)

  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
  }

  return instance.post('send/whatsapp/', formData, config)
}

export function sendContacts(token, contact_data) {
  const formData = new FormData()
  formData.append('contact_data', JSON.stringify(contact_data))

  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
  }

  return instance.post('phonebook/sendcontacts/', formData, config)
}

export function contactOut(linkedin, cid, jd_id) {
  const formData = new FormData()
  formData.append('linkedin', linkedin)
  formData.append('cid', cid)
  formData.append('jdID', jd_id)

  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  }

  return instance.post('jds/tasks/contact/', formData, config)
}
