// ** Logo
// import logo from '@src/assets/images/logo/logo.png'

// const logo = `@src/assets/images/logo/tenxlogo.png`;
import logo from '@src/assets/images/logo/tenxlogo.png'

const SpinnerComponent = () => {
  return (
    <div className="fallback-spinner app-loader">
      {/* <img className="fallback-logo" alt="logo111" /> */}
      <img
        className="fallback-logo"
        alt="logo"
        src={logo}
        style={{ width: '64px' }}
      />
      <div className="loading">
        <div className="effect-1 effects"></div>
        <div className="effect-2 effects"></div>
        <div className="effect-3 effects"></div>
      </div>
    </div>
  )
}

export default SpinnerComponent
