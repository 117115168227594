import DescriptionIcon from '@mui/icons-material/Description'
import SendIcon from '@mui/icons-material/Send'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import {
  adaptV4Theme,
  Avatar,
  Grid,
  Link,
  StyledEngineProvider,
  ThemeProvider,
  Tooltip,
} from '@mui/material'
import DuoIcon from '@mui/icons-material/Duo'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import EditIcon from '@mui/icons-material/Edit'
import CheckIcon from '@mui/icons-material/Check'
import React, { Fragment, useEffect, useState } from 'react'
import ScheduleMeeting from '../InterviewTabs/helper/ScheduleMeeting'
import { SendEmailDialog } from '../InterviewTabs/helper/SendEmailDialog'
import SendSMSDialog from '../InterviewTabs/helper/SendSMSDailoge'
import SendWhatsAppDialog from '../InterviewTabs/helper/sendWhatsappDialog'
import { createTheme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import withStyles from '@mui/styles/withStyles'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { blue, deepOrange, deepPurple } from '@mui/material/colors'
import { requestLinkedin, updateCandidateStatus } from '@src/api/CVDetailsApi'
import { getAppliedJobs, getCloudStatus, getProcessing } from './InfoHelper'
import history from '@src/history'
import { updateEmailPhoneCandidateCard } from '@src/api/JdCVMatch'
import ChangeCandidateStatus from './changeStatus'
import * as Icons from 'react-feather'
import Select from 'react-select'
import { useForm } from 'react-hook-form'
import * as api from '@src/api/JdHistoryApi'
import * as jdHistoryApi from '@src/api/JdHistoryApi'
import { toast } from 'react-toastify'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import UserAvatar from '@src/@core/components/user-avatar'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import {
  Button,
  Col,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Label,
  InputGroup,
  InputGroupText,
  Badge,
} from 'reactstrap'
import SmartToyIcon from '@mui/icons-material/SmartToy'
import AIHelper from '../InterviewTabs/helper/AIHelper'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  formControl: {
    //margin: theme.spacing(1),
    minWidth: '95%',
  },
  card: {
    radius: 30,
    backgroundColor: 'grey.200',
  },
  paper: {
    margin: 'auto',
    maxWidth: 550,
  },
  image: {
    width: 128,
    height: 128,
  },
  chip: {
    //margin: theme.spacing(0.1),
    //padding: theme.spacing(0.1),
  },
  large: {
    //width: theme.spacing(10),
    //height: theme.spacing(10),
    color: theme?.palette?.getContrastText(deepOrange[900]) ?? '#FFFFFF',
    backgroundColor: deepOrange[300],
  },
  social: {
    backgroundColor: '#e5e1e1',
  },
  purple: {
    color: theme?.palette?.getContrastText(deepPurple[500]) ?? '#FFFFFF',
    backgroundColor: deepPurple[500],
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
  },
}))

function CandidateDetailInfoCard(props) {
  const classes = useStyles()

  console.log('DEBUG: CandidateDetailsInfoCard: ', props)

  const defaultValues = {
    // lastName: '',
    // firstName: ''
  }

  // ** Hooks
  const {
    reset,
    control,
    setError,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues })

  let token = props.token
  let name = props.name
  let jdId = props.jdID
  //let phone1 = props.phone
  let email1 = props.email ?? props.userInfo[0]?.email
  let cvID = props.id
  let candidateData = props.data.data
  let journeys = props.data.journey
  let emailTemplates = props.emailTemplates
  console.log('DEBUG: CandidateDetailsInfoCard: ', props, email1)

  const [currentCV, setCurrentCV] = useState({})
  const [phone, setPhoneNumber] = React.useState()
  const [email, setEmail] = React.useState('')
  const [candidateStatus, setCandidateStatus] = useState({
    value: -1,
    label: 'Select Candidate Status',
  })

  useEffect(() => {
    if (candidateData?.length === 0) {
      setEmail(props?.userInfo[0]?.email)
      setPhoneNumber(props?.userInfo[0]?.phone_number)

      return
    }

    let cv = candidateData.filter((cv) => {
      return cv.jd_id?.toString().trim() === jdId?.toString().trim()
    })
    console.log('SDT:', candidateData, props, cv, props?.userInfo[0].email)
    if (cv?.length === 0) {
      return
    }

    setCurrentCV(cv[0])
    setEmail(props?.userInfo[0]?.email)
    setPhoneNumber(props?.userInfo[0]?.phone_number)
    // setEmail(cv[0].email ?? props?.userInfo[0].email)
    setCandidateStatus({
      value: cv[0].status_id,
      label: cv[0].status,
    })
    console.log('setCurrentCV', cv[0])
  }, [candidateData, props?.userInfo])

  console.log('DEBUG: CANIDATE DATA', currentCV, jdId, candidateData)

  const [params, setParams] = useState({
    jds: [],
  })

  console.log('JOURNEYS ', journeys)
  const [showSMS, setSMSModel] = useState(false)
  const [showEmail, setEmailModel] = useState(false)
  const [showMeeting, setMeetingModel] = useState(false)
  const [showWhatsapp, setWhatsapp] = useState(false)

  const handleSMS = (status) => setSMSModel(status)
  const handleEmail = (status) => setEmailModel(status)
  const handleMeeting = (status) => setMeetingModel(status)
  const handleWhatsapp = (status) => setWhatsapp(status)

  const [googleMeetData, setGoogleMeetData] = useState({
    isOpen: false,
    data: {
      c_id: '',
      jd_id: '',
    },
  })

  const [isEditable, setIsEditable] = React.useState(false)
  //candidate status change
  const [showCandidateStatusDialog, setCandidateStatusDialog] = useState(false)
  const [statusComments, setstatusComments] = useState('')

  const onCommentsChange = (e) => {
    let value = e.target.value
    setstatusComments(value)
  }

  const restoreStatus = (e) => {
    console.log('DEBUG: restoreStatus')
    setCandidateStatus({
      value: currentCV.status_id,
      label: currentCV.status,
    })
  }

  const handleCandidateStatusDialogClose = (e) => {
    setCandidateStatusDialog(false)
    restoreStatus()
  }

  //Fill the dropdowns with Jd names in create task tab
  function getJds() {
    api.getJdsForTask(props.token).then((res) => {
      if (res.data.Response === 'Ok') {
        console.log('Response: getJdsForTask: ', res.data.Data)
        setParams((prevState) => ({
          ...prevState,
          jds: res.data.Data,
        }))
      }
    })
  }

  const [openEmailDialog, setOpenEmailDialog] = useState(false)

  const handleCloseDialog = () => {
    setOpenEmailDialog(false)
  }

  const [isDialogOpen, setIsDialogOpen] = useState({
    open: false,
    source: '',
    jd_id: '',
    c_id: '',
  })

  useEffect(() => {
    getJds()
  }, [])

  const handleCandidateStatusChange = (e) => {
    console.log('handleCandidateStatusChange ', e)
    setCandidateStatus(e)
    setCandidateStatusDialog(true)
  }

  const handleUpdateStatus = () => {
    updateCandidateStatus(
      token,
      jdId,
      cvID,
      candidateStatus.value,
      statusComments,
    )
      .then((res) => {
        if (res.data.Response === 'Ok') {
          //clear comments
          setstatusComments('')
          setCandidateStatusDialog(false)
          toast.success('Status updated successfully')
          // update candidate
          setCurrentCV((prevState) => ({
            ...prevState,
            status_id: candidateStatus.id,
            status: candidateStatus.label,
          }))
        } else {
          toast.error('Something went wrong, please try again')
          restoreStatus()
        }
      })
      .catch((err) => {
        toast.error('Something went wrong, please try again')
        restoreStatus()
      })
  }

  let attendees = [
    {
      email: props.organizerEmail ?? '',
      displayName: props.organizerName ?? '',
    },
    {
      email: props.email ?? '',
      displayName: props.name ?? '',
    },
  ]

  const linkedinButtonTheme = createTheme(
    adaptV4Theme({
      palette: {
        primary: blue,
      },
    }),
  )

  const getLinkedinDetails = (e) => {
    let newData = []
    console.log('DEBUG, lets update linkedin', candidateData)
    candidateData.map((e) => {
      e.linkedin_details_request = 1
      newData.push(e)
    })

    props.setData((prev) => ({
      ...prev,
      data: newData,
    }))

    requestLinkedin(token, jdId, cvID).then((res) => {
      console.log('RequestedLinkedinStatus:', res)
    })
  }

  const openOCREditor = (e) => {
    let cloud_processing_id = candidateData[0].cloud_processing_id
    let ocr_labels_id = candidateData[0].ocr_labels_id
    history.push({
      pathname: '/rawedit/' + cloud_processing_id,
      id: cloud_processing_id,
      ocr_labels_id: ocr_labels_id,
    })
  }

  const onRawInputChange = (e) => {
    let key_name = e.target.name
    let value = e.target.value
    console.log('onRawInputChange', value, value.trim())
    if (key_name === 'phone') {
      setPhoneNumber(value)
    } else if (key_name === 'email') {
      setEmail(value)
    }
  }

  const [aiHelperData, setAiHelperData] = useState({
    isOpen: false,
    jdID: -1,
    cid: -1,
  })

  const handleAIHelper = (data) => {
    console.log('handleAIHelper', data)
    setAiHelperData(() => ({
      isOpen: true,
      jdID: data?.jd_id,
      cid: data?.cid,
    }))
  }

  function openCandidatesProfile(link) {
    console.log('Linkedin Link', link)
    if (link && link?.includes('linkedin')) {
      window.open(link, '_blank')
    } else {
      toast.error('Linkedin profile not available')
    }
  }
  const changeMode = (isEdit) => {
    if (isEdit) {
      setIsEditable(true)
    } else {
      setIsEditable(false)

      updateEmailPhoneCandidateCard(cvID, email, phone)
        .then((res) => {
          console.log('updateEmailPhoneCandidateCard', res)

          if (res.data.Response === 'Ok') {
            toast.success('Contact details updated successfully')
          } else {
            toast.error(
              'Contact details not updated successfully. Something went wrong, please try again',
            )
          }
        })
        .catch((e) => {
          toast.error(
            'Contact details not updated successfully. Something went wrong, please try again',
          )
        })

      // if (email === '')
      // {
      //   toast.error('Email cannot be empty. Please provide a valid email ');
      // }
      //     else
      // {

      //   updateEmailPhoneCandidateCard(cvID, email, phone)
      //     .then((res) =>
      //     {
      //       console.log('updateEmailPhoneCandidateCard', res)

      //       if (res.data.Response === 'Ok')
      //       {
      //         toast.success('Contact details updated successfully')
      //       } else
      //       {
      //         toast.error(
      //           'Contact details not updated successfully. Something went wrong, please try again',
      //         )
      //       }
      //     })
      //     .catch((e) =>
      //     {
      //       toast.error(
      //         'Contact details not updated successfully. Something went wrong, please try again',
      //       )
      //     })
      // }

      // const numberRegex = /^\d{10}$/;
      // const alphaRegex = /^[a-zA-Z!@~$%^&*()]+$/
      // if (phone === '')
      // {
      //     toast.error(
      //       'Phone number cannot be empty. Please provide a valid phone number ',
      //     )

      // }
      // else if (!numberRegex.test(phone))
      // {
      //   toast.error('The phone number must consist of numerical digits')
      //   return
      // }
      // else
      // {

      //   updateEmailPhoneCandidateCard(cvID, email, phone)
      //     .then((res) =>
      //     {
      //       console.log('updateEmailPhoneCandidateCard', res)

      //       if (res.data.Response === 'Ok')
      //       {
      //         toast.success('Contact details updated successfully')
      //       } else
      //       {
      //         toast.error(
      //           'Contact details not updated successfully. Something went wrong, please try again',
      //         )
      //       }
      //     })
      //     .catch((e) =>
      //     {
      //       toast.error(
      //         'Contact details not updated successfully. Something went wrong, please try again',
      //       )
      //     })
      // }
    }
  }

  //***********************************************************************
  // Handle Add To New Jd
  //***********************************************************************

  const [params1, setParams1] = useState({
    jdId_addNewJd: 0, //props?.jdsForTask[0][0],
    jdName_addNewJd: 0, //props?.jdsForTask[0][1],
    data: [],
  })

  const [showAddToNewJd, setAddToNewJd] = useState(false)
  const onDiscardAddToJd = () => {
    clearErrors()
    setAddToNewJd(false)
    reset()
  }

  const dropDownValues = {
    candidate_status: journeys,
    templates: emailTemplates,
  }

  // offer Letter
  const [offerDialog, setOfferDialog] = useState(false)

  const offer_dialog_open = () => {
    setOfferDialog(true)
  }
  const offer_dialog_close = () => {
    setOfferDialog(false)
  }

  const onSelectJdChange = (e) => {
    let txt = e.value //e.target.value
    const myArray = txt.split('~~~~')
    let jdId = myArray[0]
    let jdName = myArray[1]

    console.log(jdId + '----' + jdName)

    setParams1((prevState) => ({
      ...prevState,
      jdId_addNewJd: jdId,
      jdName_addNewJd: jdName,
    }))
  }

  const addToNewJd = () => {
    setAddToNewJd(true)
    // setToggleCleared(!toggleCleared);
  }

  const handleAddToNewJdSend = () => {
    let data = []

    data.push({ cv: cvID, jdid: jdId })

    console.log('DATA ', data)
    console.log('DATA ', params1.jdId_addNewJd)

    // console.log("SEND JD ",params.token,'--- ', params1.data, '----',params1.jdId_addNewJd)
    jdHistoryApi
      .addNewJd(params.token, data, params1.jdId_addNewJd)
      .then((res) => {
        console.log('RESP ', res)
        if (res.data.Response === 'Ok') {
          toast.success(res.data.Message)
        } else {
          toast.error(res.data.Message)
        }
        clearErrors()
        setAddToNewJd(false)
        reset()
      })
  }

  const handleGoogleMeet = () => {
    console.log('handleGoogleMeet', props)

    if (email !== '') {
      setGoogleMeetData(() => ({
        isOpen: true,
        data: {
          jd_id: props?.jdID,
          c_id: props?.id,
        },
      }))
    } else {
      toast.error('Please provide the email')
    }
  }

  console.log('CandidateProfileCard', currentCV)

  return (
    <div>
      <div className="card">
        <div className="card-body">
          <div className="d-flex flex-column justify-content-center align-items-center">
            <UserAvatar
              name={name}
              imageUrl={currentCV.image_url}
              sx={{ width: '5em', height: '5em' }}
            />

            <p className="font-medium-4 mt-1 mb-50 fw-bolder opacity-75">
              {name}
            </p>
            <p className="font-medium-1 fw-bolder opacity-50 mb-0">
              Last Updated - {getProcessing(currentCV.updated_at)}
            </p>
          </div>
          <div className="d-flex align-content-center justify-content-center flex-wrap my-1">
            <div className="mb-1">
              <Avatar
                className={'bg-primary'}
                onClick={handleGoogleMeet}
                style={{ height: '1.6em', width: '1.6em', padding: '.5em' }}
              >
                <DuoIcon fontSize="20" />
              </Avatar>
            </div>
            <div className="mx-1 mb-1">
              <Avatar
                className={'bg-primary'}
                onClick={(e) => {
                  if (email !== '') {
                    setOpenEmailDialog(true)
                  } else {
                    toast.error(
                      'Please provide the email address and try again',
                    )
                  }
                }}
                style={{ height: '1.6em', width: '1.6em', padding: '.5em' }}
              >
                <Link>
                  <Icons.Mail fontSize="20" />
                </Link>
              </Avatar>
            </div>
            <div className="mb-1">
              <Avatar
                className={'bg-primary'}
                onClick={(e) => {
                  if (phone !== '') {
                    handleSMS(true)
                  } else {
                    toast.error(
                      'Please provide the phone number and try again ',
                    )
                  }
                }}
                style={{ height: '1.6em', width: '1.6em', padding: '.5em' }}
              >
                <Link>
                  <Icons.MessageCircle fontSize="20" />
                </Link>
              </Avatar>
            </div>
            <div className="mx-1 mb-1">
              <Avatar
                className={'bg-primary'}
                onClick={(e) => {
                  if (phone !== '') {
                    setIsDialogOpen(() => ({
                      open: true,
                      jd_id: jdId,
                      c_id: cvID,
                    }))
                  } else {
                    toast.error('Please provide the phone number and try again')
                  }
                }}
                style={{ height: '1.6em', width: '1.6em', padding: '.5em' }}
              >
                <Link>
                  <WhatsAppIcon fontSize="20" />
                </Link>
              </Avatar>
            </div>
            {currentCV.social_url &&
              currentCV.social_media_name != 'NAUKRI' && (
                <div className="mb-1">
                  <Avatar
                    className={'bg-primary'}
                    // className={"opacity-75"}

                    onClick={() => {
                      openCandidatesProfile(currentCV.social_url)
                    }}
                    style={{ height: '1.6em', width: '1.6em' }}
                  >
                    <Link>
                      <LinkedInIcon />
                    </Link>
                  </Avatar>
                </div>
              )}

            <div className="mb-1 ms-1">
              <Avatar
                className={'bg-primary'}
                // className={"opacity-75"}

                onClick={() => {
                  handleAIHelper(currentCV)
                }}
                style={{ height: '1.6em', width: '1.6em' }}
              >
                <Link>
                  <SmartToyIcon fontSize="small" />
                </Link>
              </Avatar>
            </div>
          </div>
          <hr style={{ height: '.2em' }} />
          <div>
            <h5 className="fw-bolder opacity-75"> Candidate Status</h5>
            <Select
              labelId="label"
              id="demo-simple-select"
              className="react-select"
              classNamePrefix="select"
              onChange={handleCandidateStatusChange}
              //check
              value={candidateStatus}
              options={journeys.map((e) => {
                return { value: e.id, label: e.value }
              })}
            />
          </div>

          <div className="my-2">
            <div>
              <ThemeProvider theme={linkedinButtonTheme}>
                <Button
                  block
                  variant="contained"
                  color="primary"
                  disableElevation
                  onClick={offer_dialog_open}
                >
                  <DescriptionIcon className="me-1" /> Generate Offer Letter
                </Button>
              </ThemeProvider>
            </div>

            <div className="my-1">
              <ThemeProvider theme={linkedinButtonTheme}>
                <Button
                  block
                  variant="contained"
                  color="primary"
                  className={classes.margin}
                  disableElevation
                  onClick={addToNewJd}
                >
                  Add To New JD
                </Button>
              </ThemeProvider>
            </div>
            <div>
              {currentCV.social_media_name
                ?.toUpperCase()
                .includes('LINKEDIN') && (
                <div>
                  <div className="ml-3 mr-5 border-radius-12">
                    {currentCV.linkedin_requested === 0 && (
                      <StyledEngineProvider injectFirst>
                        <ThemeProvider theme={linkedinButtonTheme}>
                          <Button
                            block
                            variant="contained"
                            color="primary"
                            className={classes.margin}
                            disableElevation
                            onClick={getLinkedinDetails}
                          >
                            Fetch Contact Details
                          </Button>
                        </ThemeProvider>
                      </StyledEngineProvider>
                    )}
                    {currentCV.linkedin_requested === 1 && (
                      <StyledEngineProvider injectFirst>
                        <ThemeProvider theme={linkedinButtonTheme}>
                          <Button
                            block
                            variant="contained"
                            color="primary"
                            className={classes.margin}
                            fullWidth
                            onClick={(e) => {
                              toast.error(
                                "Already requested, please wait a while. Usually it's take sometimes",
                              )
                            }}
                            disableElevation
                          >
                            Contact Details: Requested
                          </Button>
                        </ThemeProvider>
                      </StyledEngineProvider>
                    )}
                    {currentCV === 2 && (
                      <StyledEngineProvider injectFirst>
                        <ThemeProvider theme={linkedinButtonTheme}>
                          <Button
                            block
                            variant="contained"
                            color="primary"
                            className={classes.margin}
                            fullWidth
                            onClick={(e) => {
                              toast.error(
                                'Linkedin Contacted details already updated',
                              )
                            }}
                            disableElevation
                          >
                            Contact Details: Updated
                          </Button>
                        </ThemeProvider>
                      </StyledEngineProvider>
                    )}

                    {currentCV === 3 && (
                      <StyledEngineProvider injectFirst>
                        <ThemeProvider theme={linkedinButtonTheme}>
                          <Button
                            block
                            variant="contained"
                            color="primary"
                            className={classes.margin}
                            onClick={(e) => {
                              toast.error(
                                'Linkedin Contacted details not found',
                              )
                            }}
                            disableElevation
                          >
                            CONTACT DETAILS: NOT FOUND
                          </Button>
                        </ThemeProvider>
                      </StyledEngineProvider>
                    )}
                  </div>
                </div>
              )}
            </div>

            <div>
              {getCloudStatus(candidateData) && (
                <div>
                  <div>
                    <hr />
                  </div>

                  <div className="ml-3 mr-5 border-radius-12">
                    <StyledEngineProvider injectFirst>
                      <ThemeProvider theme={linkedinButtonTheme}>
                        <Button
                          variant="contained"
                          color="primary"
                          className={classes.margin}
                          fullWidth
                          onClick={openOCREditor}
                        >
                          OPEN OCR EDITOR
                        </Button>
                      </ThemeProvider>
                    </StyledEngineProvider>
                  </div>
                </div>
              )}
            </div>
          </div>

          <hr style={{ height: '.2em' }} />

          <div className="d-flex justify-content-between align-items-center mb-1">
            <h5 className="fw-bolder opacity-75 mb-0">Contact Details </h5>
            {isEditable ? (
              <Link
                className="cursor-pointer"
                onClick={(e) => changeMode(false)}
              >
                <CheckIcon className="opacity-75" fontSize={'15'} />
              </Link>
            ) : (
              <Link
                className="cursor-pointer"
                onClick={(e) => changeMode(true)}
              >
                <EditIcon className="opacity-75" fontSize={'15'} />
              </Link>
            )}
          </div>

          <Row>
            <div className="mb-2">
              <InputGroup>
                <InputGroupText
                  style={{
                    backgroundColor: isEditable ? ' #fff' : '#efefef',
                  }}
                >
                  <Icons.Mail aria-disabled />
                </InputGroupText>
                <Input
                  type="email"
                  className={classes.field}
                  placeholder="email"
                  variant="outlined"
                  name={'email'}
                  onChange={onRawInputChange}
                  value={email}
                  disabled={!isEditable}
                />
              </InputGroup>
            </div>
          </Row>

          <div>
            <InputGroup>
              <InputGroupText
                style={{ backgroundColor: isEditable ? ' #fff' : '#efefef' }}
              >
                <Icons.Phone />
              </InputGroupText>

              <Input
                className={classes.field}
                placeholder="phone"
                variant="outlined"
                type="text"
                name={'phone'}
                onChange={onRawInputChange}
                value={phone}
                disabled={!isEditable}
              />
            </InputGroup>
          </div>

          <hr style={{ height: '.2em' }} />

          {getAppliedJobs(candidateData)?.length !== 0 && (
            <div>
              <h5 className="fw-bolder opacity-75"> Applied Jobs</h5>

              {getAppliedJobs(candidateData).map((e) => {
                return (
                  <div className="px-1 py-1 mb-2 bg-light-primary">
                    <Badge
                      color="light-primary"
                      className="me-1 border mb-50"
                      pill
                    >
                      <b>JD ID</b> - {e.id}
                    </Badge>

                    {/* <Badge
                      color="light-primary"
                      className="me-1 border mb-50"
                      pill
                    >
                      <b>JD Name</b> -
                      {<Tooltip title={e.name} placement="top-start" arrow>
                        {' '}
                        {e.name.slice(0, 30).concat('...')}
                      </Tooltip> ? (
                        e.name.slice(0, 30).concat('...')
                      ) : (
                        e.name
                      )}
                    </Badge> */}
                    <Badge
                      color="light-primary"
                      className="me-1 border mb-50"
                      pill
                    >
                      <b>JD Name</b> -{' '}
                      {e.name?.length > 30 ? (
                        <Tooltip title={e.name} placement="top-start" arrow>
                          {e.name.slice(0, 30).concat('...')}
                        </Tooltip>
                      ) : (
                        e.name
                      )}
                    </Badge>

                    <Badge
                      color="light-primary"
                      className="me-1 border mb-50"
                      pill
                    >
                      <b>JD Role</b> - {e.role}
                    </Badge>
                  </div>
                )
              })}
            </div>
          )}

          {/*
           ********************************* Add To New JD *******************************************
           */}

          <Fragment>
            <Modal
              isOpen={showAddToNewJd}
              onClosed={onDiscardAddToJd}
              toggle={() => setAddToNewJd(!showAddToNewJd)}
              className="modal-dialog-centered "
            >
              <ModalHeader
                className="bg-transparent"
                toggle={() => setAddToNewJd(!showAddToNewJd)}
              ></ModalHeader>
              <ModalBody className="px-sm-5 mx-50 pb-5">
                <h1 className="text-center mb-1">Add To New Jd</h1>
                <Row className="gy-1 gx-2 mt-75">
                  <Col xs={12}>
                    <Select
                      labelId="demo-select-small"
                      id="demo-simple-select"
                      size="small"
                      className="react-select"
                      classNamePrefix="select"
                      options={params.jds?.map((item) => {
                        return {
                          value: item.jd_id + '~~~~' + item.jd_name,
                          label: item.jd_name,
                        }
                      })}
                      styles={{
                        color: 'white',
                      }}
                      variant={'outlined'}
                      disableUnderline
                      onChange={(e) => onSelectJdChange(e)}
                    ></Select>
                  </Col>
                </Row>
                <Row className="gy-1 gx-2">
                  <Col className="text-center" xs={12}>
                    <Button
                      className="me-1 mt-2"
                      color="primary"
                      onClick={handleAddToNewJdSend}
                    >
                      Submit
                    </Button>
                    <Button
                      type="reset"
                      className="mt-2"
                      color="secondary"
                      outline
                      onClick={onDiscardAddToJd}
                    >
                      Discard
                    </Button>
                  </Col>
                </Row>
              </ModalBody>
            </Modal>
          </Fragment>
        </div>
      </div>
      <div className="my-5">
        {/****************************** SEND EMAIL Dialog **************************************/}

        <SendEmailDialog
          isOpen={openEmailDialog}
          handleClose={handleCloseDialog}
          data={currentCV}
          options={dropDownValues}
          classes={classes}
          handleSend=""
        />

        {/****************************** Schedule Meeting **************************************/}
        <ScheduleMeeting
          isOpen={googleMeetData?.isOpen}
          data={googleMeetData?.data}
          isClose={setGoogleMeetData}
        />

        {/****************************** SEND SMS Dialog **************************************/}
        <SendSMSDialog
          isOpen={showSMS}
          handleClose={(e) => handleSMS(false)}
          data={currentCV}
          options={journeys}
        />

        {/*
         ********************************* AIHelper Dialog *******************************************
         */}

        <AIHelper
          isOpen={aiHelperData?.isOpen}
          jdID={aiHelperData?.jdID}
          cid={aiHelperData?.cid}
          isClose={setAiHelperData}
        />
        {/*
         ********************************* Whatsapp *******************************************
         */}

        <SendWhatsAppDialog
          isOpen={isDialogOpen?.open}
          isClose={setIsDialogOpen}
          data={isDialogOpen}
        />
      </div>
      <ChangeCandidateStatus
        showCandidateStatusDialog={showCandidateStatusDialog}
        handleClose={handleCandidateStatusDialogClose}
        journeys={journeys}
        data={currentCV}
        statusComments={statusComments}
        onCommentsChange={onCommentsChange}
        statusID={candidateStatus}
        handleUpdateStatus={handleUpdateStatus}
      />

      {/****************************** Offer Letter Dialog **************************************/}
      <div>
        <Dialog
          open={offerDialog}
          // TransitionComponent={Transition}
          // keepMounted
          fullWidth
          maxWidth="md"
          onClose={offer_dialog_close}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>Offer Letter</DialogTitle>
          <DialogContent>
            <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4">
                <Label for="skills">Choose Template</Label>
                <Select
                  className="react-select"
                  classNamePrefix="select"
                ></Select>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4">
                <Label for="from">From</Label>
                <Input placeholder={'From'} name="from" id="from" />
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4">
                <Label for="to">To</Label>
                <Input placeholder={'To'} name="to" id="to" />
              </div>
            </div>

            <div className="row mt-1">
              <div className="col-12">
                <Label for="content">Content</Label>
                <Input
                  type="textarea"
                  name="text"
                  rows="4"
                  id="content"
                  placeholder="content"
                  className="auto"
                />
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={offer_dialog_close} outline color="primary">
              <HighlightOffIcon fontSize="small" className="me-1" /> Close
            </Button>
            <Button onClick={offer_dialog_close} color="primary">
              <SendIcon fontSize="small" className="me-1" /> Send Email
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  token: state.LoginReducers.token,
  uuid: state.LoginReducers.uuid,
  organizerEmail: state.LoginReducers.email,
  organizerName: state.LoginReducers.fullname,
})

const mapDispatchToProps = (dispatch) => ({})

export default withStyles(
  {},
  { withTheme: true },
)(
  withRouter(
    connect(mapStateToProps, mapDispatchToProps)(CandidateDetailInfoCard),
  ),
)
