import { createSlice } from '@reduxjs/toolkit'

// {
//     "shudipto": {
//     {
//         "public": {
//             "show": true,
//             dialogCount: 0,
//             count: 20
//         },
//          "recruiter": {
//             "show": true,
//             dialogCount: 0,
//             count: 20
//         },
//     date: "2021-01-01",
//     }
// }
// }

// connection_request:0
// created_at:"2023-07-12T05:53:54.117589-05:00"
// id:107
// inmail:0
// leads:0
// message:0
// name:""
// public_profile:1
// recruiter_profile:0
// status:1
// unique_identifier:"iamsdt"
// updated_at:"2023-07-12T05:53:54.120169-05:00"
// user:28

function getNewStatus(newCount, dialogCount, limit = 100) {
  // logic
  // if old count zero and new is 80 or more, than show
  // but if old count is 80 or more and new is less than 90, don't change status
  // if old count less than 90 and new count 90, then show
  // if old count 90 and new count 90 to 95, then don't change status
  // again change in 95 and then after 100 show everything

  console.log('WEBSOCKET', 'getNewStatus', newCount, dialogCount, limit)

  const percentage = newCount / limit

  if (
    percentage >= 1 ||
    percentage == 0.8 ||
    percentage == 0.85 ||
    percentage == 0.9 ||
    percentage == 0.95 ||
    percentage == 0.99
  ) {
    return false
  } else {
    return true
  }

  //   if (percentage >= 1) {
  //     return false;
  //   } else if (percentage >= 0.95 && percentage < 1) {
  //     return dialogCount >= 3;
  //   } else if (percentage >= 0.9 && percentage < 0.95) {
  //     console.log("WEBSOCKET", "90 to 95");
  //     return dialogCount >= 2;
  //   } else if (percentage >= 0.8 && percentage < 0.9) {
  //     return dialogCount >= 1;
  //   } else if (percentage >= 0.8) {
  //     return dialogCount === 0;
  //   } else {
  //     return true;
  //   }
}

const getDialogCount = (value, limit = 100) => {
  let ninety = limit * 0.9
  let ninetyFive = limit * 0.95
  if (value < ninety) {
    return 0
  } else if (value < ninetyFive) {
    return 1
  } else if (value < limit) {
    return 2
  } else {
    0
  }
}

function getInitialData(dateOnly, data) {
  let public_profile = data.public_profile ?? 0
  let recruiter_profile = data.recruiter_profile ?? 0
  let connection_request = data.connection_request ?? 0
  let message = data.message ?? 0
  let inmail = data.inmail ?? 0
  let leads = data.leads ?? 0

  let stats = {}
  stats['date'] = dateOnly
  // handle public profile
  stats['public_profile'] = {
    show: public_profile < 80,
    dialogCount: getDialogCount(public_profile),
    count: public_profile,
  }
  // handle recruiter profile
  stats['recruiter_profile'] = {
    show: (recruiter_profile / 2000) * 100 < 80,
    dialogCount: getDialogCount(recruiter_profile, 2000),
    count: recruiter_profile,
  }
  // handle connection request
  stats['connection_request'] = {
    show: connection_request < 80,
    dialogCount: getDialogCount(connection_request),
    count: connection_request,
  }
  // handle message
  stats['message'] = {
    show: message < 80,
    dialogCount: getDialogCount(message),
    count: message,
  }
  // handle inmail
  stats['inmail'] = {
    show: inmail < 80,
    dialogCount: getDialogCount(inmail),
    count: inmail,
  }

  stats['leads'] = {
    show: (leads / 4000) * 100 < 80,
    dialogCount: getDialogCount(leads, 4000),
    count: leads,
  }
  return stats
}

export function getLinkedinStatsMessage(key, count) {
  console.log('WEBSOCKET: getLinkedinStatsMessage', key, count)
  switch (key) {
    case 'public_profile':
      if (count < 80) {
        console.log('WEBSOCKET: getLinkedinStatsMessage If', key, count)
        return `You have ${count} new public profile views today`
      } else if (
        count == 80 ||
        count == 85 ||
        count == 90 ||
        count == 95 ||
        count == 99
      ) {
        console.log('WEBSOCKET: getLinkedinStatsMessage Else If 1', key, count)
        return `You have ${count} public profile views today. ${
          100 - count
        } more views left for the day.`
      } else if (count == 100) {
        console.log('WEBSOCKET: getLinkedinStatsMessage Else If 2', key, count)
        return `You have ${count} public profile views today. Please stop using this account today.`
      } else if (count > 100) {
        console.log('WEBSOCKET: getLinkedinStatsMessage Else If 3', key, count)
        return `You already reached the limit. Stop immediately using this account.`
      }
      break
    case 'recruiter_profile':
      if ((count / 2000) * 100 < 80) {
        return `You have ${count} new recruiter profile views today`
      } else if (
        (count / 2000) * 100 == 80 ||
        (count / 2000) * 100 == 85 ||
        (count / 2000) * 100 == 90 ||
        (count / 2000) * 100 == 95 ||
        (count / 2000) * 100 == 99
      ) {
        return `You have ${count} recruiter profile views today. ${
          1500 - count
        } more recruiter profile views left for the day.`
      } else if ((count / 2000) * 100 == 100) {
        return `You have ${count} recruiter profile views today. Please stop using this account today.`
      } else if ((count / 2000) * 100 > 100) {
        return `You already reached the limit. Stop immediately using this account.`
      }
      break
    case 'connection_request':
      return count < 95
        ? `You have sent ${count} connection requests today`
        : `You have sent ${count} connection requests today. Please stop using this account for today.`
    case 'message':
      return count < 95
        ? `You have sent ${count} messages today`
        : `You have sent ${count} messages today. Please stop using this account for today.`
    case 'inmail':
      return count < 95
        ? `You have sent ${count} inmails today`
        : `You have sent ${count} inmails today. Please stop using this account for today.`
    case 'leads':
      if ((count / 4000) * 100 < 80) {
        return `You have added ${count} leads today`
      } else if (
        (count / 4000) * 100 == 80 ||
        (count / 4000) * 100 == 85 ||
        (count / 4000) * 100 == 90 ||
        (count / 4000) * 100 == 95 ||
        (count / 4000) * 100 == 99
      ) {
        return `You have added ${count} leads today. ${
          4000 - count
        } more leads left for the day.`
      } else if ((count / 4000) * 100 == 100) {
        return `You have added ${count} leads today. Please stop using this account today.`
      } else if ((count / 4000) * 100 > 100) {
        return `You already reached the limit. Stop immediately using this account.`
      }
      break
    // return (count / 1500) * 100 < 95 ? `You have added ${count} leads today` : `You have added ${count} leads today. Please stop using this account for today.`;
    default:
      return key + ' ' + count
  }
  //   if (key === "public_profile") {
  //     return count < 95 ? `You have ${count} new public profile views today` : `You have ${count} public profile views today. Please stop using this account for today.`;
  //   } else if (key === "recruiter_profile") {
  //     return (count / 1500) * 100 < 95 ? `You have ${count} new recruiter profile views today` : `You have ${count} recruiter profile views today. Please stop using this account for today.`;
  //   } else if (key === "connection_request") {
  //     return count < 95 ? `You have sent ${count} connection requests today` : `You have sent ${count} connection requests today. Please stop using this account for today.`;
  //   } else if (key === "message") {
  //     return count < 95 ? `You have sent ${count} messages today` : `You have sent ${count} messages today. Please stop using this account for today.`;
  //   } else if (key === "inmail") {
  //     return count < 95 ? `You have sent ${count} inmails today` : `You have sent ${count} inmails today. Please stop using this account for today.`;
  //   } else if (key === "leads") {
  //     return (count / 1500) * 100 < 95 ? `You have added ${count} leads today` : `You have added ${count} leads today. Please stop using this account for today.`;
  //   } else {
  //     return key + " " + count;
  //   }
}

export function getLatestData(data, storeStats) {
  console.log('WEBSOCKET', data, storeStats)
  let timestamp = data.created_at
  let dateOnly = new Date(timestamp).toLocaleDateString('en-US')
  let keys = Object.keys(storeStats)
  if (keys.length === 0) {
    console.log('WEBSOCKET', 'getting initial data')
    return getInitialData(dateOnly, data)
  }
  let stats = { ...storeStats }
  let public_profile = data.public_profile ?? 0
  let recruiter_profile = data.recruiter_profile ?? 0
  let connection_request = data.connection_request ?? 0
  let message = data.message ?? 0
  let inmail = data.inmail ?? 0
  let leads = data.leads ?? 0

  // compare data, if its today then update
  // else create new one
  if (stats.date === dateOnly) {
    // handle public profile
    stats['public_profile'] = {
      count: public_profile,
      show: getNewStatus(
        public_profile,
        stats['public_profile']?.['dialogCount'] ?? 0,
      ),
      dialogCount: stats['public_profile']?.['dialogCount'] ?? 0,
    }
    // handle recruiter profile
    stats['recruiter_profile'] = {
      count: recruiter_profile,
      show: getNewStatus(
        recruiter_profile,
        stats['recruiter_profile']?.['dialogCount'] ?? 0,
        2000,
      ),
      dialogCount: stats['recruiter_profile']?.['dialogCount'] ?? 0,
    }
    // handle connection request
    stats['connection_request'] = {
      count: connection_request,
      show: getNewStatus(
        connection_request,
        stats['connection_request']['dialogCount'],
      ),
      dialogCount: stats['connection_request']?.['dialogCount'] ?? 0,
    }
    // handle message
    stats['message'] = {
      count: message,
      show: getNewStatus(message, stats['message']?.['dialogCount'] ?? 0),
      dialogCount: stats['message']?.['dialogCount'] ?? 0,
    }
    // handle inmail
    stats['inmail'] = {
      count: inmail,
      show: getNewStatus(inmail, stats['inmail']?.['dialogCount'] ?? 0),
      dialogCount: stats['inmail']?.['dialogCount'] ?? 0,
    }
    stats['leads'] = {
      count: leads,
      show: getNewStatus(leads, stats['leads']?.['dialogCount'] ?? 0, 4000),
      dialogCount: stats['leads']?.['dialogCount'] ?? 0,
    }
    // update date
    stats['date'] = dateOnly
    console.log('WEBSOCKET', 'updating data', stats)
    return stats
  } else {
    // create new one
    return getInitialData(dateOnly, data)
  }
}

const linkedinStatsReducer = createSlice({
  name: 'linkedinStatsNotification',
  initialState: {
    stats: {},
  },
  reducers: {
    // saveNotification: (state, action) => {
    //     let data = action.payload
    //     let uniqueId = data.uniqueId
    //     let timestamp = data.created_at
    //     let dateOnly = new Date(timestamp).toLocaleDateString('en-US')
    //
    //     // *******************************
    //     // check already exits or not
    //     // *******************************
    //     let keys = Object.keys(state.stats)
    //     if (!keys.includes(uniqueId)) {
    //         state.stats[uniqueId] = getInitialData(dateOnly, data)
    //         return
    //     }
    //     // *******************************
    //     // update existing data
    //     // *******************************
    //     let stats = state.stats[uniqueId]
    //     // compare data, if its today then update
    //     // else create new one
    //     if (stats.date === dateOnly) {
    //         // handle public profile
    //         stats['public'] = {
    //             count: data.public_profile,
    //             show: getNewStatus(data.public_profile, stats['public']['dialogCount']),
    //             dialogCount: stats['public']['dialogCount']
    //         }
    //         // handle recruiter profile
    //         stats['recruiter'] = {
    //             count: data.recruiter_profile,
    //             show: getNewStatus(data.recruiter_profile, stats['recruiter']['dialogCount']),
    //             dialogCount: stats['recruiter']['dialogCount']
    //         }
    //         // handle connection request
    //         stats['connection'] = {
    //             count: data.connection_request,
    //             show: getNewStatus(data.connection_request, stats['connection']['dialogCount']),
    //             dialogCount: stats['connection']['dialogCount']
    //         }
    //         // handle message
    //         stats['message'] = {
    //             count: data.message,
    //             show: getNewStatus(data.message, stats['message']['dialogCount']),
    //             dialogCount: stats['message']['dialogCount']
    //         }
    //         // handle inmail
    //         stats['inmail'] = {
    //             count: data.inmail,
    //             show: getNewStatus(data.inmail, stats['inmail']['dialogCount']),
    //             dialogCount: stats['inmail']['dialogCount']
    //         }
    //         // update date
    //         stats['date'] = dateOnly
    //     } else {
    //         // create new one
    //         state.stats[uniqueId] = getInitialData(dateOnly, data)
    //     }
    // }
    saveNotification: (state, action) => {
      let uniqueId = action.payload.uniqueId
      state.stats[uniqueId] = action.payload.data
    },
  },
})

export const { saveNotification } = linkedinStatsReducer.actions

export default linkedinStatsReducer.reducer
