import React, { useState } from 'react'
import Avatar from '@mui/material/Avatar'
import { green, pink } from '@mui/material/colors'

const UserAvatar = ({ name, imageUrl, sx }) => {
  const [imageError, setImageError] = useState(false)

  const handleImageError = () => {
    setImageError(true)
  }

  console.log('*****DEBUG: UserAvatar: ', name, sx)

  return (
    <Avatar
      onError={handleImageError}
      src={imageError ? undefined : imageUrl}
      alt={name}
      sx={sx ?? { bgcolor: green[500] }}
    >
      {imageError ? name?.slice(0, 2).toUpperCase() : null}
    </Avatar>
  )
}

export default UserAvatar
