import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import * as resumeApi from '@src/api/CvApi'

export const fetchFolders = createAsyncThunk(
  'resume/folders',
  async ({ token, bulk_id, offset, limit }) => {
    const response = await resumeApi.getRawCV(token, bulk_id, offset, limit)
    console.log('response__res', response)
    return response.data?.Data
  },
)

export const seletedCandidates = createAsyncThunk(
  'resume/selectedFolder',
  async ({ cid }) => {
    const response = await resumeApi.getCandidateCard(cid)
    return response.data?.Data
  },
)

export const editCandidates = createAsyncThunk(
  'resume/editCandidate',
  async ({ cid }) => {},
)

export const searchChat = createAsyncThunk(
  'inmail/searchChat',
  async (bulk_id, query) => {
    const response = await api.getSearchChat(bulk_id, query, offset, limit)
    return response.data
  },
)

export const processResume = createSlice({
  name: 'processResume',
  initialState: {
    selectedFolderId: 0,
    selectedCandidateDetails: [],
    listOfFolder: [],
  },
  reducers: {
    setSelectedCandidateDetails: (state, action) => {
      state.selectedCandidateDetails = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFolders.fulfilled, (state, action) => {
        state.listOfFolder = action.payload
      })
      .addCase(fetchFolders.rejected, (state, action) => {
        state.listOfFolder = []
      })
      .addCase(seletedCandidates.fulfilled, (state, action) => {
        state.selectedCandidateDetails = action.payload
      })
      .addCase(seletedCandidates.rejected, (state, action) => {
        state.selectedCandidateDetails = []
      })
      .addCase(searchChat.fulfilled, (state, action) => {
        state.listOfFolder = action.payload.Data
        console.log('SEARCH CHAT ', state, ' ----- ', action.payload.Data)
      })
  },
})

export default processResume.reducer

export const { setSelectedCandidateDetails } = processResume.actions
