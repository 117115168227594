// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import * as api from '@src/api/ChatApi'

// ** Axios Imports
import axios from 'axios'

//@todo: Data Hardcoded
let token = '20f439a6a385c8b875cc877806bedb04b2a46dad'
let offset = 0
let limit = 100
let channel = 'SMS'

export const getUserProfile = createAsyncThunk('appSMS/getTasks', async () => {
  const response = await axios.get('/apps/sms/users/profile-user')
  return response.data
})

export const getChatContacts = createAsyncThunk(
  'appSMS/getChatContacts',
  async (jdID) => {
    const response = await api.getChatAll(jdID, offset, limit, channel)
    console.log('CHAT RES ', response)
    return response.data
  },
)

export const searchChat = createAsyncThunk(
  'appSMS/searchChat',
  async (query) => {
    const response = await api.getSearchChat(query, offset, limit, channel)
    return response.data
  },
)

export const selectChat = createAsyncThunk(
  'appSMS/selectChat',
  async (item, { dispatch }) => {
    const response = await api.getChatDetails(item.id, offset, limit, channel) //id is ChatId
    console.log('CHAT DETAILS RES ', response, ' ---- ', item)
    return response.data
  },
)

export const sendMsg = createAsyncThunk(
  'appSMS/sendMsg',
  async (obj, { dispatch }) => {
    // const response = await axios.post('/apps/chat/send-msg', { obj })
    // await dispatch(selectChat(obj.contact.id))
    // return response.data
    const chatId = obj.Data[0].chat
    const response = await api.postSendMessage(chatId, obj.message, channel)
    await dispatch(selectChat(chatId)) //obj.message
    console.log('SEND MSG RES ', response)
    return response.data
  },
)

export const setActiveChat = createAsyncThunk(
  'appSMS/activeChat',
  async (chat, { dispatch }) => {
    return chat
  },
)

export const appSMSSlice = createSlice({
  name: 'appSMS',
  initialState: {
    chats: [],
    contacts: [],
    userProfile: {},
    selectedUser: {},
    selectedContact: {},
    activeChat: {},
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUserProfile.fulfilled, (state, action) => {
        state.userProfile = action.payload
      })
      .addCase(getChatContacts.fulfilled, (state, action) => {
        console.log('GET CHAT CONTACTS PAYLOAD ', action.payload)
        state.chats = action.payload.Data
        //state.chats = action.payload.chatsContacts
        state.contacts = action.payload.contacts
        state.selectedContact = {}
        state.activeChat = {}
      })
      .addCase(searchChat.fulfilled, (state, action) => {
        state.chats = action.payload.Data
        console.log('SEARCH CHAT ', state, ' ----- ', action.payload.Data)
      })
      .addCase(selectChat.fulfilled, (state, action) => {
        console.log('SELECT CHAT PAYLOAD ', action.payload)
        state.selectedUser = action.payload
      })
      .addCase(setActiveChat.fulfilled, (state, action) => {
        console.log('SET ACTIVE CHAT----')
        state.activeChat = action.payload
      })
  },
})

export default appSMSSlice.reducer
