import axios from 'axios'
import instance from './API'

//https://testserver.10xscale.in/api/chat/all?jdID=51&offset=0&limit=100

export function getChatAll(jdID, offSet, limit, channel) {
  const config = {
    headers: {
      // 'Authorization': "Token " + apptoken,
      'Content-Type': 'application/json',
    },
    params: {
      jdID,
      offset: offSet,
      limit,
      channel,
    },
  }

  return instance.get('chat/all/', config)
}

export function downLoadFileInChat(chat_id) {
  const config = {
    params: {
      chat_id,
    },
    responseType: 'blob',
  }
  return instance.get('file/download/', config)
}

export function getSearchChat(query, offSet, limit, channel, jdID = 'all') {
  console.log('getSearchChat', query, offSet, limit, channel, jdID)
  const config = {
    params: {
      query,
      offset: offSet,
      limit,
      channel,
      jdID,
    },
  }

  return instance.get('chat/search/', config)
}

export function getChatDetails(cid, offSet, limit, channel = 'whatsapp') {
  const config = {
    params: {
      cid,
      offset: offSet,
      limit,
      channel,
    },
  }

  return instance.get('chat/details/', config)
}

export function postSendMessage(
  cid,
  jdID,
  template_id,
  channel,
  subject,
  tags,
) {
  const formData = new FormData()
  formData.append('cid', cid)
  formData.append('jdID', jdID)
  formData.append('template_id', template_id)
  formData.append('channel', channel)
  formData.append('subject', subject)
  formData.append('tags', tags)

  return instance.post('chat/chat/', formData, {})
}

export function postSendWhatsppTemplateMsg(
  jdID,
  cv_ids,
  message,
  statusID,
  templateValue,
) {
  console.log(
    'API_postSendWhatsppTemplateMsg',
    jdID,
    cv_ids,
    message,
    statusID,
    templateValue,
  )

  const formData = new FormData()
  formData.append('jdID', jdID)
  formData.append('cv_ids', cv_ids)
  formData.append('message', message)
  formData.append('status_id', statusID)
  formData.append('template_id', templateValue)

  return instance.post('/send/whatsapp/', formData)
}

export function putSendMessage(
  chatID,
  chatDetailsID,
  message,
  subject,
  channel,
  cid,
  jdID,
  phone_number,
) {
  const formData = new FormData()
  formData.append('chatID', chatID)
  formData.append('chatDetailsID', chatDetailsID)
  formData.append('subject', subject)
  formData.append('channel', channel)
  formData.append('message', message)
  formData.append('cid', cid)
  formData.append('jdID', jdID)
  formData.append('phone_number', phone_number)

  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  }

  return instance.put('chat/chat/', formData, config)
}

export function postAIHelperFile(jdID, fileDetails, title) {
  const formData = new FormData()
  formData.append('jdID', jdID)
  formData.append('file', fileDetails)
  formData.append('title', title)

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }
  return instance.post('chat/generate/summary/', formData, config)
}

export function getAIHelperFileDetails(token, offset, limit) {
  console.log('postAIHelperFile', token, offset, limit)
  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
    params: {
      offset: offset,
      limit: limit,
    },
  }
  return instance.get('chat/generate/summary/', config)
}

export function aiChatPendingStatus(
  jdID,
  is_pending,
  offset,
  limit,
  channel = 'WHATSAPP',
  token,
) {
  console.log(
    'postAIHelperFile',
    jdID,
    is_pending,
    offset,
    limit,
    (channel = 'WHATSAPP'),
    token,
  )
  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
    params: {
      jdID,
      offset: offset,
      limit,
      channel,
      is_pending,
    },
  }
  return instance.get('chat/ai/chats/', config)
}

export function whatsAppBulkChatIds(chat_ids) {
  console.log('getAIGeneratedFile', chat_ids)
  const formData = new FormData()
  formData.append('chat_ids', chat_ids)

  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  }

  return instance.post('chat/whatapp/bulk/', formData, config)
}

export function searchAIGeneratedFile(query, offset, limit) {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
    params: {
      query,
      offset: offset,
      limit,
    },
  }

  return instance.get('chat/generate/summary/search/', config)
}

export function manualOtherContactsSearch(query, jdID, offset, limit) {
  console.log('manualOtherContactsSearch', query, jdID, offset, limit)
  const config = {
    params: {
      query,
      jdID,
      offset: offset,
      limit,
    },
  }

  return instance.get('chat/search/contacts/', config)
}

export function fetchSummaryData(cv, jd) {
  console.log('manualOtherContactsSearch', cv, jd)
  const config = {
    params: {
      cv,
      jd,
    },
  }

  return instance.get('/cv/summary/generate/', config)
}

export function createSummary(cv, jd) {
  console.log('manualOtherContactsSearch', cv, jd)

  const formData = new FormData()
  formData.append('cv', cv)
  formData.append('jd', jd)

  return instance.post('/cv/summary/generate/', formData, {
    responseType: 'stream',
  })
}

export function updateSummary(id) {
  console.log('manualOtherContactsSearch', id)
  const formData = new FormData()
  formData.append('id', id)

  return instance.put('/cv/summary/generate/', formData)
}

export function fetchAiAssitantDetails(jd_id, offset, limit) {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
    params: {
      jd_id,
      offset,
      limit,
    },
  }

  return axios.get(
    ' https://testserver.10xscale.in/ai/v1/jd/not-contacted/',
    config,
  )
}
