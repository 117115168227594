import * as api from '@src/api/CvApi'
import { toast } from 'react-toastify'
import {
  API_KEY,
  CLIENT_ID,
  DEFAULT_ERROR_MESSAGE,
  DISCOVERY_DOCS,
  SCOPES,
} from '@src/utility/utils/constants'

function getRandomID(string_length = 9) {
  let chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz'
  let randomstring = ''
  for (let i = 0; i < string_length; i++) {
    let rnum = Math.floor(Math.random() * chars.length)
    randomstring += chars.substring(rnum, rnum + 1)
  }
  console.log('Calendar: ', randomstring)
  return randomstring
}

export async function addEventToCalender(params) {
  let gapi = window.gapi

  try {
    await gapi.load('client:auth2', async () => {
      await gapi.client.init({
        apiKey: API_KEY,
        clientId: CLIENT_ID,
        discoveryDocs: DISCOVERY_DOCS,
        scope: SCOPES,
      })

      let isAuth = gapi.auth2.getAuthInstance().isSignedIn.get()
      if (!isAuth) {
        toast.error('Your account is not connected, please signing')
        await gapi.auth2.getAuthInstance().signIn()
      }
      let checkagain = gapi.auth2.getAuthInstance().isSignedIn.get()
      if (!checkagain) {
        toast.error('Sorry account is not connected, Please try again')
        return {
          status: false,
          message: 'User account no connected',
        }
      }

      let startDate = new Date(params.startDate)
      let endDate = new Date(params.startDate)
      endDate.setMinutes(endDate.getMinutes() + params.duration)

      //clear ateendess
      let people = params.attendees.filter(
        (e) => e.displayName !== '' && e.email !== '',
      )

      let event = {
        summary: params.title,
        description: params.description,
        start: {
          dateTime: startDate.toISOString(),
        },
        end: {
          dateTime: endDate.toISOString(),
        },
        attendees: people,
        conferenceData: {
          createRequest: {
            conferenceSolutionKey: {
              type: 'hangoutsMeet',
            },
            requestId: getRandomID(),
          },
        },
        reminders: {
          useDefault: true,
        },
      }

      let request = gapi.client.calendar?.events?.insert({
        calendarId: 'primary',
        resource: event,
        sendNotifications: true,
        conferenceDataVersion: 1,
      })

      console.log('Request', request)

      await request.execute((event) => {
        console.log('Calendar meet: ', event)
        if (event.htmlLink !== '') {
          toast.success('Meet scheduled successfully.')
          return {
            status: true,
            message: 'User account no connected',
          }
        } else {
          toast.error('Meet scheduled failed. Please try again')
          return {
            status: false,
            message: 'User account no connected',
          }
        }
      })
    })
  } catch (e) {
    console.log('Error', e)
    return {
      status: false,
      message: 'Something went wrong',
    }
  }
}

export async function sendSMS(jdID, cv_ids, jdRole, statusID) {
  try {
    let result = await api.sendSMS(jdID, cv_ids, jdRole, statusID)
    if (result.data.Response === 'Ok') {
      return { status: 1, message: 'SMS Send successfully', response: 'Ok' }
    } else {
      return { status: 0, message: DEFAULT_ERROR_MESSAGE }
    }
  } catch (e) {
    console.log('Error', e)
    return { status: 0, message: DEFAULT_ERROR_MESSAGE }
  }
}

export async function sendEmail(jdID, cvID, message, subject) {
  try {
    let result = await api.sendEmail(jdID, cvID, message, subject)
    if (result.data.Response === 'Ok') {
      return { status: 1, message: 'Email Send successfully' }
    } else {
      return { status: 0, message: DEFAULT_ERROR_MESSAGE }
    }
  } catch (e) {
    console.log('Error', e)
    return { status: 0, message: DEFAULT_ERROR_MESSAGE }
  }
}
