import { createSlice } from '@reduxjs/toolkit'

export const processingNotification = createSlice({
  name: 'pNotifications',
  initialState: {
    notifications: {},
  },
  reducers: {
    saveNotification: (state, action) => {
      // let temp = [...state.notifications]
      let bulk_id = action.payload.bulk_id
      state.notifications[bulk_id] = action.payload
    },
    clearAllNotification: (state, action) => {
      state.notifications = {}
    },
    clearNotification: (state, action) => {},
    clearNotifications: (state, action) => {},
  },
})

export const {
  saveNotification,
  clearAllNotification,
  clearNotification,
  clearNotifications,
} = processingNotification.actions

export default processingNotification.reducer
