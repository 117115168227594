import React, { Fragment, useEffect, useState } from 'react'
import { UploadCloud } from 'react-feather'
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Button,
  Card,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap'
import { convert } from 'html-to-text'
import Grid from '@mui/material/Grid'
import * as cvApi from '@src/api/CvApi'
import Select from 'react-select'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import { DropzoneArea } from 'material-ui-dropzone'
import { toast } from 'react-toastify'
import { makeStyles } from '@material-ui/core/styles'
import CloudSyncIcon from '@mui/icons-material/CloudSync'
const useStyles = makeStyles((theme) => ({
  myDropZone: {
    position: 'relative',
    width: '100%',
    height: '50%',
    minHeight: '100px',
    //backgroundColor: '#F0F0F0',
    border: 'dashed',
    borderColor: '#C8C8C8',
    cursor: 'pointer',
    boxSizing: 'border-box',
  },
}))
export function SendEmailDialog({
  isOpen,
  handleClose,
  data,
  options,
  emailTemplates,
}) {
  console.log(
    'SendEmailDialog',
    isOpen,
    handleClose,
    data,
    options,
    emailTemplates,
  )

  const dropZoneStyles = {
    'MuiDropzoneArea-root': {
      minHeight: '5px !important',
    },
  }

  const [subject, setSubject] = useState('')
  const [message, setMessage] = useState('')
  const [cc, setcc] = useState('')
  const [Bcc, setBcc] = useState('')
  const [emailTags, setEmailTags] = useState({})
  const [currentJourney, setcurrentJourney] = useState({})
  const [templateContent, setTemplateContent] = useState('')
  const [accToggleOpen, setAccToggleOpen] = useState('1')
  const [currentTemplate, setCurrentTemplate] = useState({
    value: '-1',
    label: 'None',
  })
  const [useAuto, setUseAuto] = useState(true)
  const [attachments, setAttachments] = useState([])

  const htmlOptions = {
    wordwrap: 130,
  }

  const accToggle = (id) => {
    if (accToggleOpen === id) {
      setAccToggleOpen()
    } else {
      setAccToggleOpen(id)
    }
  }

  useEffect(() => {
    if (data.status_id && data.status) {
      setcurrentJourney({
        value: data.status_id,
        label: data.status,
      })
    } else {
      let contacted = options?.candidate_status?.filter(
        (e) => e.value === 'CONTACTED',
      )
      if (contacted != undefined && contacted.length > 0) {
        console.log('contacted', contacted[0])
        setcurrentJourney({
          value: contacted[0].id,
          label: contacted[0].value,
        })
      }
    }
  }, [data])

  const handleChangeJourney = (e) => {
    setcurrentJourney(e)
  }

  const onRawInputChange = (e) => {
    // dispatch(setSubjectTooltip(false));
    // dispatch(setSendTooltip(true));
    let value = e.target.value
    setSubject(value)
  }

  const toggle = () => handleClose(!isOpen)

  const handleEmailSend = (e) => {
    if (!currentTemplate.value) {
      toast.error('Please choose an email template.')
    } else if (subject === '') {
      toast.error('Please provide an email subject')
    } else if (currentTemplate === -1 && message === '') {
      toast.error('Please provide an email message')
    } else {
      // Logic to proceed if all conditions are met
      cvApi
        .sendEmail(
          data.jdID ?? data?.jd_id,
          data.cids ?? data?.cid,
          subject,
          currentTemplate.value,
          JSON.stringify(emailTags),
          currentJourney.value,
          message,
          attachments,
          cc,
          Bcc,
        )
        .then((res) => {
          toast.success('The email has been successfully sent')
          setMessage('')
          setCurrentTemplate('')
          setAttachments([])
          handleClose(!isOpen)
        })
        .catch((e) => {
          toast.error(e.message)
        })
    }
  }

  // Toggle for Modal
  useEffect(() => {
    setSubject('')
    setEmailTags({})
    setCurrentTemplate({
      value: '-1',
      label: 'None',
    })
  }, [isOpen])

  const handleChangeTemplate = (e) => {
    setEmailTags({})
    setCurrentTemplate(e)

    let temp = options?.templates.filter((f) => f.id === e.value)[0]

    if (temp) {
      setTemplateContent(convert(temp.content, htmlOptions))
    } else {
      setTemplateContent('')
    }
  }

  const handleTagsChange = (e) => {
    setEmailTags({
      ...emailTags,
      [e.currentTarget.name]: e.currentTarget.value,
    })
  }

  const handleTempleContent = (e) => {
    setTemplateContent(e.target.value)
    console.log('setTemplateContent: ', e)
  }

  const handleMessage = (e) => {
    setMessage(e.target.value)
    console.log('setTemplateContent: ', e)
  }

  const handleAttachmentChange = (acceptedFiles) => {
    console.log('handleAttachmentChange: ', acceptedFiles)
    setAttachments(acceptedFiles)
  }

  // const handleRemoveAttachment = (index) => {
  //   const updatedAttachments = [...attachments]
  //   updatedAttachments.splice(index, 1)
  //   setAttachments(updatedAttachments)
  // }

  const classes = useStyles()

  return (
    <div>
      <Card>
        <Fragment>
          <Modal
            isOpen={isOpen}
            toggle={toggle}
            className="modal-dialog-centered modal-lg"
          >
            <ModalHeader className="bg-transparent" toggle={toggle}>
              {data?.name?.split(',')?.length > 1 ? (
                <h4 className="address-title text-left mb-1">
                  Send email to {data?.name?.split(',')?.length} Candidates
                </h4>
              ) : (
                <h4 className="address-title text-left mb-1">
                  Send email to{' '}
                  <span className="text-primary"> {data?.name}</span>
                </h4>
              )}
            </ModalHeader>
            <ModalBody className="">
              <div className="row mb-2">
                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                  <label htmlFor="">Candidate Status</label>
                  <Select
                    labelId="demo-select-small"
                    id="demo-simple-select"
                    value={currentJourney}
                    onChange={handleChangeJourney}
                    options={options?.candidate_status?.map((e) => {
                      return { value: e.id, label: e.value }
                    })}
                  />
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                  <label htmlFor="">Select Email Template</label>

                  <Select
                    labelId="demo-select-small"
                    id="demo-simple-select"
                    value={currentTemplate}
                    onChange={handleChangeTemplate}
                    options={[
                      {
                        value: '-1',
                        label: 'None',
                      },
                      ...options?.templates
                        ?.filter(
                          (e) =>
                            e?.template_type?.toLowerCase() ===
                            ('email_or_linkedin' || 'eamil' || 'linkedin'),
                        )
                        .map((e) => ({ value: e?.id, label: e?.name })),
                    ]}
                  />
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 ">
                  <label htmlFor="floatingInput">Email Subject</label>
                  <Input
                    type="text"
                    id={'emailMessage'}
                    value={subject}
                    onChange={onRawInputChange}
                    variant="outlined"
                    label={'Email Content'}
                    name={'subject'}
                    maxLength={998}
                    rows={1}
                  />
                </div>
              </div>

              <div className="row ">
                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                  <label htmlFor="">CC</label>
                  <Input
                    type="text"
                    value={cc}
                    onChange={(e) => setcc(() => e?.target?.value)}
                    variant="outlined"
                    label={'Email Content'}
                    name={'subject'}
                  />
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                  <label htmlFor="">Bcc</label>

                  <Input
                    type="text"
                    value={Bcc}
                    onChange={(e) => setBcc(() => e?.target?.value)}
                    variant="outlined"
                    label={'Email Content'}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                  {currentTemplate.value === '-1' ? (
                    <div>
                      <div className="my-1">
                        <label htmlFor="whatsappChooseStatus">Message</label>
                        <Input
                          type="textarea"
                          value={message}
                          placeholder="message"
                          rows="4"
                          onChange={handleMessage}
                          variant="outlined"
                          label={'Connect Message'}
                          name={'message'}
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="mt-2">
                      {options?.templates
                        ?.filter((f) => {
                          return f.id === currentTemplate.value
                        })
                        ?.map((e) => {
                          return (
                            <div className="row my-1">
                              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                <Input
                                  type="textarea"
                                  id={'emailMessage'}
                                  value={templateContent}
                                  rows="4"
                                  onChange={(e) =>
                                    handleTempleContent(e.target.value)
                                  }
                                  variant="outlined"
                                  label={'Connect Message'}
                                  name={'subject'}
                                  disabled={true}
                                />
                              </div>

                              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                <Grid item>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        defaultChecked={useAuto}
                                        value={useAuto}
                                        onChange={(e) =>
                                          setUseAuto(e.target.checked)
                                        }
                                      />
                                    }
                                    label="Auto generate"
                                  />
                                </Grid>
                                <Grid item>
                                  <Accordion
                                    open={accToggleOpen}
                                    toggle={accToggle}
                                  >
                                    <AccordionItem>
                                      <AccordionHeader targetId="1">
                                        Edit Tags
                                      </AccordionHeader>
                                      <AccordionBody>
                                        {options?.templates
                                          ?.filter(
                                            (f) =>
                                              f.id === currentTemplate.value,
                                          )
                                          ?.map((tp) => {
                                            console.log('Values', tp)
                                            return tp?.values.values?.map(
                                              (e) => {
                                                if (
                                                  e.name
                                                    .toLowerCase()
                                                    .includes('form') ||
                                                  e.name
                                                    .toLowerCase()
                                                    .includes('jd_link')
                                                ) {
                                                  return <div />
                                                }
                                                return (
                                                  <div className="my-1">
                                                    <label htmlFor="floatingInput">
                                                      {e.description}
                                                    </label>
                                                    <Input
                                                      type="text"
                                                      id={
                                                        'emailMessage' + e.name
                                                      }
                                                      value={emailTags[e.name]}
                                                      onChange={
                                                        handleTagsChange
                                                      }
                                                      variant="outlined"
                                                      label={e.description}
                                                      name={e.name}
                                                      disabled={useAuto}
                                                      size="small"
                                                    />
                                                  </div>
                                                )
                                              },
                                            )
                                          })}
                                        {options?.templates
                                          ?.filter(
                                            (f) =>
                                              f.id === currentTemplate.value,
                                          )
                                          ?.map((tp) => {
                                            console.log('Values', tp)
                                            return tp?.values.values?.map(
                                              (e) => {
                                                if (
                                                  e.name
                                                    .toLowerCase()
                                                    .includes('form')
                                                ) {
                                                  return (
                                                    <div>
                                                      **
                                                      {e.name +
                                                        ' : ' +
                                                        'will be auto generated from backend'}
                                                    </div>
                                                  )
                                                } else if (
                                                  e.name
                                                    .toLowerCase()
                                                    .includes('jd_link')
                                                ) {
                                                  return (
                                                    <div color="secondary">
                                                      **
                                                      {e.name +
                                                        ' : ' +
                                                        'will be auto generated from backend'}
                                                    </div>
                                                  )
                                                }
                                                return <div></div>
                                              },
                                            )
                                          })}
                                      </AccordionBody>
                                    </AccordionItem>
                                  </Accordion>
                                </Grid>
                              </div>
                            </div>
                          )
                        })}
                    </div>
                  )}
                </div>
              </div>
              <div className="row align-items-center">
                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                  <div>
                    <DropzoneArea
                      showFileNames={true}
                      classes={{ root: classes.myDropZone }}
                      acceptedFiles={[
                        'application/pdf',
                        'application/msword',
                        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                      ]}
                      filesLimit={5}
                      dropzoneText={
                        <div>
                          {' '}
                          <p className="mb-0 font-medium-2">
                            Drag and drop files here or click to select CV
                          </p>
                          <p className=" font-small-4 text-center">
                            Only accept PDF, Docs, and Doc files
                          </p>
                        </div>
                      }
                      Icon={UploadCloud}
                      showPreviews={true}
                      showPreviewsInDropzone={false}
                      useChipsForPreview
                      previewGridProps={{
                        container: { spacing: 1, direction: 'row' },
                      }}
                      showAlerts={false}
                      onChange={handleAttachmentChange}
                    />
                  </div>
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                type="reset"
                className="me-1"
                color="secondary"
                outline
                onClick={handleClose}
              >
                Discard
              </Button>
              <Button className=" " color="primary" onClick={handleEmailSend}>
                Send
              </Button>
            </ModalFooter>
          </Modal>
        </Fragment>
      </Card>
    </div>
  )
}
